import React, { useEffect, useState, useCallback } from 'react';
import { Button, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
    COMPONENTFROM_OPENIPOS_UNKNOWN_STATUS, DATE_PICKER_YEAR_MONTH_DATE, KEY_INSTANTACK, KEY_INVOICE, KEY_ITEMAMEND, KEY_ITEMCASELOT, KEY_ITEMEAN, KEY_XMLFAI,
    STATUS_CASELOT_AMENDMENT, STATUS_EAN_LOOPUP_FAILED, STATUS_INSTANT_ACK_FAILED, STATUS_INVOICE_PENDING, STATUS_PO_AMENDMENT, STATUS_XML_VALIDATION_FAIL
} from '../../../constants';
import { ORDERS_ACTIVITY, ORDERS_PODATA } from '../../../routingUrl';
import CustomPopover from '../../popOver';
import { getOnlyID, getStatus } from '../../../functions';
import DefaultSorting from '../../../images/defaultSort.svg';
import AsendingSorting from '../../../images/asendingSort.svg';
import DecendingSorting from '../../../images/decendingSort.svg';
import ReactExport from "react-data-export";
import { API } from 'aws-amplify';
import { queryInvoicePendingItems } from '../../../../graphql/queries';
const POID = 'poID';
const CREATEDDATE = 'createdDate';
const ASCENDING = 'ascending';
const DECENDING = 'decending';
const OrdersTable = ({ siteCode,data, startDateTime, endDateTime, openPOStatusSetKeys, activeSortOrder, activeElement, updateKeyValue, openPOArray, from, to, openPODataSet }) => {
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const { amdStatussets = [], invoiceStatussets = [], eanStatussets = [], xmlStatussets = [], caselotStatussets = [], instantACKStatussets = [] } = openPOStatusSetKeys;
    
    const[res, setres] = useState([]);
    const [flag, setFlag] = useState(false);
    const [preData, setPreData] = useState([]);   
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;    
    useEffect(() => {        
        if (data) {
            setTableRowData(data); 
        };        
    }, [data, res, flag,siteCode])
    const checkFunc = async(e,arr) => {
        let response = [];
        //condition to fetch all data of all checked input and enabling download button 
        if(e.currentTarget.value === "checkbox_Parent" && e.currentTarget.checked === true){
            data.forEach( async (element) => {              
                if(element.status && element.status==="INVOICE_PENDING"){
                    let apiDatas = await API.graphql({ query: queryInvoicePendingItems, variables: { PK: element.item.PK+'#'+element.item.SK, SK: "Item#", first: 100, after: null } });
                    response = [...response, apiDatas.data.queryInvoicePendingItems.items];                    
                    setres(response);
                }
            });
            setFlag(true);            
            var checkboxes = document.getElementsByName('INVOICE_PENDING');
            for (var i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i] != e)
                    checkboxes[i].checked = e.currentTarget.checked;
            }
            
        }
        //condition to remove all data of all unchecked input and disabling download button 
        else if (e.currentTarget.value === "checkbox_Parent" && e.currentTarget.checked === false){
            var checkboxes = document.getElementsByName('INVOICE_PENDING');            

            for (var i = 0; i < checkboxes.length; i++) {                
                if (checkboxes[i] != e)
                    checkboxes[i].checked = e.currentTarget.checked;
            }
            setFlag(false);
            setres([]);
        }
        //condition to fetch data of individual checked input and enabling download button 
        else if (e.currentTarget.value === "checkbox" && e.currentTarget.checked === true) {            
            setFlag(true);
            const apiData = await API.graphql({ query: queryInvoicePendingItems, variables: { PK: arr.PK+'#'+arr.SK, SK: "Item#", first: 100, after: null } });            
            setres([...res, apiData.data.queryInvoicePendingItems.items]);
        }
        else if(e.currentTarget.value === "checkbox" && e.currentTarget.checked === false){
            //removing the element
            res.forEach((item, index) => {
                if(item[0].PK === arr.PK+"#"+arr.SK){
                    res.splice(index, 1);
                }
            })
            let newResponse = [];
            //creating a new array after removing the element
            res.map((element, index)=>{
                newResponse = [...newResponse, element]
            })
            setres(newResponse);

            //condition to check if all the checkbox are unchecked than disable the download button
            var checkboxes = document.querySelectorAll('input[name="INVOICE_PENDING"]:checked');
            if (checkboxes.length === 0){
                setFlag(false);
            }
            else{
                setFlag(true);
            }                                
        }        
        
    }
    const setTableRowData = (data) => {        

        // To empty the res array on page change and also to uncheck the checkbox on page change
        setPreData(data.map((element) => element.item.GSI2))        
        if(preData[0] !== data.map((element) => element.item.GSI2)[0]){
            setres([]);            
        }
        
        // Mapping Data to the table
        let dataMapper = [];
        if (data && data.length) {
            data.map((itemData, i) => {
                const { item, status } = itemData;
                var obj = {};               
                if (status != null) {
                    obj = {
                        checkBox: <>                        
                            <input type="checkbox" id='checkbox' hidden={status!=='INVOICE_PENDING' ? true : false} value="checkbox" name={status==="INVOICE_PENDING" ? "INVOICE_PENDING" : ""} onClick={(e) => {checkFunc(e, item)} }/>                        
                        </>,
                        key: i,
                        siteCode:item.SiteCode,
                        createdDate: <CustomPopover onNav={() => navToOrdersActivity(getStatus(status), item)} data={<span className="cursorpointer">
                            {` ${moment(item.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}`}</span>} content={popOverContent(item, status)} />,
                        poID: <CustomPopover onNav={() => navToOrdersActivity(getStatus(status), item)} data={<span className="cursorpointer">
                            {getOnlyID(item.SK)}</span>} content={popOverContent(item, status)} />,
                        status: <CustomPopover onNav={() => navToOrdersActivity(getStatus(status), item)} data={<span className="cursorpointer">
                            {getStatus(status)}</span>} content={popOverContent(item, status)} />
                    }    
                } else {
                    obj = {
                        key: i,
                        siteCode: item.SiteCode,
                        createdDate: <CustomPopover onNav={() => navToPOData(item)} data={<span className="cursorpointer">
                            {` ${moment(item.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}`}</span>} />,
                        poID: <CustomPopover onNav={() => navToPOData(item)} data={<span className="cursorpointer">
                            {getOnlyID(item.SK)}</span>} />,
                        status: <CustomPopover onNav={() => navToPOData(item)} data={<span className="cursorpointer">
                            -</span>} />
                    }
                }
                dataMapper.push(obj);
            })
        }
        setTableData(dataMapper)
    }

    // For navigating to inner pages based on status
    const navToPOData = (item) => {
        history.push({ pathname: ORDERS_PODATA, state: { PK: item.PK, SK: item.SK, currentTab: '2', wholeData: item, componentFrom: COMPONENTFROM_OPENIPOS_UNKNOWN_STATUS } })
    }

    // For navigating to inner pages based on status
    const navToOrdersActivity = (status, item) => {
        var itemArray = item.StatusSet;
        if (status === STATUS_PO_AMENDMENT) {
            if (amdStatussets.length && itemArray.length) {
                var filteredArray = amdStatussets.filter(value => itemArray.includes(value));
                history.push({ pathname: ORDERS_ACTIVITY, state: { sk: KEY_ITEMAMEND, pk: item.PK + "#" + item.SK, itemID: filteredArray, data: item, currentTab: "2", startDateTime, endDateTime, openPODataSet: openPODataSet } })
                // let tempstate = { sk: KEY_ITEMAMEND, pk: item.PK + "#" + item.SK, itemID: filteredArray, data: item, currentTab: "2", startDateTime, endDateTime }
                // goToActivity(tempstate);
            }
        } else if (status === STATUS_INVOICE_PENDING) {
            history.push({ pathname: ORDERS_ACTIVITY, state: { sk: KEY_INVOICE, pk: item.PK + "#" + item.SK, data: item, currentTab: "2", startDateTime, endDateTime, openPODataSet: openPODataSet } })
            // let tempstate = { sk: KEY_INVOICE, pk: item.PK + "#" + item.SK, data: item, currentTab: "2", startDateTime, endDateTime }
            // goToActivity(tempstate);

        } else if (status === STATUS_EAN_LOOPUP_FAILED) {
            if (eanStatussets.length && itemArray.length) {
                var filteredArray = eanStatussets.filter(value => itemArray.includes(value));
                history.push({ pathname: ORDERS_ACTIVITY, state: { sk: KEY_ITEMEAN, pk: item.PK + "#" + item.SK, itemID: filteredArray, data: item, currentTab: "2", startDateTime, endDateTime, openPODataSet: openPODataSet } })
                // let tempstate = { sk: KEY_ITEMEAN, pk: item.PK + "#" + item.SK, itemID: filteredArray, data: item, currentTab: "2", startDateTime, endDateTime }
                // goToActivity(tempstate);
            }
        } else if (status === STATUS_CASELOT_AMENDMENT) {
            if (caselotStatussets.length && itemArray.length) {
                var filteredArray = caselotStatussets.filter(value => itemArray.includes(value));
                history.push({
                    pathname: ORDERS_ACTIVITY, state: {
                        sk: KEY_ITEMCASELOT, pk: item.PK + "#" + item.SK, itemID: filteredArray, data: item, currentTab: "2", startDateTime, endDateTime, openPODataSet: openPODataSet
                    }
                })
                // let tempstate = {
                //     sk: KEY_ITEMCASELOT, pk: item.PK + "#" + item.SK, itemID: filteredArray, data: item, currentTab: "2", startDateTime, endDateTime
                // }
                // goToActivity(tempstate);
            }
        } else {
            history.push({
                pathname: ORDERS_PODATA, state: {
                    PK: item.PK, SK: item.SK, currentTab: '2', wholeData: item, componentFrom: COMPONENTFROM_OPENIPOS_UNKNOWN_STATUS, openPODataSet: openPODataSet
                }
            })
            // let tempstate = {
            //     PK: item.PK, SK: item.SK, currentTab: '2', wholeData: item, componentFrom: COMPONENTFROM_OPENIPOS_UNKNOWN_STATUS
            // }
            // goToActivity(tempstate);
        }
    }

    // get Line Items length 
    const getItemsCount = (item, originalStatus) => {
        var Newarray = [];
        item.StatusSet.map((status, i) => {
            if (originalStatus === "Amendment") { if (status.includes(KEY_ITEMAMEND)) Newarray.push(status); }
            else if (originalStatus === "EANFailed") { if (status.includes(KEY_ITEMEAN)) Newarray.push(status); }
            else if (originalStatus === "XMLFailed") { if (status.includes(KEY_XMLFAI)) Newarray.push(status); }
            else if (originalStatus === "Caselot") { if (status.includes(KEY_ITEMCASELOT)) Newarray.push(status); }
            else if (originalStatus === "Instant") { if (status.includes(KEY_INSTANTACK)) Newarray.push(status); }
        })
        return Newarray.length;
    }

    const popOverContent = (item, status) => {
        return (<div className="popupContent">
            <h2 className="clrRed capitalize">{getStatus(status) === STATUS_INVOICE_PENDING ? "Line Items pending For Invoices" : getStatus(status)}</h2>
            <p>{getStatus(status) === "Amendment" ? getItemsCount(item, "Amendment") + " Item(s) Amendment"
                : getStatus(status) === STATUS_EAN_LOOPUP_FAILED ? getItemsCount(item, "EANFailed") + " Item(s) Failed"
                    : getStatus(status) === STATUS_XML_VALIDATION_FAIL ? getItemsCount(item, "XMLFailed") + " Item(s) Failed"
                        : getStatus(status) === STATUS_CASELOT_AMENDMENT ? getItemsCount(item, "Caselot") + " Item(s) Failed"
                            : getStatus(status) === STATUS_INSTANT_ACK_FAILED ? getItemsCount(item, "Instant") + " Item(s) Failed" : ""}</p>
        </div>)
    }

    const handleSorting = (name, info, openPOArray, from, to) => { updateKeyValue(name, info, openPOArray, from, to); }

    const getSorterIcon = useCallback((info, openPOArray, from, to) => {
        return activeSortOrder == ASCENDING && info == activeElement ? <span onClick={() => handleSorting(info, DECENDING, openPOArray, from, to)}><img src={AsendingSorting} className="cursorpointer" /></span> :
            activeSortOrder == DECENDING && info == activeElement ? <span onClick={() => handleSorting(info, ASCENDING, openPOArray, from, to)}><img src={DecendingSorting} className="cursorpointer" /></span> :
                <span onClick={() => handleSorting(info, ASCENDING, openPOArray, from, to)} > <img src={DefaultSorting} className="cursorpointer" /> </span>
    }, [activeSortOrder, activeElement])

    const columns = [{
        title: <input type="checkbox" value="checkbox_Parent" onChange={(e) =>{checkFunc(e, '')}}/>,     
        dataIndex: 'checkBox',
        key: 'checkBox',
        width: '4%'
    }, {
        title: <p className="margin0">PO ID {tableData.length ? getSorterIcon(POID, openPOArray, from, to) : null} </p>,
        dataIndex: 'poID',
        key: 'poID',
    },
         {
        title: <p className="margin0">Site Code{tableData.length ? getSorterIcon(CREATEDDATE, openPOArray, from, to) : null} </p>,
        dataIndex: 'siteCode',
        key: 'siteCode',
    }, {
        title: <p className="margin0">Created Date {tableData.length ? getSorterIcon(CREATEDDATE, openPOArray, from, to) : null} </p>,
        dataIndex: 'createdDate',
        key: 'createdDate',
        width: "40%",
    },{
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    }, {
        title: <>  
                <ExcelFile filename={`Multiple_PO_Details_${new Date().getFullYear() + '_' + (new Date().getMonth() + 1) + '_' + new Date().getDate()}`} 
                element={<Button type='button' disabled={flag===true?false:true}>Download</Button>}>
                    {res?.map((res, index) => 
                    <ExcelSheet key={index} data={res} name={`PO_Sheet_${res[0].PK.slice(14)}`}>                  
                        <ExcelColumn label="EAN" value={(col) => col.EAN}/>
                        <ExcelColumn label="Line Item ID" value={(col) => col.GSI2}/>
                        <ExcelColumn label="Customer Product ID" value={(col) => col.CustomerProductID}/>
                        <ExcelColumn label="Article Desc" value={(col) => col.SystemSKUDescription}/>
                        <ExcelColumn label="MRP" value={(col) => col.MRP}/>
                        <ExcelColumn label="Material" value={(col) => col.SystemSKUCode}/>
                    </ExcelSheet>
                    )}
                </ExcelFile>
                </>,
        dataIndex: 'download',
        key: 'download',
        width: '5%'
    }
];        

    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default OrdersTable;