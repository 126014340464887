import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import { useHistory } from 'react-router-dom';
import Dashboard from '../../../dashboard';
import CustomeButton from '../../../../utils/components/buttons'
import CustomInput from '../../../../utils/components/customeInputFields/input';
import CustomCheckBox from '../../../../utils/components/customeInputFields/CustomChecknox';
import { COMAPNIES_DASHBOARD } from '../../../../utils/routingUrl';
import { API, graphqlOperation } from 'aws-amplify';
import { addCustomer } from '../../../../graphql/mutations';

const CreateCompanies = (props) => {
    const history = useHistory();
    const [state, setState] = useState({
        CompanyName: '',
        IsActive: false,
        SK: '',
        errors: {}
    });
    const { CompanyName, IsActive, SK, errors } = state;

    const handleValueChange = (info, e) => {
        if (e !== "") errors[info] = "";
        setState(state => ({ ...state, [info]: e.target.value }))
    }

    const onCheck = (e) => { setState(state => ({ ...state, IsActive: e.target.checked })) }

    const handleSubmit = async () => {
        let errors = {};
        if (CompanyName === "" || CompanyName === undefined) errors.CompanyName = "Please select a customer name";
        if (SK === "" || SK === undefined) errors.SK = "Please select a customer payer ID";
        setState(state => ({ ...state, errors }));
        if (Object.keys(errors).length === 0) {
            var body = { CompanyName, SK, IsActive, Type: "Company", PK: 'Company' }
            try {
                const newTodo = await API.graphql(graphqlOperation(addCustomer, body));
                message.success('Customer created successfully!');
                history.push(COMAPNIES_DASHBOARD)
            } catch (error) {
                if (error.errors[0].errorType.includes("ConditionalCheckFailedException")) {
                    message.error('Already existed user data');
                } else message.error('Invalid Data');
            }
        }
    }

    const handleCancel = () => { history.push(COMAPNIES_DASHBOARD) }

    return (
        <Dashboard>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <h2 className="heading18Bold margin0">Create Customer</h2>
                    <div className="CreateUserForm">
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel">Customer Name</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={CompanyName || undefined} placeholder='Customer Name' errorText={errors.CompanyName}
                                    onChange={(e) => { handleValueChange("CompanyName", e) }} inputClassname="inputBoxField" />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">Customer Payer ID</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={SK || undefined} placeholder='Customer Payer ID' name="SK" errorText={errors.SK}
                                    onChange={(e) => { handleValueChange("SK", e) }} inputClassname="inputBoxField" />
                            </Col>
                        </Row>

                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '6' }}>
                                <p className="customeSideLabel">Active</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '18' }}>
                                <CustomCheckBox onChange={onCheck} checked={IsActive} className="checkBoxStyles" />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}> </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }} className="displaySpacebetween ">
                                <CustomeButton buttonLabel="Cancel" className="cancelBtn" handleSubmit={handleCancel} />
                                <CustomeButton buttonLabel={"Submit"} className="submitBtn" handleSubmit={handleSubmit} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Dashboard>
    )
}
export default CreateCompanies;