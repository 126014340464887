import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { getItems, getLineItemID } from '../../../functions';
import { KEY_ITEMAMEND, KEY_ITEMCASELOT, KEY_MRP_CHECK2, STATUS_CASELOT_AMENDMENT } from '../../../constants';

const liItemID = {
    title: 'Item ID',
    dataIndex: 'liItemID',
    key: 'liItemID',
};
const ean = {
    title: 'EAN',
    dataIndex: 'ean',
    key: 'ean',
};
const itemName = {
    title: 'Article Desc',
    dataIndex: 'itemName',
    key: 'itemName',
};
const mrp = {
    title: 'MRP',
    dataIndex: 'mrp',
    key: 'mrp',
};
const upMrp = {
    title: 'Updated MRP',
    dataIndex: 'upMrp',
    key: 'upMrp',
}
const material = {
    title: 'Material',
    dataIndex: 'material',
    key: 'material',
}
const caselot = {
    title: 'Caselot',
    dataIndex: 'caselot',
    key: 'caselot',
}
const correctCaselot = {
    title: 'Correct Caselot',
    dataIndex: 'correctCaselot',
    key: 'correctCaselot',
}

const CustomerProductID = {
    title: 'Customer Product ID',
    dataIndex: 'CustomerProductID',
    key: 'CustomerProductID',
}

const OrdersActivityTable = ({ data, type }) => {
    const [state, setState] = useState({
        columns: [], tableData: []
    });
    const { columns, tableData } = state;

    useEffect(() => {
        var array = [];
        if (type === KEY_ITEMCASELOT) array.push(liItemID, caselot, correctCaselot, itemName, mrp, material);
        else if (type === KEY_ITEMAMEND || type === KEY_MRP_CHECK2) array.push(liItemID, ean, CustomerProductID, itemName, mrp, upMrp, material);
        setState(state => ({ ...state, columns: array }));
    }, [])
    useEffect(() => {
        var tableData = [];
        data && data.map((item, i) => {
            var obj = {
                key: i,
                liItemID: type === KEY_MRP_CHECK2 ? getItems(item.SK, 3) : getLineItemID(item.SK),
                ean: item.EAN ? item.EAN : "--",
                itemName: item.SystemSKUDescription ? item.SystemSKUDescription : "--",
                mrp: item.MRP ? item.MRP : "--",
                upMrp: item.UpdatedMRP ? item.UpdatedMRP : "--",
                material: item.SystemSKUCode ? item.SystemSKUCode : "--",
                caselot: item.CaseLot,
                correctCaselot: item.CorrectCaseLot,
                CustomerProductID: item.CustomerProductID ? item.CustomerProductID : '--'
            }
            tableData.push(obj);
        })
        setState(state => ({ ...state, tableData }));
    }, [data])

    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default OrdersActivityTable;