import React from 'react';
import { Select, Form } from 'antd';
import "./styles.scss"

const DropDownSelect = ({ label, placeholder, options, errorText, errorMsg, defaultValue, value, onChange, style, mode, className, selectStyles, disabled }) => {
    const FormItem = Form.Item;
    return (<FormItem label={label} className={className} colon={false}>
        <Select getPopupContainer={triggerNode => triggerNode.parentNode} placeholder={placeholder} mode={mode && mode} defaultValue={defaultValue === "true" ? "User Mgmt" : null}
            style={style} optionFilterProp="children" value={value} onChange={onChange} className={selectStyles} disabled={disabled}>
            {options}
        </Select>
        {errorMsg ? <p className="errorDropdownStyles">{errorText}</p> : null}
    </FormItem>)
}

export default DropDownSelect;