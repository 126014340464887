import React from 'react';
import { Table } from 'antd';

const POByID = ({ data }) => {
    // Mapping Data For Table
    const dataMapper = [];
    if (data && data.length) {
        data.map((item, i) => {
            if(item.Message != 'PRODUCT_DETAILS_NOT_FOUND'){
                if ( item.Message != 'Pack size issue (PO vs SAP)'){
                    if(item.Message != "EDI - Base Price match condition fail"){
                        var obj = {
                            key: i,
                            lineItem: item.GSI2,
                            inStatus: <span className="capitalizeAllCaps"> {item.InvoiceStatus && item.InvoiceStatus.toLowerCase()}</span>,
                            targetQuantity: item.TargetQty,
                            saleUnit: item.SalesUnit,
                            mrp: item.MRP,
                            dec: item.SystemSKUDescription ? item.SystemSKUDescription : "--",
                        }
                        dataMapper.push(obj);
                    }
                }
            }
        })
    }

    const columns = [{
        title: 'Item ID',
        dataIndex: 'lineItem',
        key: 'lineItem',
    }, {
        title: 'Sales Unit',
        dataIndex: 'saleUnit',
        key: 'saleUnit',
    }, {
        title: 'MRP',
        dataIndex: 'mrp',
        key: 'mrp',
    }, {
        title: 'Target Quantity',
        dataIndex: 'targetQuantity',
        key: 'targetQuantity',
    }, {
        title: 'Invoice Status',
        dataIndex: 'inStatus',
        key: 'inStatus',
    }, {
        title: 'Article Desc',
        dataIndex: 'dec',
        key: 'dec',
        with: '5%',
    }];

    return (<Table dataSource={dataMapper} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default POByID;