import React from 'react';
import { Table } from 'antd';
import { getInvoiceID } from '../../../../functions';

const InvoiceByID = ({ data }) => {
    // Mapping Data For Table
    const dataMapper = [];
    if (data && data.length) {
        data.map((item, i) => {
            var obj = {
                key: i,
                lineItem: getInvoiceID(item.SK),
                inQuantity: item.InvoiceQuantity,
                mrp: item.MRP,
                invmrp: item.UpdatedMRP || '-',
                articleDec: item.SystemSKUDescription,
            }
            dataMapper.push(obj);
        })
    }

    const columns = [{
        title: 'Item ID',
        dataIndex: 'lineItem',
        key: 'lineItem',
    }, {
        title: 'Invoice Quantity',
        dataIndex: 'inQuantity',
        key: 'inQuantity',
    }, {
        title: 'PO MRP',
        dataIndex: 'mrp',
        key: 'mrp',
    }, {
        title: 'Invoice MRP',
        dataIndex: 'invmrp',
        key: 'mrp',
    },{
        title: 'Article Desc',
        dataIndex: 'articleDec',
        key: 'articleDec',
    }];

    return (<Table dataSource={dataMapper} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default InvoiceByID;