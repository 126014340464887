import React, { useMemo } from 'react'
import { Col } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './index.scss';
import CustomButton from '../buttons';
import DropDownSelect from '../customeInputFields/dropDownSelect';
import { getShowOptionsList } from '../../functions';

const CustomPaginationWithPages = ({ showItems, noOfRecordsPerPage, noOfRecordsPerPageChanged, onPre, onNext, from, to, previousBtn, nextBtn, dataLength }) => {

  const showItemOptions = useMemo(() => getShowOptionsList(showItems), [showItems])
  const onPreClick = () => { onPre(); }
  const onNextClick = () => { onNext(); }
  const recordsPerPageChanged = (e) => { noOfRecordsPerPageChanged(e); }

  return (<Col span={24} className="paginationMain">
    <div className="paginationButtons displayFlex justifyContentCenter alignItemsCenter">
      <p className="margin0">Show</p>
      <DropDownSelect placeholder='0' value={noOfRecordsPerPage || undefined} onChange={recordsPerPageChanged}
        selectStyles="selectStyles paddingLeft10" options={showItemOptions} errorMsg={false} disabled={showItems.length == 0} />
      <p className="margin0 paddingLeft10 paddingRight10"> Items</p>
      <CustomButton handleSubmit={onPreClick} className="PrevNext" buttonLabel={<LeftOutlined />} disabled={previousBtn} />
      <div className="pageNumbers">
        <p className="margin0 displayFlex justifyContentCenter alignItemsCenter"><span>{from}</span> - <span>{nextBtn ? dataLength : to}</span></p>
      </div>
      <CustomButton handleSubmit={onNextClick} className="PrevNext" buttonLabel={<RightOutlined />} disabled={nextBtn} />
    </div>
  </Col>)
}
export default CustomPaginationWithPages;


