import React, { useState } from 'react';
import { Row, Col, message, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import Dashboard from '../../../dashboard';
import CustomeButton from '../../../../utils/components/buttons'
import DropDownSelect from '../../../../utils/components/customeInputFields/dropDownSelect';
import CustomInput from '../../../../utils/components/customeInputFields/input';
// import './index.scss';
import { addUser } from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { CUSTOMER_DATA_DASHBOARD, USERS_DASHBOARD, USERS_EDIT } from '../../../../utils/routingUrl';
const Option = Select.Option;

const CreateCustomerData = (props) => {
    const history = useHistory();
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));

    const [state, setState] = useState({
        customerDepoCode: '',
        customerCode: '',
        errors: {},
    });
    const { customerDepoCode, customerCode, errors } = state;

    const handleValueChange = (info, e) => {
        if (e !== "") errors[info] = "";
        if (info === 'customerCode') setState(state => ({ ...state, [info]: e }))
        else setState(state => ({ ...state, [info]: e.target.value }))
    }

    const handleSubmit = async () => {
        let errors = {};
        if (customerDepoCode === "" || customerDepoCode === undefined) errors.customerDepoCode = "Please enter a customer depo code";
        if (customerCode === "" || customerCode === undefined) errors.customerCode = "Please select a customer code";

        setState(state => ({ ...state, errors }));
        if (Object.keys(errors).length === 0) {
            var body = { customerDepoCode, customerCode }
            try {
                const newTodo = await API.graphql(graphqlOperation(addUser, body));
                message.success('User created successfully!');
                history.push(USERS_DASHBOARD);
            } catch (error) {
                if (error.errors[0].errorType.includes("ConditionalCheckFailedException")) {
                    message.error('Already existed user data');
                } else message.error('Invalid Data');
            }
        }
    }

    const handleCancel = () => { history.push(CUSTOMER_DATA_DASHBOARD) }

    return (
        <Dashboard>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <h2 className="heading18Bold margin0">Add New Customer Data</h2>
                    <div className="CreateUserForm">
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel">Customer Depo Code</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={customerDepoCode || undefined} placeholder='Customer Depo Code'
                                    onChange={(e) => { handleValueChange("customerDepoCode", e) }} inputClassname="inputBoxField" errorText={errors.customerDepoCode} />
                            </Col>
                        </Row>

                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">Customer Code</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <DropDownSelect placeholder='Customer Code' className="beforeStar" value={customerCode || undefined} onChange={(e) => { handleValueChange("customerCode", e) }}
                                    selectStyles="selectStyles" options={[<Option value={'Yes'}> Yes </Option>, <Option value={'No'}> No </Option>]} errorText={errors.customerCode} />
                            </Col>
                        </Row>

                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}> </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }} className="displaySpacebetween ">
                                <CustomeButton buttonLabel="Cancel" className="cancelBtn" handleSubmit={handleCancel} />
                                <CustomeButton buttonLabel={window.location.pathname === USERS_EDIT ? 'Update' : "Submit"} className="submitBtn" handleSubmit={handleSubmit} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Dashboard>
    )
}
export default CreateCustomerData;