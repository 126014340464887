import React from 'react';
import { Table } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import EditIcon from '../../../../images/editRecord.svg';
import DeleteIcon from '../../../../images/trashIcon.svg';
import { BB_USERS_DASHBOARD, BB_USERS_EDIT, USERS_EDIT } from '../../../../routingUrl';
import { NOTIFICATION_KEY, TRUE_CONDITION } from '../../../../constants';
import { getItems } from '../../../../functions';
import {
    ADMIN, CUSTOMER_ADMIN, CUST_ADMIN_KEY, ERROR_MAIL_RECIPIENTS, ERROR_MAIL_RECIPIENTS_KEY, SALES_PERSONS,
    SALES_PERSON_KEY, SO_MAIL_RECIPIENTS, SO_MAIL_RECIPIENTS_KEY, SUB_ADMIN_KEY, SUPER_ADMIN_KEY
  } from '../../../../constants/index.js';

const CustomerAdminTable = ({ data, deleteUserAccount ,activeTab}) => {
    const history = useHistory();
    const toEditUser = (item) => { history.push({ pathname: window.location.pathname === BB_USERS_DASHBOARD ? BB_USERS_EDIT : USERS_EDIT, state: { data: item } }) }

    // Mapping Data For Table 
    var dataMapper = [];
    if (data && data.length) { 
        data.map((item, i) => {
            let obj=null;
            //let i=0
            //if so-mail recepient
           
            if(activeTab== SO_MAIL_RECIPIENTS_KEY && item.Type =='CFAEmails'){
             obj = {
                    key: i,
                    name: item.GSI1PK,
            //  customerPairID: item.Type == NOTIFICATION_KEY ? getItems(item.SK, 0) : item.SK,
                 customerPairID: item.CustomerCode,
                  PlantCode: item.PlantCode,
                    EmailID1 : item.EmailID1,
                    EmailID2 : item.EmailID2 ,
                    VendorCode : item.VendorCode,
                    StoreID: item.StoreID,
                    status: item.IsActive === TRUE_CONDITION || item.IsActive ? 'Active' : 'Not Active',
                   //  edit: <img src={EditIcon} alt="edit icon" onClick={() => toEditUser(item)} className="editIcon" />,
                   // delete: <img src={DeleteIcon} alt="delete icon" onClick={() => deleteUserAccount(item)} className="cursorpointer" />,
                }
           
            }
            //if sales persons || error recepient
            else{
             obj = {
                key: i,
                customerName: item.CompanyName,
                name: item.GSI1PK,
                customerPairID: item.Type == NOTIFICATION_KEY ? getItems(item.SK, 0) : item.SK,
                emailID: item.PK,
                status: item.IsActive === TRUE_CONDITION || item.IsActive ? 'Active' : 'Not Active',
                edit: <img src={EditIcon} alt="edit icon" onClick={() => toEditUser(item)} className="editIcon" />,
                delete: <img src={DeleteIcon} alt="delete icon" onClick={() => deleteUserAccount(item)} className="cursorpointer" />,
            }}
            dataMapper.push(obj);
        })
    }

    
    const NUMBER_ONE = 1;
    const NUMBER_TEN = 10;

    const inititalPaginationState = {
        noOfRecordsPerPage: NUMBER_TEN,
        nextBtn: true,
        previousBtn: true,
        fromCount: NUMBER_ONE,
        toCount: NUMBER_TEN,
        showItems: []
      }

    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const { GSI1PK } = userDetails;
    
    if(userDetails){
        var salesData = { key: SALES_PERSON_KEY, name: SALES_PERSONS, data: [], pagination: inititalPaginationState, tableData: [] },
        subAdminData = { key: SUB_ADMIN_KEY, name: ADMIN, data: [], pagination: inititalPaginationState, tableData: [] },
        custAdminData = { key: CUST_ADMIN_KEY, name: CUSTOMER_ADMIN, data: [], pagination: inititalPaginationState, tableData: [] },
        SOMailRecipientsData = { key: SO_MAIL_RECIPIENTS_KEY, name: SO_MAIL_RECIPIENTS, data: [], pagination: inititalPaginationState, tableData: [] },
        errorMailRecipientsData = { key: ERROR_MAIL_RECIPIENTS_KEY, name: ERROR_MAIL_RECIPIENTS, data: [], pagination: inititalPaginationState, tableData: [] },
        userData1 = [];
        if (GSI1PK == SUB_ADMIN_KEY) {
             userData1.push(salesData, SOMailRecipientsData, errorMailRecipientsData) }
      else if (GSI1PK == CUST_ADMIN_KEY) {
         userData1.push(subAdminData, salesData, SOMailRecipientsData, errorMailRecipientsData) }
      else if (GSI1PK == SUPER_ADMIN_KEY) {
         userData1.push(custAdminData, subAdminData, salesData) }
    
    }


    const columns = [{
        title: 'E-Mail ID',
        dataIndex: 'emailID',
        key: 'emailID',
        width: '30%'
    }, {
        title: 'Customer Name',
        dataIndex: 'customerName',
        key: 'customerName',
        width: '30%'
    }, {
        title: 'Customer Payer ID',
        dataIndex: 'customerPairID',
        key: 'customerPairID',
        width: '20%'
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '10%'
    }, {
        title: 'Edit',
        dataIndex: 'edit',
        key: 'edit',
        align: 'center',
        width: '10%'
    }, {
        title: 'Delete',
        dataIndex: 'delete',
        key: 'delete',
        align: 'center',
        width: '10%'
    }];
    //Table for SO success mail recipients
    const columnsSOMail = [ {
        title: 'Store Id',
        dataIndex: 'StoreID',
        key: 'StoreID',
        width: '10%'
    }, {
        title: 'Customer Code',
        dataIndex: 'customerPairID',
        key: 'customerPairID',
        width: '10%'
    },
    {
        title: 'Plant Code',
        dataIndex: 'PlantCode',
        key: 'PlantCode',
        width: '10%'
    },
    {
        title: 'Vendor Code',
        dataIndex: 'VendorCode',
        key: 'VendorCode',
        width: '15%'
    },
    {
        title: 'E-Mail ID 1',
        dataIndex: 'EmailID1',
        key: 'EmailID1',
        width: '30%'
    },
    {
        title: 'E-Mail ID 2',
        dataIndex: 'EmailID2',
        key: 'EmailID2',
        width: '30%'
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '30%'
    }
   // ,
    // {
    //     title: 'Edit',
    //     dataIndex: 'edit',
    //     key: 'edit',
    //     align: 'center',
    //     width: '10%'
    // }, 
    // {
    //     title: 'Delete',
    //     dataIndex: 'delete',
    //     key: 'delete',
    //     align: 'center',
    //     width: '10%'
    // }
];

let newColumn;
    if(activeTab== SO_MAIL_RECIPIENTS_KEY)
        {
        newColumn= columnsSOMail
        } 
    else{
        newColumn=columns
        }
    return (<Table dataSource={dataMapper} columns={newColumn} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default CustomerAdminTable;