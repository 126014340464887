import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { getLineItemID } from '../../../functions';
import { NO_KEY, TRUE_CONDITION, YES_KEY } from '../../../constants';
import DefaultSorting from '../../../images/defaultSort.svg';
import AsendingSorting from '../../../images/asendingSort.svg';
import DecendingSorting from '../../../images/decendingSort.svg';

const ASCENDING = 'ascending';
const DECENDING = 'decending';
const S4HANAPARENTCODE = 'S4HANAParentCode';
const ISACTIVE = 'IsActive';
const CUSTOMERPRODUCT = 'CustomerProduct';
const SKUMAPPING = 'SKUMapping';
const GSI2 = 'GSI2';

const ProductTable = ({ data, productData, updateProductData }) => {
    const [tableData, setTableData] = useState([]);
    const [activeSortOrder, setSortOrder] = useState();
    const [activeColumn, setActiveColumn] = useState();
    const [activeElement, setActiveElement] = useState();

    useEffect(() => { if (data) setTableRowData(data); }, [data])

    const setTableRowData = (data) => {
        const tableData = [];
        data && data.map((item, i) => {
            const { SKUMapping, CustomerProduct } = item;
            var obj = {
                key: i,
                GTIN: <span className={SKUMapping.IsActive || SKUMapping.IsActive === TRUE_CONDITION ? "" : 'clrRed'} >{SKUMapping.GSI2}</span>,
                division: getLineItemID(SKUMapping.SK), hanaParentCode: SKUMapping.S4HANAParentCode, hanaCode: SKUMapping.S4HANACode,
                hanaProdDesc: SKUMapping.HANAProductDescription, MRP: SKUMapping.MRP ? SKUMapping.MRP : '-', caseSize: SKUMapping.CaseSize,
                RRL: CustomerProduct.IsActive || CustomerProduct.IsActive === TRUE_CONDITION ? YES_KEY : NO_KEY,
                RRLArticle: CustomerProduct.GSI2, RRLArticleDesc: CustomerProduct.RRLArticleDescription,
            }
            tableData.push(obj);
        })
        setTableData(tableData)
    }

    const handleSorting = (name, info, key) => {
        setSortOrder(info)
        setActiveColumn(key)
        setActiveElement(name)
        if (info === DECENDING) {
            if (name === SKUMAPPING) productData.sort((x, y) => y.SKUMapping[key] - x.SKUMapping[key]);
            else if (name === CUSTOMERPRODUCT) productData.sort((x, y) => y.CustomerProduct[key] - x.CustomerProduct[key]);
            updateProductData(productData)
        } else if (info === ASCENDING) {
            if (name === SKUMAPPING) productData.sort((x, y) => x.SKUMapping[key] - y.SKUMapping[key]);
            else if (name === CUSTOMERPRODUCT) productData.sort((x, y) => x.CustomerProduct[key] - y.CustomerProduct[key]);
            updateProductData(productData)
        }
    }

    const getSorterIcon = useCallback((info, key) => {
        return activeColumn == key && activeSortOrder == ASCENDING && info == activeElement ? <span onClick={() => handleSorting(info, DECENDING, key)}><img src={AsendingSorting} className="cursorpointer" /></span> :
            activeColumn == key && activeSortOrder == DECENDING && info == activeElement ? <span onClick={() => handleSorting(info, ASCENDING, key)}><img src={DecendingSorting} className="cursorpointer" /></span> :
                <span onClick={() => handleSorting(info, ASCENDING, key)} > <img src={DefaultSorting} className="cursorpointer" /> </span>
    }, [activeColumn, activeSortOrder, activeElement])

    const columns = [{
        title: 'Division',
        dataIndex: 'division',
        width: '5%'
    }, {
        title: <p className="margin0">GTIN {getSorterIcon(SKUMAPPING, ISACTIVE)} </p>,
        dataIndex: 'GTIN',
        width: '8%'
    }, {
        title: <p className="margin0">S4 HANA Parent Code {getSorterIcon(SKUMAPPING, S4HANAPARENTCODE)} </p>,
        dataIndex: 'hanaParentCode',
        width: '10%'
    }, {
        title: 'S4 HANA Code',
        dataIndex: 'hanaCode',
        width: '8%'
    }, {
        title: 'Hana Prod Desc',
        dataIndex: 'hanaProdDesc',
        width: '15%'
    }, {
        title: 'MRP',
        dataIndex: 'MRP',
        width: '4%'
    }, {
        title: 'Case Size',
        dataIndex: 'caseSize',
        width: '5%'
    }, {
        title: <p className="margin0">RRL {getSorterIcon(CUSTOMERPRODUCT, ISACTIVE)} </p>,
        dataIndex: 'RRL',
        fixed: 'right',
        width: '6%'
    }, {
        title: <p className="margin0">RRL Article {getSorterIcon(CUSTOMERPRODUCT, GSI2)} </p>,
        dataIndex: 'RRLArticle',
        fixed: 'right',
        width: '8%'
    }, {
        title: 'RRL Article Desc',
        dataIndex: 'RRLArticleDesc',
        fixed: 'right',
        width: '15%'
    }];

    return (<Table scroll={{ x: 1700 }} className="primary-color-table productMappingTable" dataSource={tableData}
        columns={columns} pagination={{ position: ["bottomCenter"] }} pagination={false} />)
}

export default ProductTable;