/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateEDIGatewayTable = /* GraphQL */ `
  mutation UpdateEDIGatewayTable($input: UpdateEDIGatewayTableInput!) {
    updateEDIGatewayTable(input: $input) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2
      Type
      Status
      MRP
    }
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser(
    $PK: String!
    $SK: String!
    $CompanyName: String!
    $GSI2: String!
    $GSI1PK: String!
    $GSI1SK: String!
    $IsActive: Boolean!
    $Type: String!
  ) {
    addUser(
      PK: $PK
      SK: $SK
      CompanyName: $CompanyName
      GSI2: $GSI2
      GSI1PK: $GSI1PK
      GSI1SK: $GSI1SK
      IsActive: $IsActive
      Type: $Type
    ) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2
      CompanyName
      IsActive
      Type
    }
  }
`;
export const addUserSOMail = /* GraphQL */ `
  mutation AddUserSOMail(
    $PK: String!
    $SK: String!
    $CustomerCode: String!
    $EmailID1: String!
    $EmailID2: String!
    $GSI1PK: String!
    $GSI1SK: String!
    $GSI2: String!
    $PlantCode: String!
    $StoreID: String!
    $Type: String!
    $VendorCode: String!
    $IsActive: Boolean!
  ) {
    addUserSOMail(
      PK: $PK
      SK: $SK
      CustomerCode: $CustomerCode
      EmailID1: $EmailID1
      EmailID2: $EmailID2
      GSI1PK: $GSI1PK
      GSI1SK: $GSI1SK
      GSI2: $GSI2
      PlantCode: $PlantCode
      StoreID: $StoreID
      Type: $Type
      VendorCode: $VendorCode
      IsActive: $IsActive
    ) {
      PK
      SK
      CustomerCode
      EmailID1
      EmailID2
      GSI1PK
      GSI1SK
      GSI2
      PlantCode
      StoreID
      Type
      VendorCode
      IsActive
    }
  }
`;
export const addCustomer = /* GraphQL */ `
  mutation AddCustomer(
    $PK: String!
    $SK: String!
    $CompanyName: String!
    $IsActive: Boolean!
    $Type: String!
  ) {
    addCustomer(
      PK: $PK
      SK: $SK
      CompanyName: $CompanyName
      IsActive: $IsActive
      Type: $Type
    ) {
      PK
      SK
      CompanyName
      Type
      IsActive
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $PK: String!
    $SK: String!
    $GSI2: String!
    $GSI1PK: String!
    $GSI1SK: String!
    $IsActive: Boolean!
  ) {
    updateUser(
      PK: $PK
      SK: $SK
      GSI2: $GSI2
      GSI1PK: $GSI1PK
      GSI1SK: $GSI1SK
      IsActive: $IsActive
    ) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2
      CompanyName
      IsActive
      Type
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($PK: String!, $SK: String!) {
    deleteUser(PK: $PK, SK: $SK) {
      PK
      SK
      GSI1PK
      GSI1SK
      GSI2
      CompanyName
      IsActive
      Type
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail(
    $From: String!
    $ToList: [String]!
    $CompanyCode: String
    $UserType: String
    $URL: String
  ) {
    sendEmail(
      From: $From
      ToList: $ToList
      CompanyCode: $CompanyCode
      UserType: $UserType
      URL: $URL
    )
  }
`;
export const updateCompanies = /* GraphQL */ `
  mutation UpdateCompanies(
    $PK: String!
    $Type: String!
    $itemIds: [CompanyStatus]
  ) {
    updateCompanies(PK: $PK, Type: $Type, itemIds: $itemIds) {
      items {
        PK
        SK
        CompanyName
        Type
        IsActive
      }
      nextToken
    }
  }
`;
export const mapProduct = /* GraphQL */ `
  mutation MapProduct(
    $PK: String!
    $SK: String!
    $GSI1PK: String!
    $GSI1SK: String!
    $Description: String
    $IsActive: Boolean!
  ) {
    mapProduct(
      PK: $PK
      SK: $SK
      GSI1PK: $GSI1PK
      GSI1SK: $GSI1SK
      Description: $Description
      IsActive: $IsActive
    ) {
      PK
      SK
      GSI1PK
      GSI1SK
      Description
      IsActive
      Type
    }
  }
`;
export const productMapping = /* GraphQL */ `
  mutation ProductMapping(
    $Type: String!
    $SearchID: String
    $UnMap: UnMap
    $Map: Map
    $Ids: Ids
    $ProductIds: ProductIds
    $DeleteMap: [DeleteMap]
    $MapProduct: MapProduct
  ) {
    productMapping(
      Type: $Type
      SearchID: $SearchID
      UnMap: $UnMap
      Map: $Map
      Ids: $Ids
      ProductIds: $ProductIds
      DeleteMap: $DeleteMap
      MapProduct: $MapProduct
    ) {
      statusCode
      body {
        SKUMapping {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          MRP
          HANAProductDescription
          S4HANAParentCode
          S4HANACode
          MainEAN
          CaseSize
          IsActive
        }
        CustomerProduct {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          IsActive
          ParentSKUDescription
          SystemSKUDescription
        }
        GTINList {
          PK
          SK
          GSI1PK
          GSI1SK
          GSI2
          Type
          MRP
          HANAProductDescription
          S4HANAParentCode
          S4HANACode
          MainEAN
          CaseSize
          IsActive
        }
        SKUParent {
          GSI1PK
          Division
          SystemSKUDescription
          GSI2
          PK
          SK
          Type
          ParentSKUDescription
        }
        CustomerCode
        DCCode
        CustProductID {
          ParentSKUCode
          SystemSKUCode
        }
      }
    }
  }
`;
export const customerMapping = /* GraphQL */ `
  mutation CustomerMapping($Type: String!) {
    customerMapping(Type: $Type)
  }
`;
export const sORetrigger = /* GraphQL */ `
  mutation SORetrigger($PK: String!) {
    SORetrigger(PK: $PK)
  }
`;
export const uploadMasterData = /* GraphQL */ `
  mutation UploadMasterData($customerId: String!, $Data: [MasterData]!) {
    uploadMasterData(customerId: $customerId, Data: $Data) {
      statusCode
      body
    }
  }
`;
export const uploadBigBasketMasterData = /* GraphQL */ `
  mutation UploadBigBasketMasterData(
    $customerId: String!
    $Data: [BBMasterData]!
  ) {
    uploadBigBasketMasterData(customerId: $customerId, Data: $Data) {
      statusCode
      body
    }
  }
`;
export const deliveryStatus = /* GraphQL */ `
  mutation DeliveryStatus($PONUMBER: String!) {
    DeliveryStatus(PONUMBER: $PONUMBER)
  }
`;
export const unblockMaterial = /* GraphQL */ `
  mutation UnblockMaterial($payload: String!) {
    UnblockMaterial(payload: $payload)
  }
`;
export const createEDIBigBasketPOSOStatus = /* GraphQL */ `
  mutation CreateEDIBigBasketPOSOStatus(
    $input: CreateEDIBigBasketPOSOStatusInput!
    $condition: ModelEDIBigBasketPOSOStatusConditionInput
  ) {
    createEDIBigBasketPOSOStatus(input: $input, condition: $condition) {
      id
      PO
      SO
      Status
      createdAt
      updatedAt
    }
  }
`;
export const updateEDIBigBasketPOSOStatus = /* GraphQL */ `
  mutation UpdateEDIBigBasketPOSOStatus(
    $input: UpdateEDIBigBasketPOSOStatusInput!
    $condition: ModelEDIBigBasketPOSOStatusConditionInput
  ) {
    updateEDIBigBasketPOSOStatus(input: $input, condition: $condition) {
      id
      PO
      SO
      Status
      createdAt
      updatedAt
    }
  }
`;
export const deleteEDIBigBasketPOSOStatus = /* GraphQL */ `
  mutation DeleteEDIBigBasketPOSOStatus(
    $input: DeleteEDIBigBasketPOSOStatusInput!
    $condition: ModelEDIBigBasketPOSOStatusConditionInput
  ) {
    deleteEDIBigBasketPOSOStatus(input: $input, condition: $condition) {
      id
      PO
      SO
      Status
      createdAt
      updatedAt
    }
  }
`;
export const createEDIBigBasketPODetails = /* GraphQL */ `
  mutation CreateEDIBigBasketPODetails(
    $input: CreateEDIBigBasketPODetailsInput!
    $condition: ModelEDIBigBasketPODetailsConditionInput
  ) {
    createEDIBigBasketPODetails(input: $input, condition: $condition) {
      id
      BASIC_COST
      CASES
      DATE
      DESCRIPTION
      EAN
      HSN_CODE
      MAT
      MRP
      PO
      QTY
      SHIP_CODE
      SHIP_FROM
      SHIP_TO
      SKU_CODE
      SO
      STATUS
      TOTAL_AMOUNT
      createdAt
      updatedAt
    }
  }
`;
export const updateEDIBigBasketPODetails = /* GraphQL */ `
  mutation UpdateEDIBigBasketPODetails(
    $input: UpdateEDIBigBasketPODetailsInput!
    $condition: ModelEDIBigBasketPODetailsConditionInput
  ) {
    updateEDIBigBasketPODetails(input: $input, condition: $condition) {
      id
      BASIC_COST
      CASES
      DATE
      DESCRIPTION
      EAN
      HSN_CODE
      MAT
      MRP
      PO
      QTY
      SHIP_CODE
      SHIP_FROM
      SHIP_TO
      SKU_CODE
      SO
      STATUS
      TOTAL_AMOUNT
      createdAt
      updatedAt
    }
  }
`;
export const deleteEDIBigBasketPODetails = /* GraphQL */ `
  mutation DeleteEDIBigBasketPODetails(
    $input: DeleteEDIBigBasketPODetailsInput!
    $condition: ModelEDIBigBasketPODetailsConditionInput
  ) {
    deleteEDIBigBasketPODetails(input: $input, condition: $condition) {
      id
      BASIC_COST
      CASES
      DATE
      DESCRIPTION
      EAN
      HSN_CODE
      MAT
      MRP
      PO
      QTY
      SHIP_CODE
      SHIP_FROM
      SHIP_TO
      SKU_CODE
      SO
      STATUS
      TOTAL_AMOUNT
      createdAt
      updatedAt
    }
  }
`;
export const createEDIBigBasketUsers = /* GraphQL */ `
  mutation CreateEDIBigBasketUsers(
    $input: CreateEDIBigBasketUsersInput!
    $condition: ModelEDIBigBasketUsersConditionInput
  ) {
    createEDIBigBasketUsers(input: $input, condition: $condition) {
      id
      email
      customerName
      payerId
      userRole
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateEDIBigBasketUsers = /* GraphQL */ `
  mutation UpdateEDIBigBasketUsers(
    $input: UpdateEDIBigBasketUsersInput!
    $condition: ModelEDIBigBasketUsersConditionInput
  ) {
    updateEDIBigBasketUsers(input: $input, condition: $condition) {
      id
      email
      customerName
      payerId
      userRole
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteEDIBigBasketUsers = /* GraphQL */ `
  mutation DeleteEDIBigBasketUsers(
    $input: DeleteEDIBigBasketUsersInput!
    $condition: ModelEDIBigBasketUsersConditionInput
  ) {
    deleteEDIBigBasketUsers(input: $input, condition: $condition) {
      id
      email
      customerName
      payerId
      userRole
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createEDIBigBasketMasterData = /* GraphQL */ `
  mutation CreateEDIBigBasketMasterData(
    $input: CreateEDIBigBasketMasterDataInput!
    $condition: ModelEDIBigBasketMasterDataConditionInput
  ) {
    createEDIBigBasketMasterData(input: $input, condition: $condition) {
      id
      BB_SKU_CODE
      BRAND
      C_MATERIAL
      DIV
      EAN
      P_DESC
      P_MATERIAL
      S_DESC
      createdAt
      updatedAt
    }
  }
`;
export const updateEDIBigBasketMasterData = /* GraphQL */ `
  mutation UpdateEDIBigBasketMasterData(
    $input: UpdateEDIBigBasketMasterDataInput!
    $condition: ModelEDIBigBasketMasterDataConditionInput
  ) {
    updateEDIBigBasketMasterData(input: $input, condition: $condition) {
      id
      BB_SKU_CODE
      BRAND
      C_MATERIAL
      DIV
      EAN
      P_DESC
      P_MATERIAL
      S_DESC
      createdAt
      updatedAt
    }
  }
`;
export const deleteEDIBigBasketMasterData = /* GraphQL */ `
  mutation DeleteEDIBigBasketMasterData(
    $input: DeleteEDIBigBasketMasterDataInput!
    $condition: ModelEDIBigBasketMasterDataConditionInput
  ) {
    deleteEDIBigBasketMasterData(input: $input, condition: $condition) {
      id
      BB_SKU_CODE
      BRAND
      C_MATERIAL
      DIV
      EAN
      P_DESC
      P_MATERIAL
      S_DESC
      createdAt
      updatedAt
    }
  }
`;
export const createEDIBigBasketShippingCodes = /* GraphQL */ `
  mutation CreateEDIBigBasketShippingCodes(
    $input: CreateEDIBigBasketShippingCodesInput!
    $condition: ModelEDIBigBasketShippingCodesConditionInput
  ) {
    createEDIBigBasketShippingCodes(input: $input, condition: $condition) {
      id
      FC
      SHIPPING_CODE
      createdAt
      updatedAt
    }
  }
`;
export const updateEDIBigBasketShippingCodes = /* GraphQL */ `
  mutation UpdateEDIBigBasketShippingCodes(
    $input: UpdateEDIBigBasketShippingCodesInput!
    $condition: ModelEDIBigBasketShippingCodesConditionInput
  ) {
    updateEDIBigBasketShippingCodes(input: $input, condition: $condition) {
      id
      FC
      SHIPPING_CODE
      createdAt
      updatedAt
    }
  }
`;
export const deleteEDIBigBasketShippingCodes = /* GraphQL */ `
  mutation DeleteEDIBigBasketShippingCodes(
    $input: DeleteEDIBigBasketShippingCodesInput!
    $condition: ModelEDIBigBasketShippingCodesConditionInput
  ) {
    deleteEDIBigBasketShippingCodes(input: $input, condition: $condition) {
      id
      FC
      SHIPPING_CODE
      createdAt
      updatedAt
    }
  }
`;
