import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import Dashboard from '../../dashboard';
import moment from 'moment';
import './index.scss';
import { queryAmendmentItems, queryInvoicePendingItems, queryEANFailedItems, queryCaseLotAmendItems, queryCompletePOEvents } from '../../../graphql/queries';
import { API } from 'aws-amplify';
import { getItems, getOnlyID, getStatus, getSystemSKUList } from '../../../utils/functions';
import OrdersActivityTable from '../../../utils/components/tables/orderAmemndament';
import EanLookUpTable from '../../../utils/components/tables/eanLookUp';
import PendingInvoicesTable from '../../../utils/components/tables/pendingInvoices';
import { ORDERS_DASHBOARD, ORDERS_PODATA } from '../../../utils/routingUrl';
import {
    COMPONENTFROM_OPENINVOICE, COMPONENTFROM_OPENPOS, DATE_PICKER_YEAR_MONTH_DATE, KEY_AMENDMENT, KEY_ITEMAMEND,
    KEY_ITEMCASELOT, KEY_ITEMEAN, STATUS_CASELOT_AMENDMENT, STATUS_EAN_LOOPUP_FAILED, STATUS_INVOICE_PENDING, STATUS_PO_AMENDMENT,
    STATUS_INVOICE_AMENDMENT, HASH_KEY, NUMBER_ONE, NUMBER_TWO
} from '../../../utils/constants';
import CustomNavigator from '../../../utils/components/backToOrders';
import POByID from '../../../utils/components/tables/searchPage/openPO';
import InvoiceByID from '../../../utils/components/tables/searchPage/invoice';
const INVOICE = "Invoice";
const TYPE_INVOICE = 'InvoiceItem';

const OrdersActivity = (props) => {
    const location = useLocation();
    const [activeStatus, setActiveStatus] = useState(getStatus(location?.state?.sk));
    const [orderDetails, setOrderDetails] = useState(location?.state?.data);
    const [allDetails, setAllDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(1);
    const history = useHistory();
    const [nextToken, setNextToken] = useState(null)
    const [details, setDetails] = useState({});

    const [startDateTime, setStartDate] = useState();
    const [endDateTime, setEndDate] = useState();

    useEffect(() => {
        const { sk, pk, itemID, currentTab, startDateTime, endDateTime } = location?.state;
        if (activeStatus === STATUS_PO_AMENDMENT || activeStatus === STATUS_EAN_LOOPUP_FAILED || activeStatus === STATUS_CASELOT_AMENDMENT) getDetails(currentTab, sk, pk, itemID);
        if (activeStatus === STATUS_INVOICE_PENDING) getDetails(currentTab, "Item#", pk);
        setCurrentTab(currentTab);
        setStartDate(startDateTime);
        setEndDate(endDateTime);
        setDetails({ activeStatus, sk, pk, itemID })
    }, [location])

    useEffect(() => {
        if (nextToken !== null) {
            const { sk, pk, itemID } = location.state;
            if (activeStatus === STATUS_PO_AMENDMENT || activeStatus === STATUS_EAN_LOOPUP_FAILED || activeStatus === STATUS_CASELOT_AMENDMENT) getDetails(sk, pk, itemID);
            if (activeStatus === STATUS_INVOICE_PENDING) getDetails("Item#", pk);
        }
    }, [nextToken])

    // Fetching Data Based on Status
    async function getDetails(currentTab, sk, pk, itemID) {
        try {
            if (activeStatus === STATUS_PO_AMENDMENT) {
                if (currentTab === NUMBER_TWO) {
                    //PO Amendment
                    const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK: pk, SK: sk, Type: sk, itemIds: itemID } });
                    let obj = {}
                    obj.PassedItems = apiData.data.queryCompletePOEvents.body.PassedItems
                    obj.FailedItems = apiData.data.queryCompletePOEvents.body.AmendmentItems
                    setAllDetails(obj);

                    // Invoice Amendment --> (extra param) PassedItemsSK": "InvoiceItem#0932041946", <InvoiceID>
                    // const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK: pk, SK: sk, Type: sk, itemIds: itemID, PassedItemsSK: PassedItemsSK } });
                    // Response - PassedInvoiceItems


                    // const apiData = await API.graphql({ query: queryAmendmentItems, variables: { PK: pk, SK: sk, itemIds: itemID, first: 100, after: nextToken } });
                    // setAllDetails([...allDetails, ...apiData.data.queryAmendmentItems.items]);
                    // if (apiData.data.queryAmendmentItems.nextToken != null) setNextToken(apiData.data.queryAmendmentItems.nextToken);
                    // else setNextToken(null);

                    setLoading(false);
                } else if (currentTab === NUMBER_ONE) {
                    const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK: pk, SK: sk, Type: sk, itemIds: itemID, PassedItemsSK: TYPE_INVOICE + HASH_KEY + getItems(pk, 3) } });
                    let obj = {}
                    obj.PassedItems = apiData.data.queryCompletePOEvents.body.PassedInvoiceItems;
                    obj.FailedItems = apiData.data.queryCompletePOEvents.body.AmendmentItems;
                    setAllDetails(obj);
                    setLoading(false);
                }
            } else if (activeStatus === STATUS_INVOICE_PENDING) {
                const apiData = await API.graphql({ query: queryInvoicePendingItems, variables: { PK: pk, SK: sk, first: 100, after: nextToken } });
                setAllDetails([...allDetails, ...apiData.data.queryInvoicePendingItems.items]);
                if (apiData.data.queryInvoicePendingItems.nextToken != null) setNextToken(apiData.data.queryInvoicePendingItems.nextToken);
                else setNextToken(null);
                setLoading(false);
            } else if (activeStatus === STATUS_EAN_LOOPUP_FAILED) {
                const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK: pk, SK: sk, Type: sk, itemIds: itemID } });
                setAllDetails(apiData.data.queryCompletePOEvents.body);

                // const apiData = await API.graphql({ query: queryEANFailedItems, variables: { PK: pk, SK: sk, itemIds: itemID, first: 100, after: nextToken } });
                // setAllDetails([...allDetails, ...apiData.data.queryEANFailedItems.items]);
                // if (apiData.data.queryEANFailedItems.nextToken != null) setNextToken(apiData.data.queryEANFailedItems.nextToken);
                // else setNextToken(null);
                setLoading(false);
            } else if (activeStatus === STATUS_CASELOT_AMENDMENT) {
                const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK: pk, SK: sk, Type: sk, itemIds: itemID } });
                let obj = {}
                obj.PassedItems = apiData.data.queryCompletePOEvents.body.PassedItems
                obj.FailedItems = apiData.data.queryCompletePOEvents.body.CaseLotAmendmentItems
                setAllDetails(obj);

                // const apiData = await API.graphql({ query: queryCaseLotAmendItems, variables: { PK: pk, SK: sk, itemIds: itemID, first: 100, after: nextToken } });
                // setAllDetails([...allDetails, ...apiData.data.queryCaseLotAmendItems.items]);
                // if (apiData.data.queryCaseLotAmendItems.nextToken != null) setNextToken(apiData.data.queryCaseLotAmendItems.nextToken);
                // else setNextToken(null);
                setLoading(false);
            }
        } catch (error) { console.log('error occured while fetching the data ', error); }
    }

    const navTOOrders = () => {
        history.push({
            pathname: ORDERS_DASHBOARD, state: {
                currentTab, startDateTime, endDateTime, openPODataSet: location.state.openPODataSet, prevURL: window.location.pathname
            }
        })
        // props.backToOrders()
    }
    const navTOPOData = () => {
        if (orderDetails.Type === INVOICE) {
            var splitData = orderDetails.PK.split('#');
            history.push({
                pathname: ORDERS_PODATA, state: {
                    PK: splitData[0] + '#' + splitData[1], SK: splitData[2] + '#' + splitData[3], currentTab, componentFrom: COMPONENTFROM_OPENINVOICE, componentDetails: details, componentOrderDetails: orderDetails, startDateTime, endDateTime, openPODataSet: location.state.openPODataSet
                }
            })
        } else history.push({ pathname: ORDERS_PODATA, state: { PK: orderDetails.PK, SK: orderDetails.SK, currentTab, componentFrom: COMPONENTFROM_OPENPOS, componentDetails: details, componentOrderDetails: orderDetails, startDateTime, endDateTime, openPODataSet: location.state.openPODataSet } })
    }
    const keyName = activeStatus === STATUS_PO_AMENDMENT ? KEY_ITEMAMEND : activeStatus === STATUS_CASELOT_AMENDMENT ? KEY_ITEMCASELOT : '';
    const nameChange = (e) => { }

    return (
        <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <div className="displaySpacebetween marginBottomRg">
                        <h2 className="heading18Bold margin0">{activeStatus === STATUS_PO_AMENDMENT ? "Amendment Details"
                            : activeStatus === STATUS_EAN_LOOPUP_FAILED ? "Article Look up Failed Line Items" : activeStatus === STATUS_INVOICE_PENDING ? "Line Items Pending for Invoice"
                                : activeStatus === STATUS_CASELOT_AMENDMENT ? 'Caselot Amendment Details' : ""}</h2>
                        {/* <CustomButton handleSubmit={navTOOrders} buttonLabel="Back To Orders" className="regularSize" /> */}
                        <CustomNavigator onChange={navTOOrders} />
                    </div>
                    <div className="detailsInline">
                        <p className="sub14regular"><span className="sub14Bold">Status : </span> {currentTab === NUMBER_ONE ? STATUS_INVOICE_AMENDMENT : activeStatus}</p>
                        {/* <p className="sub14regular"><span className="sub14Bold"> {activeStatus === KEY_AMENDMENT ? "Invoice ID :" : ""}</span> {activeStatus === KEY_AMENDMENT ? getOnlyID(orderDetails.PK) : ""}</p> */}
                        {orderDetails.Type === INVOICE ? <p className="sub14regular"><span className="sub14Bold">{INVOICE} ID : </span>{getOnlyID(orderDetails.SK)}</p> : ''}
                    </div>
                    <div className="detailsInline">
                        {/* <p className="sub14regular"><span className="sub14Bold"> PO ID : </span>{activeStatus === KEY_AMENDMENT ? getOnlyID(orderDetails.PK) : getOnlyID(orderDetails.SK)}</p> */}
                        <p className="sub14regular"><span className="sub14Bold"> PO ID : </span><span className="cursorpointer colorBlue" onClick={navTOPOData} >{orderDetails.Type === INVOICE ? getOnlyID(orderDetails.PK) : getOnlyID(orderDetails.SK)}</span></p>
                        <p className="sub14regular"><span className="sub14Bold">Created Date :</span> {` ${moment(orderDetails.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}`}</p>
                    </div>

                    {activeStatus === STATUS_PO_AMENDMENT || activeStatus === STATUS_CASELOT_AMENDMENT ?
                        <>{!loading ?
                            <>
                                <OrdersActivityTable data={allDetails.FailedItems} type={keyName} />
                                {currentTab === NUMBER_TWO && activeStatus === STATUS_PO_AMENDMENT || currentTab === NUMBER_ONE || activeStatus === STATUS_CASELOT_AMENDMENT ? <>
                                    <br />
                                    <p className="sub14regular"><span className="sub14Bold">Remaining line items:</span></p>
                                    {currentTab === NUMBER_TWO && activeStatus === STATUS_PO_AMENDMENT ? <POByID data={allDetails.PassedItems} /> :
                                        currentTab === NUMBER_ONE ? <InvoiceByID data={allDetails.PassedItems} /> :
                                            activeStatus === STATUS_CASELOT_AMENDMENT ? <POByID data={allDetails.PassedItems} /> : null}
                                </> : null}
                            </> :
                            <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>}
                        </>
                        : activeStatus === STATUS_EAN_LOOPUP_FAILED ?
                            <>{!loading ?
                                <>
                                    <EanLookUpTable data={allDetails.EANItems} />
                                    <br />
                                    <p className="sub14regular"><span className="sub14Bold">Remaining line items:</span></p>
                                    <POByID data={allDetails.PassedItems} />
                                </> :
                                <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>}
                            </>
                            : activeStatus === STATUS_INVOICE_PENDING ?
                                <>{!loading ?
                                    <PendingInvoicesTable data={allDetails} /> :
                                    <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>}
                                </> : ""}

                </Col>
            </Row>
        </Dashboard>
    );
}

export default OrdersActivity;
