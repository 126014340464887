import React, { useState, useEffect , useHistory} from 'react';
import Dashboard from '../dashboard';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Row, Col, Spin, Tabs, message } from 'antd';
import { Table } from 'antd';
import CustomDatePicker from '../../utils/components/customeInputFields/datePicker';
import CustomPopover from '../../utils/components/popOver';
import CustomNavigator from '../../utils/components/backToOrders';
import CustomSearchBar from '../../utils/components/customeInputFields/searchField';
import {
  CLOSED_POS, DATE_PICKER_YEAR_MONTH_DATE, NUMBER_TWO, NUMBER_THREE, DATE_PICKER_TIME_ZONE_Z, OpenPOs,
  KEY_INSTANTACK, KEY_INVOICE, KEY_ITEMAMEND, KEY_ITEMCASELOT, KEY_ITEMEAN, KEY_XMLFAI, POs,
} from '../../utils/constants';
import { BB_ORDERS_ACTIVITY } from '../../utils/routingUrl';
import OrdersTable from '../../utils/components/tables/orders';
import InvoiceTable from '../../utils/components/tables/orderInvoice';
import ClosedPOs from '../../utils/components/tables/closedPO';
import { BBOrdersTabs } from '../../utils/functions';
import AsnDateModal from '../../utils/components/modal/asnInvoice';
import { queryOpenInvoiceByDateRange, 
    queryOpenPOByDateRange, 
    queryOpenInvoicePOCount, 
    queryClosedPOByDateRange, 
    listEDIBigBasketPOSOStatuses} from '../../graphql/queries';
    
import {createEDIBigBasketPOSOStatus } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import './index.scss';
import CustomPaginationWithPages from '../../utils/components/pagination/index1';
import BBOrdersActivity from './features/BBOrderActivity';
const { TabPane } = Tabs;

const INVOICE = 'invoice';
const CLOSEDPO = 'closedPO';
const OPENPO = 'openOP';
const NUMBER_ONE = 1;
const NUMBER_TEN = 10;
const POID = 'poID';
const INID = 'inID';
const SALESORDER = 'SalesOrder';
const CREATEDDATE = 'createdDate';
const ASCENDING = 'ascending';
const DECENDING = 'decending';
const NOOFRECORDS = 'noofRecords';
const NEXTBTN = 'nextBtn';

const columns = [{
    title: "PO Number",
    dataIndex: 'PO',
    key: 'PO',
    width: "40%"
}, {
    title: "SO Number",
    dataIndex: 'SO',
    key: 'SO',
    width: "40%",
}, {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
}];

const BBOrdersDashboard = () => {
  
  const { state: st } = useLocation();
  const [POSelected, setPOSelected] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tempData, setTempData] = useState([
    {
        PO : "1113456678",
        SO : "1233789556",
        Status : "InProgress"
    },
    {
        PO : "11134990978",
        SO : "1233455556",
        Status : "Blocked"
    },
    {
        PO : "1890456678",
        SO : "1233450986",
        Status : "InProgress"
    },
    {
        PO : "11134876678",
        SO : "12334756756",
        Status : "Blocked"
    }
  ]);
  const [companyName, setCompanyName] = useState(sessionStorage.getItem('companySK'));
  const [company, setCompany] = useState(sessionStorage.getItem('companyName'));
  const [tabKey, setTabKey] = useState(NUMBER_ONE);
  const [startDateTime, setStartDate] = useState();
  const [endDateTime, setEndDate] = useState();
  const [totalAsnCount, setTotalAsnCount] = useState(0);
  const [totalAmendCount, setTotalAmendCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setModalVisible] = useState(false);
  const [tabData, setTabData] = useState({ tab1Date: false, tab2Date: false, tab3Date: false });
  const [PODetails, setPODetails] = useState("");
  const [searchNextToken, setSearchNextToken] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [type, setType] = useState('');

  // invoice pagination initial state
  const [invoicePOData, setInvoicePOData] = useState({
    invoicePOTotalData: {}, invoicePOTableData: [],
    invoicePOPageNumber: '', invoicePONextToken: null,
    invoicePOPaginationData: {
      invoicePONoOfRecordsPerPage: NUMBER_TEN,
      invoicePONextBtn: true,
      invoicePOPreviousBtn: true,
      invoicePOFromCount: NUMBER_ONE,
      invoicePOToCount: NUMBER_TEN,
      invoicePOShowItems: [10, 20, 50, 100]
    },
    invoicePOArray: [], invoicePOActiveElement: null, invoicePOActiveSortOrder: null
  })

  // closed po pagination initial state
  const [closedPOData, setClosedPOData] = useState({
    closedPOTotalData: {}, closedPOTableData: [],
    closedPOPageNumber: '', closedPONextToken: null,
    closedPOPaginationData: {
      closedPONoOfRecordsPerPage: NUMBER_TEN,
      closedPONextBtn: true,
      closedPOPreviousBtn: true,
      closedPOFromCount: NUMBER_ONE,
      closedPOToCount: NUMBER_TEN,
      closedPOShowItems: [10, 20, 50, 100]
    },
    closedPOArray: [], closedPOActiveElement: null, closedPOActiveSortOrder: null
  })

  // open pos pagination initial state
  const [openPOData, setOpenPOData] = useState({
    openPOTotalData: {}, openPOTableData: [],
    openPOPageNumber: '', openPONextToken: null,
    openPOPaginationData: {
      openPONoOfRecordsPerPage: NUMBER_TEN,
      openPONextBtn: true,
      openPOPreviousBtn: true,
      openPOFromCount: NUMBER_ONE,
      openPOToCount: NUMBER_TEN,
      openPOShowItems: [10, 20, 50, 100]
    },
    openPOArray: [], openPOActiveElement: null, openPOActiveSortOrder: null, openPOStatusSetKeys: []
  })

  const { tab1Date, tab2Date, tab3Date } = tabData;

  const { invoicePOTotalData, invoicePOTableData, invoicePOPageNumber, invoicePONextToken, invoicePOActiveElement, invoicePOActiveSortOrder, invoicePOArray } = invoicePOData;
  const { invoicePONoOfRecordsPerPage, invoicePONextBtn, invoicePOPreviousBtn, invoicePOFromCount, invoicePOToCount, invoicePOShowItems } = invoicePOData.invoicePOPaginationData;

  const { openPOTotalData, openPOTableData, openPOPageNumber, openPONextToken, openPOArray, openPOActiveElement, openPOActiveSortOrder, openPOStatusSetKeys } = openPOData;
  const { openPONoOfRecordsPerPage, openPONextBtn, openPOPreviousBtn, openPOFromCount, openPOToCount, openPOShowItems } = openPOData.openPOPaginationData;

  const { closedPOTotalData, closedPOTableData, closedPOPageNumber, closedPONextToken, closedPOArray, closedPOActiveElement, closedPOActiveSortOrder } = closedPOData;
  const { closedPONoOfRecordsPerPage, closedPONextBtn, closedPOPreviousBtn, closedPOFromCount, closedPOToCount, closedPOShowItems } = closedPOData.closedPOPaginationData;

  const navToOrdersActivity = (item) => {
      console.log(item);
      if(item.PO){
          setPODetails(item);
          //history.push(BB_ORDERS_ACTIVITY);
          setPOSelected(true);
      }

  }
  const backToPOTable = () => {
      setPODetails({});
      setPOSelected(false);
  }

  const setTableRowData = (data) => {
    // Mapping Data to the table
    let dataMapper = [];
    if (data && data.length) {
        data.map((itemData, i) => {
            //const [ item, status ] = itemData;
            var obj = {};
            if (itemData.Status != null) {
                obj = {
                    key: i,
                    PO: <CustomPopover onNav={() => navToOrdersActivity(itemData)} data= {<span className='cursorpointer'>{itemData.PO}</span>} content ={itemData.PO} />,
                    SO: <CustomPopover  data= {itemData.SO} content ={itemData.SO} />,
                    Status: <CustomPopover  data= {itemData.Status} content ={itemData.Status}/>
                }
             } 
            //else {
            //     obj = {
            //         key: i,
            //         createdDate: <CustomPopover onNav={() => navToPOData(item)} data={<span className="cursorpointer">
            //             {` ${moment(item.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}`}</span>} />,
            //         poID: <CustomPopover onNav={() => navToPOData(item)} data={<span className="cursorpointer">
            //             {getOnlyID(item.SK)}</span>} />,
            //         status: <CustomPopover onNav={() => navToPOData(item)} data={<span className="cursorpointer">
            //             -</span>} />
            //     }
            // }
            dataMapper.push(obj);
        })
    }
    setTableData(dataMapper)
}

  const handleStartDate = (e) => {
    setStartDate(new Date(e));
    var endDate = new Date(e);
    var endDateTime = new Date(endDate.setMonth(endDate.getMonth() + 3));
    setEndDate(endDateTime);
    setTabData(state => ({ ...state, tab1Date: true, tab2Date: true, tab3Date: true }));
    if (tabKey == NUMBER_ONE) { setInititalState(INVOICE); fetchInvoiceData(e, endDateTime, null, NUMBER_ONE, invoicePONoOfRecordsPerPage, INVOICE); }
    else if (tabKey == NUMBER_TWO) { setInititalState(OPENPO); fetchPosData(e, endDateTime, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO); }
    else if (tabKey == NUMBER_THREE) { setInititalState(CLOSEDPO); fetchClosedPoData(e, endDateTime, null, NUMBER_ONE, closedPONoOfRecordsPerPage, CLOSEDPO); }
  }

  const handleEndDate = (e) => {
    setEndDate(new Date(e));
    setTabData(state => ({ ...state, tab1Date: true, tab2Date: true, tab3Date: true }));
    if (tabKey == NUMBER_ONE) { setInititalState(INVOICE); fetchInvoiceData(startDateTime, e, null, NUMBER_ONE, invoicePONoOfRecordsPerPage, INVOICE); }
    else if (tabKey == NUMBER_TWO) { setInititalState(OPENPO); fetchPosData(startDateTime, e, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO); }
    else if (tabKey == NUMBER_THREE) { setInititalState(CLOSEDPO); fetchClosedPoData(startDateTime, e, null, NUMBER_ONE, closedPONoOfRecordsPerPage, CLOSEDPO); }
  }

  const onTabChange = async (e, key) => {
    setTabKey(e);
    // if (String(e) == NUMBER_ONE && tab1Date) {
    //   setInititalState(INVOICE); fetchInvoiceData(startDateTime, endDateTime, null, NUMBER_ONE, invoicePONoOfRecordsPerPage, INVOICE);
    // } else if (String(e) == NUMBER_TWO && tab2Date) {
    //   await setInititalState(OPENPO); fetchPosData(startDateTime, endDateTime, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO);
    // } else if (String(e) == NUMBER_THREE && tab3Date) {
    //   setInititalState(CLOSEDPO); fetchClosedPoData(startDateTime, endDateTime, null, NUMBER_ONE, closedPONoOfRecordsPerPage, CLOSEDPO);
    // }
  }

  const setInititalState = async (type) => {
    if (type === INVOICE) {
      setInvoicePOData(state => ({
        ...state, invoicePOPaginationData: {
          ...state.invoicePOPaginationData, invoicePOFromCount: NUMBER_ONE, invoicePOToCount: invoicePONoOfRecordsPerPage, invoicePOPreviousBtn: true, invoicePONextBtn: false
        }, invoicePOPageNumber: 1, invoicePOActiveElement: null, invoicePOActiveSortOrder: null, invoicePOTotalData: {}, invoicePOArray: []
      }));
    }
    else if (type === OPENPO) {
      await setOpenPOData(state => ({
        ...state, openPOPaginationData: {
          ...state.openPOPaginationData, openPOFromCount: NUMBER_ONE, openPOToCount: openPONoOfRecordsPerPage, openPOPreviousBtn: true, openPONextBtn: false
        }, openPOPageNumber: 1, openPOActiveElement: null, openPOActiveSortOrder: null, openPOTotalData: {}, openPOArray: []
      }));
    }
    else if (type === CLOSEDPO) {
      setClosedPOData(state => ({
        ...state, closedPOPaginationData: {
          ...state.closedPOPaginationData, closedPOFromCount: NUMBER_ONE, closedPOToCount: closedPONoOfRecordsPerPage, closedPOPreviousBtn: true, closedPONextBtn: false
        }, closedPOPageNumber: 1, closedPOActiveElement: null, closedPOActiveSortOrder: null, closedPOTotalData: {}, closedPOArray: []
      }));
    }
  }

  

  // Fetching Apis Data based on pagination by passing nextToken
  useEffect(() => {
    var startDate, endDate;

    if (st && st.startDateTime != undefined && st.endDateTime != undefined) {
      startDate = st.startDateTime; endDate = st.endDateTime;
    } else {
      var now = new Date(); endDate = new Date();
      startDate = new Date(now.setMonth(now.getMonth() - 3));
    }
    setStartDate(startDate); setEndDate(endDate);
    setTabKey(st && st.currentTab ? st.currentTab : '2');
    if (st && st.currentTab && st.currentTab == NUMBER_TWO) {
      fetchPosData(startDate, endDate, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO);
      setTabData(state => ({ ...state, tab1Date: true, tab3Date: true }));
    } else if (st && st.currentTab && st.currentTab == NUMBER_THREE) {
      fetchClosedPoData(startDate, endDate, null, NUMBER_ONE, closedPONoOfRecordsPerPage, CLOSEDPO);
      setTabData(state => ({ ...state, tab1Date: true, tab2Date: true }));
    } else {
      fetchPosData(startDate, endDate, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO);
      setTabData(state => ({ ...state, tab1Date: true, tab3Date: true }));
      // fetchInvoiceData(startDate, endDate, null, NUMBER_ONE, invoicePONoOfRecordsPerPage, INVOICE);
      // fetchInvoiceCount();
      // setTabData(state => ({ ...state, tab2Date: true, tab3Date: true }));
    }
  }, [companyName]);

  async function tempCreate(data){
    let all = [];
    data.forEach(async (d) => {
        console.log(d);
        let apiRes = await API.graphql(graphqlOperation(createEDIBigBasketPOSOStatus, { input: d }));
      all.push(apiRes);
    })
    console.log("all",all);
}

  useEffect(() => {
    //tempCreate(tempData);
      fetchPOData();
      //setTableRowData(tempData);
  },[])

  // useEffect(async () => {
  //   //tempCreate(tempData);
  //     //fetchPOData();
  //     //setTableRowData(tempData);
  //     let apiRes = await API.graphql(graphqlOperation(DeliveryStatusCheck, { input: d }));

  // },[tableData])


  async function fetchPOData() {
      try {
        let res = await API.graphql(graphqlOperation(listEDIBigBasketPOSOStatuses));
        if(res){
            if(res.data.listEDIBigBasketPOSOStatuses.items){
                let tempData = res.data.listEDIBigBasketPOSOStatuses.items;
                setTableRowData(tempData);
            }
        }
      }
      catch(e){

      }
  }

  // Fetiching queryOpenInvoicePOCount Data
  async function fetchInvoiceCount() {
    if (sessionStorage.getItem('companyName')) {
      let str = sessionStorage.getItem('companyName').split(' ')[0].toLowerCase();
      setCompany(str.charAt(0).toUpperCase() + str.slice(1))
    }
    const countApiData = await API.graphql({ query: queryOpenInvoicePOCount, variables: { GSI1PK: companyName + '#OpenInvoice', first: 12, after: null } });
    setLoading(false);
    setTotalAsnCount(countApiData.data.queryOpenInvoicePOCount.ASNCount);
    setTotalAmendCount(countApiData.data.queryOpenInvoicePOCount.AmendmentCount);
  }

  // Fetiching OpenInvoice Data
  async function fetchInvoiceData(startDate, endDate, nextToken, pageNumber = 1, noOfRecordsPerPage, from, fCount, tCount) {
    var startDateFormat = moment.utc(startDate).format(DATE_PICKER_TIME_ZONE_Z);
    var endDateFormat = moment.utc(endDate).format(DATE_PICKER_TIME_ZONE_Z);

    try {
      setLoading(true);
      const apiData = await API.graphql({
        query: queryOpenInvoiceByDateRange, variables: {
          GSI1PK: companyName + '#OpenInvoice',
          start: startDateFormat, end: endDateFormat, first: noOfRecordsPerPage, after: nextToken
        }
      });
      var data = apiData.data.queryOpenInvoiceByDateRange;
      setTabData(state => ({ ...state, tab1Date: false }));

      var invoicePOArry;
      if (from === NOOFRECORDS || from == INVOICE) invoicePOArry = data.items;
      else invoicePOArry = [...invoicePOArray, ...data.items];

      setInvoicePOData(state => ({
        ...state, invoicePOTotalData: { ...state.invoicePOTotalData, [pageNumber]: data.items }, invoicePOTableData: data.items,
        invoicePONextToken: data.nextToken, invoicePOPageNumber: pageNumber, invoicePOArray: invoicePOArry
      }))

      if (data.nextToken) {
        setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePONextBtn: false } }))
      } else if (data.nextToken == null) {
        var toCount1 = invoicePOToCount + data.items.length;
        if (from == INVOICE) {
          setInvoicePOData(state => ({
            ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePONextBtn: true, invoicePOToCount: data.items.length }
          }))
        } else {
          setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePONextBtn: true, invoicePOToCount: toCount1 } }))
        }
      }
      if (from == NEXTBTN && (invoicePOActiveSortOrder == ASCENDING || invoicePOActiveSortOrder == DECENDING)) {
        invoicePOSortingFunction(invoicePOActiveElement, invoicePOActiveSortOrder, invoicePOArry, fCount, invoicePOToCount + data.items.length)
      }
    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
  }

  // Fetiching OpenPOs Data
  async function fetchPosData(startDate, endDate, nextToken, pageNumber = 1, noOfRecordsPerPage, from, fCount, tCount) {
    var startDateFormat = moment.utc(startDate).format(DATE_PICKER_TIME_ZONE_Z);
    var endDateFormat = moment.utc(endDate).format(DATE_PICKER_TIME_ZONE_Z);

    try {
      setLoading(true);
      const apiData = await API.graphql({
        query: queryOpenPOByDateRange,
        variables: { GSI1PK: companyName + '#OpenPO', start: startDateFormat, end: endDateFormat, first: noOfRecordsPerPage, after: nextToken }
      });
      var dataMapper = [], i = 0, data = apiData.data.queryOpenPOByDateRange;
      let amdStatussets = [], invoiceStatussets = [], eanStatussets = [], xmlStatussets = [], caselotStatussets = [], instantACKStatussets = [];
      data.items && data.items.map(item => {
        let statussets = [];
        if (item.StatusSet != null) {
          item.StatusSet && item.StatusSet.map((status, j) => {
            if (status.includes(KEY_ITEMAMEND)) amdStatussets.push(status);
            else if (status.includes(KEY_INVOICE)) invoiceStatussets.push(status)
            else if (status.includes(KEY_ITEMEAN)) eanStatussets.push(status)
            else if (status.includes(KEY_XMLFAI)) xmlStatussets.push(status)
            else if (status.includes(KEY_ITEMCASELOT)) caselotStatussets.push(status)
            else if (status.includes(KEY_INSTANTACK)) instantACKStatussets.push(status)
            if (!statussets.some(substring => status.includes(substring))) {
              statussets.push(status.substring(0, 6));
              dataMapper.push({ item, status, i });
              i++;
            }
          })
        } else dataMapper.push({ item, status: null, i });
      })

      setTabData(state => ({ ...state, tab2Date: false }));

      var openPOArry;
      if (from === NOOFRECORDS || from === OPENPO) openPOArry = dataMapper;
      else openPOArry = [...openPOArray, ...dataMapper];

      setOpenPOData(state => ({
        ...state, openPOArray: openPOArry, openPOTotalData: { ...state.openPOTotalData, [pageNumber]: dataMapper },
        openPOTableData: dataMapper, openPONextToken: data.nextToken, openPOPageNumber: pageNumber,
        openPOStatusSetKeys: { ...state.openPOStatusSetKeys, amdStatussets, invoiceStatussets, eanStatussets, xmlStatussets, caselotStatussets, instantACKStatussets }
      }))

      if (data.nextToken) {
        var toCount1 = openPOToCount + dataMapper.length;
        setOpenPOData(state => ({
          ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: false, openPOToCount: from == OPENPO ? dataMapper.length : toCount1 }
        }))
      } else if (data.nextToken == null) {
        var toCount1 = openPOToCount + dataMapper.length;
        if (from == OPENPO) {
          setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: true, openPOToCount: dataMapper.length } }))
        } else {
          setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: true, openPOToCount: toCount1 } }))
        }
      }

      if (from === NEXTBTN && (openPOActiveSortOrder == ASCENDING || openPOActiveSortOrder == DECENDING)) {
        openPOSortingFunction(openPOActiveElement, openPOActiveSortOrder, openPOArry, fCount, openPOToCount + dataMapper.length);
      }

    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
  }

  const onSearch = async (searchValue, nextToken) => {
    try {
        setLoading(true);
        if (searchValue) {
            setTableData([]);
            const apiData = await API.graphql({
                query: listEDIBigBasketPOSOStatuses,
                variables: { //CustomerPayerID: companyName, first: 100, after: nextToken, 
                filter: { PO : { contains: searchValue.trim() } } }
            })
            var data = apiData.data.listEDIBigBasketPOSOStatuses;
            let totalData = [];
            if (nextToken === null) totalData = data.items;
            else totalData = [...tableData, ...data.items];
            setTableRowData(totalData);
            setSearchNextToken(data.nextToken);
        }
    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
}

const handleValueChange = (info, e) => {
  if (info === 'searchValue') {
      setSearchValue(e.target.value);
      if (e.target.value === "") {
          //setPaginationData(state => ({ ...state, noOfRecordsPerPage: NUMBER_TEN, nextBtn: false, previousBtn: true, fromCount: NUMBER_ONE, toCount: NUMBER_TEN }))
          fetchPOData();
      }
  } else setType(e);
}

  // Fetiching ClosedPos Data
  async function fetchClosedPoData(startDate, endDate, nextToken, pageNumber = 1, noOfRecordsPerPage, from, fCount, tCount) {
    var startDateFormat = moment.utc(startDate).format(DATE_PICKER_TIME_ZONE_Z);
    var endDateFormat = moment.utc(endDate).format(DATE_PICKER_TIME_ZONE_Z);

    try {
      setLoading(true);
      // const apiData = await API.graphql({ query: queryClosedPOByDateRange, variables: { PK: 'EDI#' + companyName, SK: "PO#", start: startDateFormat, end: endDateFormat, first: 10, after: nextToken } });
      const apiData = await API.graphql({
        query: queryClosedPOByDateRange,
        variables: {
          GSI1PK: companyName + '#COMPLETED', start: startDateFormat, end: endDateFormat,
          first: noOfRecordsPerPage, after: nextToken
        }
      });
      var data = apiData.data.queryClosedPOByDateRange;
      setTabData(state => ({ ...state, tab3Date: false }));

      var closedPOArry;
      if (from === CLOSEDPO || from === NOOFRECORDS) closedPOArry = data.items;
      else closedPOArry = [...closedPOArray, ...data.items];

      setClosedPOData(state => ({
        ...state, closedPOTotalData: { ...state.closedPOTotalData, [pageNumber]: data.items }, closedPOTableData: data.items,
        closedPONextToken: data.nextToken, closedPOPageNumber: pageNumber, closedPOArray: closedPOArry
      }))

      if (data.nextToken) setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: false } }))
      else if (data.nextToken == null) {
        var toCount1 = closedPOToCount + data.items.length;
        if (from === CLOSEDPO || from === NOOFRECORDS) {
          setClosedPOData(state => ({
            ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: true, closedPOToCount: data.items.length }
          }))
        } else {
          setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: true, closedPOToCount: toCount1 } }))
        }
      }
      if (from === NEXTBTN && (closedPOActiveSortOrder == ASCENDING || closedPOActiveSortOrder == DECENDING)) {
        closedPOSortingFunction(closedPOActiveElement, closedPOActiveSortOrder, closedPOArry, fCount, closedPOToCount + data.items.length)
      }

    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
  }

  const closedPONoOfRecordsPerPageChanged = (number) => {
    setClosedPOData(state => ({
      ...state,
      closedPOPaginationData: {
        ...state.closedPOPaginationData, closedPONoOfRecordsPerPage: number, closedPOToCount: number,
        closedPOFromCount: NUMBER_ONE, closedPOPreviousBtn: true, closedPONextBtn: false
      }, closedPOTotalData: {}, closedPOArray: [], closedPOActiveElement: null, closedPOActiveSortOrder: null, closedPOTableData: []
    }))
    fetchClosedPoData(startDateTime, endDateTime, null, NUMBER_ONE, number, NOOFRECORDS)
  }

  const invoicePONoOfRecordsPerPageChanged = (number) => {
    setInvoicePOData(state => ({
      ...state,
      invoicePOPaginationData: {
        ...state.invoicePOPaginationData, invoicePONoOfRecordsPerPage: number, invoicePOToCount: number,
        invoicePOFromCount: NUMBER_ONE, invoicePOPreviousBtn: true, invoicePONextBtn: false
      }, invoicePOTotalData: {}, invoicePOArray: [], invoicePOActiveElement: null, invoicePOActiveSortOrder: null, invoicePOTableData: []
    }))
    fetchInvoiceData(startDateTime, endDateTime, null, NUMBER_ONE, number, NOOFRECORDS)
  }

  const openPONoOfRecordsPerPageChanged = (number) => {
    setOpenPOData(state => ({
      ...state,
      openPOPaginationData: {
        ...state.openPOPaginationData, openPONoOfRecordsPerPage: number, openPOToCount: number,
        openPOFromCount: NUMBER_ONE, openPOPreviousBtn: true, openPONextBtn: false
      }, openPOTotalData: {}, openPOArray: [], openPOActiveElement: null, openPOActiveSortOrder: null, openPOTableData: []
    }))
    fetchPosData(startDateTime, endDateTime, null, NUMBER_ONE, number, OPENPO, NOOFRECORDS)
  }

  // to detch next Data
  const onNext = (type, currentPage) => {
    if (type === CLOSEDPO) {
      var fromCount1 = closedPOToCount + NUMBER_ONE;
      var toCount1 = closedPOToCount + closedPONoOfRecordsPerPage;
      var currentPageNumber = closedPOPageNumber + NUMBER_ONE;

      if (closedPOTotalData[currentPageNumber] == undefined) {
        setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOToCount: toCount1 } }));
        fetchClosedPoData(startDateTime, endDateTime, closedPONextToken, currentPageNumber, closedPONoOfRecordsPerPage, NEXTBTN, fromCount1, toCount1);
      } else {
        setClosedPOData(state => ({ ...state, closedPOTableData: closedPOTotalData[currentPageNumber], closedPOPageNumber: currentPageNumber }))
        var toCount2 = closedPOToCount + closedPOTotalData[currentPageNumber].length;
        if (currentPageNumber == Object.keys(closedPOTotalData).length && closedPONextToken == null) {
          setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: true, closedPOToCount: toCount2 } }))
        } else setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOToCount: toCount1 } }))
        if (closedPOActiveSortOrder == ASCENDING || closedPOActiveSortOrder == DECENDING) { closedPOSplitSortData(closedPOArray, fromCount1, toCount2) }
      }
      setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOFromCount: fromCount1, closedPOPreviousBtn: false } }))

    } else if (type === INVOICE) {
      var fromCount1 = invoicePOToCount + NUMBER_ONE;
      var toCount1 = invoicePOToCount + invoicePONoOfRecordsPerPage;
      var currentPageNumber = invoicePOPageNumber + NUMBER_ONE;

      if (invoicePOTotalData[currentPageNumber] == undefined) {
        setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOToCount: toCount1 } }));
        fetchInvoiceData(startDateTime, endDateTime, invoicePONextToken, currentPageNumber, invoicePONoOfRecordsPerPage, NEXTBTN, fromCount1, toCount1);
      } else {
        setInvoicePOData(state => ({ ...state, invoicePOTableData: invoicePOTotalData[currentPageNumber], invoicePOPageNumber: currentPageNumber }))
        var toCount2 = invoicePOToCount + invoicePOTotalData[currentPageNumber].length;
        if (currentPageNumber == Object.keys(invoicePOTotalData).length && invoicePONextToken == null) {
          setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePONextBtn: true, invoicePOToCount: toCount2 } }))
        } else setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOToCount: toCount1 } }))
        if (invoicePOActiveSortOrder == ASCENDING || invoicePOActiveSortOrder == DECENDING) { invoicePOSplitSortData(invoicePOArray, fromCount1, toCount2) }
      }
      setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOFromCount: fromCount1, invoicePOPreviousBtn: false } }))

    } else {
      var fromCount1 = openPOToCount + NUMBER_ONE;
      var toCount1 = openPOToCount + openPONoOfRecordsPerPage;
      var currentPageNumber = openPOPageNumber + NUMBER_ONE;

      if (openPOTotalData[currentPageNumber] == undefined) {
        setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOToCount: toCount1 } }));
        fetchPosData(startDateTime, endDateTime, openPONextToken, currentPageNumber, openPONoOfRecordsPerPage, NEXTBTN, fromCount1, toCount1);
      } else {
        setOpenPOData(state => ({ ...state, openPOTableData: openPOTotalData[currentPageNumber], openPOPageNumber: currentPageNumber }))
        var toCount2 = openPOToCount + openPOTotalData[currentPageNumber].length;
        if (currentPageNumber == Object.keys(openPOTotalData).length && openPONextToken == null) {
          setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: true, openPOToCount: toCount2 } }))
        } else setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOToCount: toCount2 } }))
        if (openPOActiveSortOrder == ASCENDING || openPOActiveSortOrder == DECENDING) { openPOSplitSortData(openPOArray, fromCount1, toCount2) }
      }
      setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOFromCount: fromCount1, openPOPreviousBtn: false } }))
    }
  }

  // after click on Previous icon
  const onPre = (type, currentPage) => {
    if (type === CLOSEDPO) {
      var fromCount1 = closedPOFromCount - closedPONoOfRecordsPerPage;
      var toCount1 = closedPOFromCount - 1;
      var currentPageNumber = closedPOPageNumber - 1;

      if (fromCount1 == NUMBER_ONE) setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOPreviousBtn: true } }))
      setClosedPOData(state => ({
        ...state, closedPOTableData: closedPOTotalData[currentPageNumber], closedPOPageNumber: currentPageNumber,
        closedPOPaginationData: { ...state.closedPOPaginationData, closedPOFromCount: fromCount1, closedPOToCount: toCount1, closedPONextBtn: false },
      }))
      if (closedPOActiveSortOrder == ASCENDING || closedPOActiveSortOrder == DECENDING) { closedPOSplitSortData(closedPOArray, fromCount1, toCount1) }

    } else if (type === INVOICE) {
      var fromCount1 = invoicePOFromCount - invoicePONoOfRecordsPerPage;
      var toCount1 = invoicePOFromCount - 1;
      var currentPageNumber = invoicePOPageNumber - 1;

      if (fromCount1 == NUMBER_ONE) setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOPreviousBtn: true } }))
      setInvoicePOData(state => ({
        ...state, invoicePOTableData: invoicePOTotalData[currentPageNumber], invoicePOPageNumber: currentPageNumber,
        invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOFromCount: fromCount1, invoicePOToCount: toCount1, invoicePONextBtn: false },
      }))
      if (invoicePOActiveSortOrder == ASCENDING || invoicePOActiveSortOrder == DECENDING) { invoicePOSplitSortData(invoicePOArray, fromCount1, toCount1) }

    } else {
      var toCount1 = openPOFromCount - 1, currentPageNumber = openPOPageNumber - 1;
      var fromCount1 = toCount1 - openPOTotalData[currentPageNumber].length + 1;
      if (fromCount1 == NUMBER_ONE) setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOPreviousBtn: true } }))
      setOpenPOData(state => ({
        ...state, openPOTableData: openPOTotalData[currentPageNumber], openPOPageNumber: currentPageNumber,
        openPOPaginationData: { ...state.openPOPaginationData, openPOFromCount: fromCount1, openPOToCount: toCount1, openPONextBtn: false },
      }))
      if (openPOActiveSortOrder == ASCENDING || openPOActiveSortOrder == DECENDING) { openPOSplitSortData(openPOArray, fromCount1, toCount1) }
    }
  }

  // For validating End Date
  const disabledEndDates = (current) => {
    if (!current) return false;
    const startDate = moment(startDateTime);
    var endDate = new Date(startDateTime);
    // endDate.setDate(endDate.getDate() + 90);
    endDate.setMonth(endDate.getMonth() + 3);
    return current.valueOf() < startDate.valueOf() || current.valueOf() > endDate.valueOf();
  }

  // sorting functionality for closed pos
  const closedPOUpdateKeyValue = (sortActiveValue, sortOrderValue, closedPOArray, fromCount, toCount) => {
    if (sortActiveValue) { setClosedPOData(state => ({ ...state, closedPOActiveElement: sortActiveValue })) }
    if (sortOrderValue) { setClosedPOData(state => ({ ...state, closedPOActiveSortOrder: sortOrderValue })) }
    closedPOSortingFunction(sortActiveValue, sortOrderValue, closedPOArray, fromCount, toCount);
  }
  const closedPOSortingFunction = (sortActiveValue, sortOrderValue, closedPOArray, fromCount, toCount) => {
    if (sortOrderValue === DECENDING) {
      if (sortActiveValue === POID) closedPOArray.sort((x, y) => y.GSI2 - x.GSI2);
      if (sortActiveValue === SALESORDER) closedPOArray.sort((x, y) => y.SalesOrder - x.SalesOrder);
      if (sortActiveValue === CREATEDDATE) closedPOArray.sort((x, y) => new Date(y.GSI1SK) - new Date(x.GSI1SK));
    } else if (sortOrderValue === ASCENDING) {
      if (sortActiveValue === POID) closedPOArray.sort((x, y) => x.GSI2 - y.GSI2);
      if (sortActiveValue === SALESORDER) closedPOArray.sort((x, y) => x.SalesOrder - y.SalesOrder);
      if (sortActiveValue === CREATEDDATE) closedPOArray.sort((x, y) => new Date(x.GSI1SK) - new Date(y.GSI1SK));
    }
    closedPOSplitSortData(closedPOArray, fromCount, toCount)
  }
  const closedPOSplitSortData = (array, fromCount, toCount) => {
    var splitData = array.slice(fromCount - 1, toCount);
    setClosedPOData(state => ({ ...state, closedPOTableData: splitData }));
  }

  // sorting functionality for invoice
  const invoicePOUpdateKeyValue = (sortActiveValue, sortOrderValue, invoicePOArray, fromCount, toCount) => {
    if (sortActiveValue) { setInvoicePOData(state => ({ ...state, invoicePOActiveElement: sortActiveValue })) }
    if (sortOrderValue) { setInvoicePOData(state => ({ ...state, invoicePOActiveSortOrder: sortOrderValue })) }
    invoicePOSortingFunction(sortActiveValue, sortOrderValue, invoicePOArray, fromCount, toCount);
  }
  const invoicePOSortingFunction = (sortActiveValue, sortOrderValue, invoicePOArray, fromCount, toCount) => {
    if (sortOrderValue === DECENDING) {
      if (sortActiveValue === POID) invoicePOArray.sort((x, y) => y.GSI2 - x.GSI2);
      if (sortActiveValue === INID) invoicePOArray.sort((x, y) => y.GSI2 - x.GSI2);
      if (sortActiveValue === CREATEDDATE) invoicePOArray.sort((x, y) => new Date(y.GSI1SK) - new Date(x.GSI1SK));
    } else if (sortOrderValue === ASCENDING) {
      if (sortActiveValue === POID) invoicePOArray.sort((x, y) => x.GSI2 - y.GSI2);
      if (sortActiveValue === INID) invoicePOArray.sort((x, y) => x.GSI2 - y.GSI2);
      if (sortActiveValue === CREATEDDATE) invoicePOArray.sort((x, y) => new Date(x.GSI1SK) - new Date(y.GSI1SK));
    }
    invoicePOSplitSortData(invoicePOArray, fromCount, toCount)
  }
  const invoicePOSplitSortData = (array, fromCount, toCount) => {
    var splitData = array.slice(fromCount - 1, toCount);
    setInvoicePOData(state => ({ ...state, invoicePOTableData: splitData }));
  }

  // sorting functionality for open pos
  const openPOUpdateKeyValue = (sortActiveValue, sortOrderValue, openPOArray, fromCount, toCount) => {
    if (sortActiveValue) { setOpenPOData(state => ({ ...state, openPOActiveElement: sortActiveValue })) }
    if (sortOrderValue) { setOpenPOData(state => ({ ...state, openPOActiveSortOrder: sortOrderValue })) }
    openPOSortingFunction(sortActiveValue, sortOrderValue, openPOArray, fromCount, toCount);
  }
  const openPOSortingFunction = async (sortActiveValue, sortOrderValue, openPOArray, fromCount, toCount) => {
    if (sortOrderValue === DECENDING) {
      if (sortActiveValue === POID) openPOArray.sort((x, y) => y.item.GSI2 - x.item.GSI2);
      if (sortActiveValue === CREATEDDATE) openPOArray.sort((x, y) => new Date(y.item.GSI1SK) - new Date(x.item.GSI1SK));
    } else if (sortOrderValue === ASCENDING) {
      if (sortActiveValue === POID) openPOArray.sort((x, y) => x.item.GSI2 - y.item.GSI2);
      if (sortActiveValue === CREATEDDATE) openPOArray.sort((x, y) => new Date(x.item.GSI1SK) - new Date(y.item.GSI1SK));
    }
    openPOSplitSortData(openPOArray, fromCount, toCount);
  }
  const openPOSplitSortData = async (array, fromCount, toCount) => {
    var splitData = array.slice(fromCount - 1, toCount);
    setOpenPOData(state => ({ ...state, openPOTableData: splitData }));
  }

  const disabledPreviousDates = (current) => { }
  const onModalSubmit = () => { }

  // Enable ASN modal
  const openModal = () => { setModalVisible(true); }
  const nameChange = (e) => { setCompanyName(e); }

  return (!POSelected? <>
    <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
      <Row>
        <Col span = '24'>
            <div className='card-container'>
            <Tabs activeKey={tabKey} onTabClick={onTabChange} type="card" className="tabsMain">
                <TabPane tab={POs} key={1+1}>
                <div className="bgWhite paddingtopbotom borderRadius5rem">
                <Row className="">
                <Col md={{ span: '15' }} sm={{ span: '5' }} xs={{ span: '24' }} className="tableTitle paddingTop10Bottom20">
                        <h2 className="margin0">{"List of POs"}</h2>
                </Col>
                <Col lgmd={{ span: '9' }} sm={{ span: '9' }} xs={{ span: '24' }} className="create-user ">
                    <div className="displayFlex justifyContentFlexEnd">
                        <CustomSearchBar placeholder="Search here" className={"width200"} value={searchValue}
                            inputClassname="Search-main" onChange={(e) => { handleValueChange('searchValue', e) }} onSearch={e => onSearch(searchValue, searchNextToken)} />
                    </div>
                </Col>
                </Row>
                <Row>
                    <Col span='24'>
                    <>{!loading ? <>
                        <Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={true} scroll={{ x: 500 }} />
                            {openPOTableData && openPOTableData.length ? <CustomPaginationWithPages onPre={() => onPre(OPENPO, openPOPageNumber)} onNext={() => onNext(OPENPO, openPOPageNumber)}
                              from={openPOFromCount} to={openPOToCount} nextBtn={openPONextBtn} previousBtn={openPOPreviousBtn}
                              noOfRecordsPerPageChanged={(e) => openPONoOfRecordsPerPageChanged(e)} noOfRecordsPerPage={openPONoOfRecordsPerPage} showItems={openPOShowItems} dataLength={openPOToCount} /> : null}
                          </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>} </>
                    </Col>
                </Row>
                </div>
                </TabPane>
            </Tabs>
            </div>
        </Col>
      </Row>
    </Dashboard>);
    </> : <>
    {!loading ? <>
    <BBOrdersActivity backToPOTable = {backToPOTable} PODetails = {PODetails} />
    </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>
    }
    </>
  )
};

export default BBOrdersDashboard;
