import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Row, Col, Spin, message } from 'antd';
import Dashboard from '../dashboard';
import '../orders/index.scss';
import {
  ADMIN, CUSTOMER_ADMIN, CUST_ADMIN_KEY, ERROR_MAIL_RECIPIENTS, ERROR_MAIL_RECIPIENTS_KEY, SALES_PERSONS,
  SALES_PERSON_KEY, SO_MAIL_RECIPIENTS, SO_MAIL_RECIPIENTS_KEY, SUB_ADMIN_KEY, SUPER_ADMIN_KEY
} from '../../utils/constants';
import CustomButton from '../../utils/components/buttons';
import CustomerAdminTable from '../../utils/components/tables/usersTables/customerAdminTable';
import { ERROR_MAIL_USERS_CREATE, SO_MAIL_USERS_CREATE, USERS_CREATE,USERS_CREATE_SO_MAIL } from '../../utils/routingUrl';
import { API, graphqlOperation } from 'aws-amplify';
import { queryUsers , queryUsersSOMail} from '../../graphql/queries';
import DeleteModal from '../../utils/components/modal/deleteModal';
import CustomPaginationWithPages from '../../utils/components/pagination/index1';
import { deleteUser } from '../../graphql/mutations';


const { TabPane } = Tabs;
const NUMBER_ONE = 1;
const NUMBER_TEN = 10;


const UsersDashboard = (props) => {
  let conditionalTab= SALES_PERSON_KEY;
  const history = useHistory();
  const [companyName, setCompanyName] = useState(sessionStorage.getItem('companySK'));
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [nextToken, setNextToken] = useState(null);
  const [modalVisible, setModalVisible] = useState({ visibility: false });
  const inititalPaginationState = {
    noOfRecordsPerPage: NUMBER_TEN,
    nextBtn: true,
    previousBtn: true,
    fromCount: NUMBER_ONE,
    toCount: NUMBER_TEN,
    showItems: []
  }

  useEffect(() => { getUsersData(); }, [companyName]);

  const getUsersData = () => {
    if (userDetails) {
      const { GSI1PK } = userDetails;
      var salesData = { key: SALES_PERSON_KEY, name: SALES_PERSONS, data: [], pagination: inititalPaginationState, tableData: [] },
        subAdminData = { key: SUB_ADMIN_KEY, name: ADMIN, data: [], pagination: inititalPaginationState, tableData: [] },
        custAdminData = { key: CUST_ADMIN_KEY, name: CUSTOMER_ADMIN, data: [], pagination: inititalPaginationState, tableData: [] },
        SOMailRecipientsData = { key: SO_MAIL_RECIPIENTS_KEY, name: SO_MAIL_RECIPIENTS, data: [], pagination: inititalPaginationState, tableData: [] },
        errorMailRecipientsData = { key: ERROR_MAIL_RECIPIENTS_KEY, name: ERROR_MAIL_RECIPIENTS, data: [], pagination: inititalPaginationState, tableData: [] },
        userData1 = [];

      if (GSI1PK == SUB_ADMIN_KEY) { userData1.push(salesData, SOMailRecipientsData, errorMailRecipientsData) }
      else if (GSI1PK == CUST_ADMIN_KEY) { userData1.push(subAdminData, salesData, SOMailRecipientsData, errorMailRecipientsData) }
      else if (GSI1PK == SUPER_ADMIN_KEY) { userData1.push(custAdminData, subAdminData, salesData) }
      setUserData(userData1);
      setActiveTab(userData1[0].key);
      getUserData(userData1, userData1[0].key, companyName);
    }
  }

  useEffect(() => { if (nextToken) { getUserData(userData, activeTab, companyName, nextToken); } }, [nextToken]);
 
  const getUserData = async (userData, GSI1PK, itemIds, nextToken, from) => {
    try {
      setLoading(true);
      var apiData, data, totalData, noOfRecordsPerPage;
      // if (GSI1PK === SO_MAIL_RECIPIENTS_KEY) {
      //   apiData = await API.graphql(graphqlOperation(listEDISOCreationRecipients));
      //   data = apiData.data.listEDISOCreationRecipients;
      // } else if (GSI1PK === ERROR_MAIL_RECIPIENTS_KEY) {
      //   apiData = await API.graphql({ query: listEDIErrorRecipients, variables: { limit: 500, after: nextToken } });
      //   data = apiData.data.listEDIErrorRecipients;
      // } else {
 
      
       //For SO success mail recipients   
      if(conditionalTab== SO_MAIL_RECIPIENTS_KEY){
          apiData= await API.graphql({ query: queryUsersSOMail, variables: { PK : SO_MAIL_RECIPIENTS_KEY } });
            data = apiData.data.queryUsersSOMail;
      }
      //Sales persons and Error Mail recipients
     else{
        apiData= await API.graphql({ query:queryUsers, variables: { GSI1PK, itemIds, first: 10, after: nextToken } });
        data = apiData.data.queryUsers;
     }

      var mainData = [];
      userData && userData.map((item, i) => {
        if (item.key === GSI1PK) {
          if (from === 'update') { totalData = data.items }
          else { totalData = [...item.data, ...data.items]; }
          item.data = totalData;
          noOfRecordsPerPage = item.pagination.noOfRecordsPerPage;
        }
      });
      mainData = userData;
      setUserData(mainData);

      if (data.nextToken == null) {
        getShowItems(totalData, GSI1PK);
        getTableData(totalData, GSI1PK, 0, noOfRecordsPerPage, mainData);
      }

      setNextToken(data.nextToken);
      
    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
  }

  const getShowItems = (data, key) => {
    if (data && data.length) {
      var array = [10, 20, 50, 100], optionArray = [];

      userData && userData.map(item => {
        if (item.key === key) {
          array.map(arrItem => {
            if (data.length > arrItem) {
              optionArray.push(arrItem);
              item.pagination.nextBtn = false;
            }
          })
          item.pagination.showItems = optionArray;
        }
      })

    }
  }

  const getTableData = (data, key, fromRecord, toRecord, userData) => {
    let slicedData = data.slice(fromRecord, toRecord);
    const elementsIndex = userData.findIndex(item => item.key === key);
    let newArray = [...userData];
    newArray[elementsIndex] = { ...newArray[elementsIndex], tableData: slicedData }
    setUserData(newArray);
  }

  const noOfRecordsPerPageChanged = (key, number) => {
    const elementsIndex = userData.findIndex(item => item.key === key);
    let newArray = [...userData]
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      pagination: { ...newArray[elementsIndex].pagination, noOfRecordsPerPage: number, toCount: number, fromCount: NUMBER_ONE, previousBtn: true, nextBtn: false }
    }
    setUserData(newArray);
    getTableData(newArray[elementsIndex].data, key, 0, number, newArray)
  }

  // to fetch next Data
  const onNext = (key) => {
    userData && userData.map(item => {
      if (item.key == key) {
        var fromCount1 = item.pagination.toCount + NUMBER_ONE;
        var toCount1 = item.pagination.toCount + item.pagination.noOfRecordsPerPage;

        if (item.data.length > toCount1) {
          getTableData(item.data, key, item.pagination.toCount, toCount1, userData);
          item.pagination.fromCount = fromCount1;
          item.pagination.toCount = toCount1;
          item.pagination.previousBtn = false;
        } else if (item.data.length >= fromCount1 && item.data.length <= toCount1) {
          getTableData(item.data, key, item.pagination.toCount, toCount1, userData);
          item.pagination.fromCount = fromCount1;
          item.pagination.toCount = toCount1;
          item.pagination.previousBtn = false;
          item.pagination.nextBtn = true;
        }
      }
    })
  }

  // after click on Previous icon
  const onPre = (key) => {
    userData && userData.map(item => {
      if (item.key === key) {
        var fromCount1 = item.pagination.fromCount - item.pagination.noOfRecordsPerPage;
        var toCount1 = item.pagination.fromCount - 1;

        if (fromCount1 != NUMBER_ONE) {
          item.pagination.fromCount = fromCount1;
          item.pagination.toCount = toCount1;
          item.pagination.nextBtn = false;
          getTableData(item.data, key, fromCount1 - 1, toCount1, userData)
        } else if (fromCount1 == NUMBER_ONE) {
          item.pagination.fromCount = fromCount1;
          item.pagination.toCount = toCount1;
          item.pagination.nextBtn = false;
          item.pagination.previousBtn = true;
          getTableData(item.data, key, fromCount1 - 1, toCount1, userData)
        }
      }
    })
  }


  const onTabChange = (e) => {
    setActiveTab(e);
    conditionalTab= e;
    userData && userData.map(item => {
      if (item.key === e && item.data.length === 0) { getUserData(userData, e, companyName, null); }
    })
  }
  const toCreateUser = (data) => { history.push(data) }


  const toCreateSOMailUser = () => {
    if (activeTab === SO_MAIL_RECIPIENTS_KEY) history.push(SO_MAIL_USERS_CREATE)
    else if (activeTab === ERROR_MAIL_RECIPIENTS_KEY) history.push(ERROR_MAIL_USERS_CREATE)
  }

  const handleOK = async () => {
    var apiData, data;
    const { PK, SK, GSI1PK, id, name } = modalVisible;
    // if (activeTab === SO_MAIL_RECIPIENTS_KEY) {
    //   apiData = await API.graphql(graphqlOperation(deleteEDISOCreationRecipients, { input: { id } }));
    //   data = apiData.data.deleteEDISOCreationRecipients;
    //   if (data) message.success(`${name} User Deleted Successfully!`);
    //   getUserData(userData, SO_MAIL_RECIPIENTS_KEY, companyName, null, 'update');
    // } else if (activeTab === ERROR_MAIL_RECIPIENTS_KEY) {
    //   apiData = await API.graphql(graphqlOperation(deleteEDIErrorRecipients, { input: { id } }));
    //   data = apiData.data.deleteEDIErrorRecipients;
    //   if (data) message.success(`${name} User Deleted Successfully!`);
    //   getUserData(userData, ERROR_MAIL_RECIPIENTS_KEY, companyName, null, 'update');
    // } else {
    apiData = await API.graphql(graphqlOperation(deleteUser, { PK, SK }));
    data = apiData.data.deleteUser;
    if (data && data.PK) message.success(`${data.PK} User Deleted Successfully!`);
    getUserData(userData, GSI1PK, companyName, null, 'update');
    // }
    setModalVisible(modalVisible => ({ ...modalVisible, visibility: false }))
  }
  const handleCancel = () => { setModalVisible(modalVisible => ({ ...modalVisible, visibility: false })) }
  const deleteUserAccount = async (item) => {
    let obj = { ...item, visibility: true };
    setModalVisible(obj)
  }
  const actionToggle = async (hrObj) => {
    let newObj = { ...hrObj };
    if (newObj.isActive === 'true') newObj.isActive = "false";
    else newObj.isActive = "true";
    delete newObj.createdAt;
    delete newObj.updatedAt;
    try {
      // if (activeTab === SO_MAIL_RECIPIENTS_KEY) {
      //   let addResult = await API.graphql(graphqlOperation(updateEDISOCreationRecipients, { input: newObj }));
      //   if (addResult.data.updateEDISOCreationRecipients) {
      //     if (!addResult.data.updateEDISOCreationRecipients.isActive) message.success("Deactivated Successfully");
      //     else message.success("Activated Successfully");
      //     getUserData(userData, SO_MAIL_RECIPIENTS_KEY, companyName, null, 'update');
      //   }
      // } else if (activeTab === ERROR_MAIL_RECIPIENTS_KEY) {
      //   let addResult = await API.graphql(graphqlOperation(updateEDIErrorRecipients, { input: newObj }));
      //   if (addResult.data.updateEDIErrorRecipients) {
      //     if (!addResult.data.updateEDIErrorRecipients.isActive) message.success("Deactivated Successfully");
      //     else message.success("Activated Successfully");
      //     getUserData(userData, SO_MAIL_RECIPIENTS_KEY, companyName, null, 'update');
      //   }
      // }
    } catch (error) { }
  };

  const nameChange = (e) => { setCompanyName(e); }

  return (
    <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
      <Row>
        <Col lg={{ span: '24' }}>
          <div className="card-container">
           
            <Tabs activeKey={activeTab} onTabClick={async (e)=>{  
            onTabChange(e)}} type="card" className="tabsMain">
              {userData && userData.map((item, i) =>
                <TabPane tab={item.name} key={item.key}>
                  {/* {item.key === SO_MAIL_RECIPIENTS_KEY || item.key === ERROR_MAIL_RECIPIENTS_KEY ?
                    <div className="bgWhite paddingtopbotom borderRadius5rem">
                      <Row className="displayFlex justifyContentCenter alignItemsCenter">
                        <Col md={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="tableTitle paddingTop10Bottom20">
                          <h2 className="margin0">{item.name + " List"}</h2>
                        </Col>
                        <Col md={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="create-user paddingTop10Bottom20">
                          <CustomButton buttonLabel="Create User" className="createUserBtn" handleSubmit={toCreateSOMailUser} />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={{ span: '24' }}>
                          <>{!loading ? <>
                            <SOMailRecipientsTable data={item.tableData} deleteUserAccount={deleteUserAccount} actionToggle={actionToggle} />
                            {item.tableData.length ?
                              <CustomPaginationWithPages onPre={() => onPre(item.key)} onNext={() => onNext(item.key)} noOfRecordsPerPageChanged={(e) => noOfRecordsPerPageChanged(item.key, e)}
                                from={item.pagination.fromCount} to={item.pagination.toCount} nextBtn={item.pagination.nextBtn} previousBtn={item.pagination.previousBtn}
                                noOfRecordsPerPage={item.pagination.noOfRecordsPerPage} showItems={item.pagination.showItems} dataLength={item.data.length} /> : null}
                          </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>} </>
                        </Col>
                      </Row>
                    </div> : */}
                  <div className="bgWhite paddingtopbotom borderRadius5rem">
                    <Row className="displayFlex justifyContentCenter alignItemsCenter">
                      <Col md={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="tableTitle paddingTop10Bottom20">
                        <h2 className="margin0">{item.name + " List"}</h2>
                      </Col>
                      {/* Create user button */}
                      <Col md={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="create-user paddingTop10Bottom20">
                      {
                        activeTab == SO_MAIL_RECIPIENTS_KEY ? 
                              <CustomButton buttonLabel="Create User" disabled style={{display: "none"}} className="createUserBtn" handleSubmit={()=> toCreateUser(USERS_CREATE_SO_MAIL)}  />
                      :       <CustomButton buttonLabel="Create User" className="createUserBtn" handleSubmit={()=> toCreateUser(USERS_CREATE)} />                      }
                        {/* <CustomButton buttonLabel="Create User" className="createUserBtn" handleSubmit={toCreateUser} /> */}
                    
                      </Col>
                    </Row>

                    <Row>
                      <Col span='24'>
                        <>{!loading ? <>
                          <CustomerAdminTable data={item.tableData} deleteUserAccount={deleteUserAccount} activeTab={activeTab}  />
                          {item.tableData.length ?
                            <CustomPaginationWithPages onPre={() => onPre(item.key)} onNext={() => onNext(item.key)} noOfRecordsPerPageChanged={(e) => noOfRecordsPerPageChanged(item.key, e)}
                              from={item.pagination.fromCount} to={item.pagination.toCount} nextBtn={item.pagination.nextBtn} previousBtn={item.pagination.previousBtn}
                              noOfRecordsPerPage={item.pagination.noOfRecordsPerPage} showItems={item.pagination.showItems} dataLength={item.data.length} /> : null}
                        </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>} </>
                      </Col>
                    </Row>
                  </div>
                  {/* } */}

                </TabPane>)}
            </Tabs>
          </div>
        </Col>
      </Row>
      <DeleteModal message={`Do you want to delete the user`} handleCancel={handleCancel} handleOK={handleOK} modalVisible={modalVisible.visibility} />
    </Dashboard>
  );
}

export default UsersDashboard;
