import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, message } from 'antd';
import { useHistory } from 'react-router-dom';
import Dashboard from '../../../dashboard';
import CustomeButton from '../../../../utils/components/buttons'
import DropDownSelect from '../../../../utils/components/customeInputFields/dropDownSelect';
import { getCompanyNamesList, getUserTypeByCustAdmin, getUserTypeBySubAdmin, getUserTypeBySuperAdmin } from '../../../../utils/functions';
import CustomInput from '../../../../utils/components/customeInputFields/input';
import './index.scss';
import moment from 'moment';
import CustomCheckBox from '../../../../utils/components/customeInputFields/CustomChecknox';
import { addUser, sendEmail, updateUser } from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { USERS_DASHBOARD, USERS_EDIT } from '../../../../utils/routingUrl';
import { CUSTOMER_ADMIN, CUST_ADMIN_KEY, DATE_PICKER_TIME_ZONE_Z, ERROR_MAIL_RECIPIENTS_KEY, HASH_KEY, SALES_PERSONS, SALES_PERSON_KEY, SO_MAIL_RECIPIENTS_KEY, STAGING_URL, SUB_ADMIN, SUB_ADMIN_KEY } from '../../../../utils/constants';
import { queryAzureADUser } from '../../../../graphql/queries';
import AutoCompleteSection from '../../../../utils/components/autoCompleteSelection';

const CreateUser = (props) => {
    const location = useLocation();
    const history = useHistory();
    const companiesList = JSON.parse(sessionStorage.getItem('companiesList'));
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));

    const [state, setState] = useState({
        CompanyName: '',
        IsActive: false,
        PK: '',
        SK: '',
        GSI1PK: '',
        errors: {},
        userType: ''
    });
    const [allCompanies, setAllCompanies] = useState([]);
    const [emailList, setEmailList] = useState([]);

    const { CompanyName, IsActive, PK, SK, GSI1PK, userType, errors } = state;

    useEffect(() => {
        const data = location.state;
        if (data != undefined) {
            const { IsActive, PK, SK, CompanyName, GSI1PK } = data.data;
            setState(state => ({ ...state, IsActive, PK, SK, CompanyName, GSI1PK }))
        }
        if (companiesList) { setAllCompanies(companiesList ? companiesList : ''); }
    }, []);

    const handleValueChange = (info, e) => {
        if (e !== "") errors[info] = "";
        if (info === 'CompanyName') {
            allCompanies.map(item => {
                if (e === item.CompanyName) { setState(state => ({ ...state, SK: item.SK, [info]: e, userType: userDetails.GSI1PK })) }
            })
        } else setState(state => ({ ...state, [info]: e }))
    }

    const onCheck = (e) => { setState(state => ({ ...state, IsActive: e.target.checked })) }

    const handleSubmit = async () => {
        let errors = {};
        if (CompanyName === "" || CompanyName === undefined) errors.CompanyName = "Please select a customer name";
        if (PK === "" || PK === undefined) errors.PK = "Please select an email ID";
        if (SK === "" || SK === undefined) errors.SK = "Please select a customer payer ID";
        if (GSI1PK === "" || GSI1PK === undefined) errors.GSI1PK = "Please select a user type";
        setState(state => ({ ...state, errors }));
        if (Object.keys(errors).length === 0) {
            var body = {};
            if (GSI1PK === ERROR_MAIL_RECIPIENTS_KEY || GSI1PK === SO_MAIL_RECIPIENTS_KEY) {
                body = { CompanyName, PK, SK: SK + HASH_KEY + GSI1PK, GSI1PK, IsActive, Type: "Notification", GSI1SK: moment.utc(new Date()).format(DATE_PICKER_TIME_ZONE_Z), GSI2: GSI1PK + '#' + SK }
            } else {
                body = { CompanyName, PK, SK: SK, GSI1PK, IsActive, Type: "User", GSI1SK: moment.utc(new Date()).format(DATE_PICKER_TIME_ZONE_Z), GSI2: GSI1PK + '#' + SK }
            }
            try {
                if (window.location.pathname === USERS_EDIT) {
                    const apiData = await API.graphql({ query: updateUser, variables: body });
                    message.success('User updated successfully!');
                    history.push(USERS_DASHBOARD);
                } else {
                    const newTodo = await API.graphql(graphqlOperation(addUser, body));
                    message.success('User created successfully!');
                    if (GSI1PK != ERROR_MAIL_RECIPIENTS_KEY && GSI1PK != SO_MAIL_RECIPIENTS_KEY) handleEmailSent();
                    else history.push(USERS_DASHBOARD);
                }
            } catch (error) {
                if (error.errors[0].errorType.includes("ConditionalCheckFailedException")) {
                    message.error('Already existed user data');
                } else message.error('Invalid Data');
            }
        }
    }

    const handleEmailSent = async () => {
        var UserType = GSI1PK === CUST_ADMIN_KEY ? CUSTOMER_ADMIN : GSI1PK === SUB_ADMIN_KEY ? SUB_ADMIN : GSI1PK === SALES_PERSON_KEY ? SALES_PERSONS : '';
        var body = {
            From: 'noreply@tataconsumer.com',
            ToList: [PK],
            CompanyCode: CompanyName,
            UserType: UserType,
            URL: process.env.REACT_APP_REDIRECT_URL || STAGING_URL
        }
        try {
            const newTodo = await API.graphql(graphqlOperation(sendEmail, body));
            message.success('Sent the mail successfully!');
            history.push(USERS_DASHBOARD);
        } catch (error) { message.error('Invalid Data'); }
    }

    const companyNameOptions = useMemo(() => getCompanyNamesList(allCompanies), [allCompanies])

    const handleCancel = () => { history.push(USERS_DASHBOARD) }

    const handleSearch = async (value) => {
        let res = [];
        if (!value || value.length < 3 || value.indexOf('@') >= 0) {
            res = [];
            setState(state => ({ ...state, PK: '' }))
        } else {
            const apiData = await API.graphql({ query: queryAzureADUser, variables: { Email: value } });
            res = apiData.data.queryAzureADUser.body ? apiData.data.queryAzureADUser.body : [];
        }
        setEmailList(res);
    };

    const nameChange = (e) => { }
    return (
        <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                     {/* Create user model */}
                    <h2 className="heading18Bold margin0">Create User</h2>
                    <div className="CreateUserForm">
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel beforeLabelStar">Customer Name</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <DropDownSelect placeholder='Customer Name' className="beforeStar" value={CompanyName || undefined} name="CompanyName"
                                    onChange={(e) => { handleValueChange("CompanyName", e) }} selectStyles="selectStyles" options={companyNameOptions}
                                    errorText={errors.CompanyName} disabled={window.location.pathname === USERS_EDIT} errorMsg="true" />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel beforeLabelStar">Customer Payer ID</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={SK || undefined} placeholder='Customer Payer ID' name="SK"
                                    onChange={(e) => { handleValueChange("SK", e) }} inputClassname="inputBoxField" disabled />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel beforeLabelStar">User Type</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <DropDownSelect className="beforeStar" value={GSI1PK || undefined} placeholder='User Type' name="GSI1PK" errorText={errors.GSI1PK} errorMsg="true"
                                    onChange={(e) => { handleValueChange("GSI1PK", e) }} selectStyles="selectStyles"
                                    options={userType === SUB_ADMIN_KEY ? getUserTypeBySubAdmin : userType === CUST_ADMIN_KEY ? getUserTypeByCustAdmin : getUserTypeBySuperAdmin} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel beforeLabelStar">E-Mail ID</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <AutoCompleteSection options={emailList} onSearch={handleSearch} placeholder="Email"
                                    value={PK || undefined} onChange={(e) => { handleValueChange("PK", e) }} autoCompleteStyles="autoCompleteStyles"
                                    errorMsg="true" errorText={errors.PK} disabled={window.location.pathname === USERS_EDIT} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '6' }}>
                                <p className="customeSideLabel">Active</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '18' }}>
                                <CustomCheckBox onChange={onCheck} checked={IsActive} className="checkBoxStyles" />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}> </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }} className="displaySpacebetween ">
                                <CustomeButton buttonLabel="Cancel" className="cancelBtn" handleSubmit={handleCancel} />
                                <CustomeButton buttonLabel={window.location.pathname === USERS_EDIT ? 'Update' : "Submit"} className="submitBtn" handleSubmit={handleSubmit} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Dashboard>
    )
}
export default CreateUser;