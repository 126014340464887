import React, { useEffect, useState } from 'react';
import { Row, Col, message, Spin, Modal } from 'antd';
import Dashboard from '../dashboard';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import SwitchDesign from '../../utils/components/switch';
import { updateCompanies, uploadMasterData } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { listCompanies } from '../../graphql/queries';
import DeleteModal from '../../utils/components/modal/deleteModal';
import { TRUE_CONDITION } from '../../utils/constants';
import CustomSearchBar from '../../utils/components/customeInputFields/searchField';
import CustomPaginationWithPages from '../../utils/components/pagination/index1';
import CustomButton from '../../utils/components/buttons';
import { COMAPNIES_CREATE } from '../../utils/routingUrl';
import UploadFile from '../../utils/components/MediaUpload/uploadFie';
const COMPANY = 'Company';
const NUMBER_ONE = 1;
const NUMBER_TEN = 10;

const CompaniesDashboard = (props) => {
  const history = useHistory();
  const inititalPaginationState = {
    noOfRecordsPerPage: NUMBER_TEN,
    nextBtn: true,
    previousBtn: true,
    fromCount: NUMBER_ONE,
    toCount: NUMBER_TEN,
    showItems: []
  }
  const [loading, setLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [nextToken, setNextToken] = useState(null)
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [state, setState] = useState({ modalVisible: false, updatedObj: {} })
  const { modalVisible, updatedObj } = state;
  const [uploadData, setUploadData] = useState({
    uploadVisible: false,
    data: [], 
    customerId: '',
    isModalVisible: false,
    fileName: ''
  })
  const { uploadVisible, data, customerId, isModalVisible, fileName } = uploadData;

  // pagination initial state
  const [paginationData, setPaginationData] = useState(inititalPaginationState);
  const { noOfRecordsPerPage, nextBtn, previousBtn, fromCount, toCount, showItems } = paginationData;

  useEffect(() => { getCompaniesList(); }, []);
  useEffect(() => { if (nextToken !== null) getCompaniesList(nextToken); }, [nextToken]);

  const getCompaniesList = async (nextToken, from) => {
    try {
      setLoading(true);
      const apiData = await API.graphql({ query: listCompanies, variables: { PK: COMPANY, first: 50, after: nextToken } });
      var data = apiData.data.listCompanies;
      var totalData;
      if (from === 'sessionUpdated') sessionStorage.setItem('companiesList', JSON.stringify(data.items));
      else {
        if (from === 'updated') {
          totalData = data.items;
          setAllCompanies(totalData)
        } else {
          totalData = [...allCompanies, ...data.items];
          setAllCompanies(totalData)
        }
        if (data.nextToken == null) {
          getShowItems(totalData);
          getTableData(totalData, 0, noOfRecordsPerPage);
          sessionStorage.setItem('companiesList', JSON.stringify(totalData));
        }
        setNextToken(data.nextToken)
      }
    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
  }

  const getShowItems = (totalData) => {
    if (totalData && totalData.length) {
      var array = [10, 20, 50, 100], optionArray = [];
      array.map(item => {
        if (totalData.length > item) {
          optionArray.push(item);
          setPaginationData(state => ({ ...state, nextBtn: false }))
        }
      })
      setPaginationData(state => ({ ...state, showItems: optionArray }))
    }
  }

  const getTableData = (data, fromRecord, toRecord) => {
    let slicedData = data.slice(fromRecord, toRecord);
    setTableData(slicedData);
  }

  const noOfRecordsPerPageChanged = (number) => {
    setPaginationData(state => ({ ...state, noOfRecordsPerPage: number, toCount: number, fromCount: NUMBER_ONE, previousBtn: true, nextBtn: false }))
    getTableData(allCompanies, 0, number)
  }

  // to fetch next Data
  const onNext = () => {
    var fromCount1 = toCount + NUMBER_ONE;
    var toCount1 = toCount + noOfRecordsPerPage;
    if (allCompanies.length > toCount1) {
      setPaginationData(state => ({ ...state, fromCount: fromCount1, toCount: toCount1, previousBtn: false }))
      getTableData(allCompanies, toCount, toCount1);
    } else if (allCompanies.length > fromCount1 && allCompanies.length <= toCount1) {
      setPaginationData(state => ({ ...state, fromCount: fromCount1, toCount: toCount1, previousBtn: false, nextBtn: true }))
      getTableData(allCompanies, toCount, toCount1);
    }
  }

  // after click on Previous icon
  const onPre = () => {
    var fromCount1 = fromCount - noOfRecordsPerPage;
    var toCount1 = fromCount - 1;
    if (fromCount1 != NUMBER_ONE) {
      setPaginationData(state => ({ ...state, fromCount: fromCount1, toCount: toCount1, nextBtn: false }))
      getTableData(allCompanies, fromCount1 - 1, toCount1)
    } else if (fromCount1 == NUMBER_ONE) {
      setPaginationData(state => ({ ...state, fromCount: fromCount1, toCount: toCount1, nextBtn: false, previousBtn: true }))
      getTableData(allCompanies, fromCount1 - 1, toCount1)
    }
  }

  const handleChange = (SK, e) => {
    let newArr = [...allCompanies];
    newArr.length && newArr.map((item, i) => {
      if (item.SK === SK) {
        newArr[i]['IsActive'] = newArr[i]['IsActive'] == TRUE_CONDITION || newArr[i]['IsActive'] ? false : true;
        setState({ ...state, modalVisible: true, updatedObj: newArr[i] })
      }
    })
    // setAllCompanies(newArr)
  }

  const handleCancel = () => {
    setState({ ...state, modalVisible: false })
    getCompaniesList(null, 'updated');
  }
  const handleUploadCancel = () => { setUploadData({ ...state, uploadVisible: false, data: [], fileName: '' }) }
  const handleOK = async () => {
    const { SK, IsActive } = updatedObj;
    try {
      var body = {
        "PK": "Company",
        "Type": "Company",
        "itemIds": [{
          "SK": SK,
          "IsActive": IsActive
        }]
      };
      var data = await API.graphql(graphqlOperation(updateCompanies, body));
      message.success('Customer status updated successfully!')
      getCompaniesList(null, 'sessionUpdated');
      setState({ ...state, modalVisible: false })
    } catch (error) { message.error('Invalid Data') }
  }

  const handleValueChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value == "") { setAllCompanies([]); getCompaniesList(null, 'updated'); }
  }

  const handleUploadChange = (name, e, fileName) => {
    if (e.length === 0) setUploadData(state => ({ ...state, [name]: {} }))
    else setUploadData(state => ({ ...state, [name]: e, fileName }))
  }

  const handleSubmitOK = async () => {
    setUploadData(state => ({ ...state, isModalVisible: true }))
    try {
      if (data.length > 0) {
        let tempData = [...data], sendData = [], count = 1;
        let dataLength = Math.ceil(tempData.length / 100);
        if (tempData.length > 100) {
          while (count <= dataLength && tempData.length > 100) {
            sendData = tempData.slice(0, 99);
            let ApiData = await API.graphql({ query: uploadMasterData, variables: { customerId, Data: sendData } });
            if (ApiData?.data?.uploadMasterData?.statusCode == 200) {
              count = count + 1;
              tempData.splice(0, 100);
            }
          }
        }
        if (tempData.length < 100) {
          sendData = tempData;
          let ApiData = await API.graphql({ query: uploadMasterData, variables: { customerId, Data: sendData } });
          let response = ApiData.data.uploadMasterData;
          if (response.statusCode == 200) message.success('Data uploaded successfully!');
          else message.error(response.body);
        }
      }

      setUploadData(state => ({ ...state, uploadVisible: false, isModalVisible: false, data: [], fileName: '' }))
    } catch (error) { message.error('Invalid Data') }
  }
  // fetching Data while click on search
  const onSearch = async () => {
    var searchedValue = searchValue.toLowerCase();
    const filteredData = [];
    if (!searchValue.length) {
      setAllCompanies([])
    } else {
      allCompanies.forEach(item => {
        if (item.CompanyName && item.CompanyName.toLowerCase().includes(searchedValue)) { filteredData.push(item); }
      });
      getShowItems(filteredData);
      getTableData(filteredData, 0, noOfRecordsPerPage);
    }
  }

  const uploadFile = async (item) => { setUploadData(state => ({ ...state, uploadVisible: true, customerId: item.SK })) }

  const toCreateCustomer = () => { history.push(COMAPNIES_CREATE) }
  const nameChange = (e) => { }

  return (
    <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
      <Row>
        <Col lg={{ span: '20' }} className="bgWhite viewDetailsPage borderRadius5rem">
          <Row className="displayFlex justifyContentCenter alignItemsCenter paddingTop10Bottom10">
            <Col md={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="tableTitle">
              <h2 className="heading18Bold margin0"> Customers List </h2>
            </Col>
            <Col md={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="create-user">
              <div className="displayFlex justifyContentFlexEnd">
                <ul className="custom-list-inline">
                  <li>
                    <CustomSearchBar placeholder="Search by Name" className={"width200"} value={searchValue}
                      inputClassname="Search-main" onChange={(e) => { handleValueChange(e) }} onSearch={onSearch} />
                  </li>
                  {/* <li className="paddingLeft20">
                    <CustomButton buttonLabel="Create Customer" className="createUserBtn" handleSubmit={toCreateCustomer} />
                  </li> */}
                </ul>
              </div>
            </Col>
          </Row>

          <div className="companiesListStyles paddingTop10">
            <div className="displaySpacebetween companiesTitleStyles">
              <p className="margin0"> Customer Name </p>
              <p className="margin0 textAlignCenter"> Customer Payer ID </p>
              <p className="margin0 textAlignCenter">Activate /Deactivate </p>
            </div>

            <>{!loading ? <>
              {tableData.map((item, i) => <>
                <div className="displaySpacebetween companiesStyles" key={i}>
                  <p className="margin0 companyName" onClick={() => uploadFile(item)}> {item.CompanyName} </p>
                  <p className="margin0 textAlignCenter companyCode" onClick={() => uploadFile(item)}> {item.SK} </p>
                  <p className="margin0 switchBtn textAlignCenter">
                    <SwitchDesign onChange={() => handleChange(item.SK)} checked={item.IsActive == TRUE_CONDITION || item.IsActive ? true : false} className="switchStyles" />
                  </p>
                </div>
                <hr className='separator' />
              </>)}
              {tableData.length != 0 && searchValue == '' ?
                <CustomPaginationWithPages onPre={() => onPre()} onNext={() => onNext()} noOfRecordsPerPageChanged={(e) => noOfRecordsPerPageChanged(e)}
                  from={fromCount} to={toCount} nextBtn={nextBtn} previousBtn={previousBtn}
                  noOfRecordsPerPage={noOfRecordsPerPage} showItems={showItems} dataLength={searchValue ? tableData.length : allCompanies.length} /> : null}
            </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>} </>
          </div>
        </Col>
      </Row>
      <DeleteModal message="Do you want to change the status of this customer?" handleCancel={handleCancel} handleOK={handleOK} modalVisible={modalVisible} />

      <Modal visible={uploadVisible} onOk={handleSubmitOK} onCancel={handleUploadCancel} className='FilterModal' footer={null} width={'80vh'} centered>
        <h3 className='paddingBottom10'>Upload Master Data</h3>
        <UploadFile UploadedFile={(url, name) => { handleUploadChange('data', url, name) }} file={data} fileName={fileName} />

        <div className="buttonsInline paddingTop10">
          <CustomButton handleSubmit={handleUploadCancel} className="clearFilter" buttonLabel="Cancel" />
          <CustomButton handleSubmit={handleSubmitOK} className="filterButton" buttonLabel="Ok" />
        </div>
      </Modal>

      <Modal visible={isModalVisible} className="loadingModal">
        <div className="textAlignCenter">
          <h2 className="colorWhite">Please wait...</h2>
        </div>
      </Modal>
    </Dashboard>
  );
}

export default CompaniesDashboard;
