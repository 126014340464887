import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Table, Spin, message } from 'antd';
import Dashboard from '../../dashboard';
import './index.scss';
import { ORDERS_ACTIVITY, ORDERS_DASHBOARD } from '../../../utils/routingUrl';
import CustomNavigator from '../../../utils/components/backToOrders';
import { CheckOutlined, CloseOutlined, RedoOutlined } from '@ant-design/icons';
import SOCreationTable from '../../../utils/components/tables/SOCreations';
import MRPCheckEANLookUpTable from '../../../utils/components/tables/MRPCheck-EANLookUp';
import ValidationAkgTable from '../../../utils/components/tables/Validation-Acknowledgement';
import InvoiceTable from '../../../utils/components/tables/Invoice';
import { API } from 'aws-amplify';
import { queryCompletePOEvents } from '../../../graphql/queries';
import { sORetrigger } from '../../../graphql/mutations';
import {
    COMPONENTFROM_CLOSEDPOS, COMPONENTFROM_OPENINVOICE, COMPONENTFROM_OPENPOS, DATE_PICKER_YEAR_MONTH_DATE,
    SALES_PERSON_KEY, SEARCHBY_OPENINVOICE
} from '../../../utils/constants';
import moment from 'moment';
import CustomButton from '../../../utils/components/buttons';
import ASNSentTable from '../../../utils/components/tables/ANSSent';
import { getItems } from '../../../utils/functions';
import Modal from 'antd/lib/modal/Modal';

const columns = [{
    title: 'PO ID',
    dataIndex: 'poId',
    key: 'poId'
}, {
    title: 'PO Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate'
}, {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
}, {
    title: 'Sales Order',
    dataIndex: 'SalesOrder',
    key: 'SalesOrder'
}];

const VALIDATION = 'XML_VALIDATION';
const ACKNOWLEDGEMENT = 'INSTANT_ACK';
const MRPCHECK = 'MRP_CHECK';
const CASELOTCHECK = 'CASELOT_CHECK';
const EANLOOKUP = 'EAN_LOOKUP';
const SOCREATIONS = 'SO_CREATION';
const INVOICES = 'INVOICES';
const POSTAGE = 'POStage';
const MRPCHECK2 = 'MRP_CHECK2';
const ASNSENT = 'ASN';

const VALIDATION_TITLE = 'Validation';
const ACKNOWLEDGEMENT_TITLE = 'Acknowledgement';
const MRPCHECK_TITLE = 'MRP Check';
const MRPCHECK2_TITLE = 'MRP Check 2';
const CASELOTCHECK_TITLE = 'Caselot Check';
const EANLOOKUP_TITLE = 'Article Lookup';
const SOCREATIONS_TITLE = 'SO Creations';
const INVOICES_TITLE = 'Invoices';
const ASNSENT_TITLE = 'ASN Sent';

const FAILED = 'FAILED';
const SUCCESS = 'SUCCESS';
const FAILEDSUCCESS = 'FAILEDSUCCESS';


const POData = (props) => {
    const location = useLocation();
    const history = useHistory();
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const [POData, setPOData] = useState([]);
    const [SOPOData, setSOPOData] = useState([]);
    const [POEvents, setPOEvents] = useState([]);
    const [activeEvent, setActiveEvent] = useState('');
    const [activeEventData, setActiveEventData] = useState([]);
    const [activeEventTitle, setActiveEventTitle] = useState('');
    const [currentComponent, setCurrentComponent] = useState("1");
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState({});
    const [orderDetails, setOrderDetails] = useState({});
    const [startDateTime, setStartDate] = useState();
    const [endDateTime, setEndDate] = useState();
    const [retriggerBtnActiveState, setRetriggerBtnActiveState] = useState(false);
    const [state, setState] = useState({ PK: '', SK: '' });
    const [isModalVisible, setIsModalVisible] = useState(false)

    const { PK, SK } = state;

    useEffect(() => {
        const { PK, SK, componentFrom, componentDetails, componentOrderDetails, startDateTime, endDateTime } = location.state;
        getPOEvenets(PK, SK);
        setCurrentComponent(componentFrom);
        setDetails(componentDetails);
        setOrderDetails(componentOrderDetails);
        setStartDate(startDateTime);
        setEndDate(endDateTime);
        setState(state => ({ ...state, PK, SK }))
    }, [location.state])

    // fetch PO Data and PO Events
    async function getPOEvenets(PK, SK) {
        try {
            const ApiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK, SK, Type: POSTAGE } });
            var data = ApiData.data.queryCompletePOEvents.body, dataMapper = [], eventsMapper = [];
            var Validation = [], MRPCheck = [], CASELOTCheck = [], Acknowledgement = [], SOCreations = [], EANLookup = [], Invoices = [], MRPCheck2 = [], ASNSent = [];

            const { GSI2, GSI1SK, Status, SalesOrder } = data.POData;
            var obj = {
                key: '1',
                poId: <span className="fontWeight700">{GSI2}</span>,
                createdDate: <span className="fontWeight700">{moment(GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}</span>,
                status: <span className="fontWeight700 capitalizeAllCaps">{Status ? Status.toLowerCase() : '-'}</span>,
                SalesOrder: <span className="fontWeight700">{SalesOrder ? SalesOrder : '-'}</span>
            }
            dataMapper.push(obj);
            setPOData(dataMapper);
            setSOPOData(data.POData)

            data.POEvents && data.POEvents.length && data.POEvents.map(item => {
                if (item.SK.includes(VALIDATION)) Validation.unshift(item);
                else if (item.SK.includes(ACKNOWLEDGEMENT)) Acknowledgement.unshift(item);
                else if (item.SK.includes(EANLOOKUP)) EANLookup.unshift(item);
                else if (item.SK.includes(MRPCHECK2)) MRPCheck2.unshift(item);
                else if (item.SK.includes(MRPCHECK)) MRPCheck.unshift(item);
                else if (item.SK.includes(CASELOTCHECK)) CASELOTCheck.unshift(item);
                else if (item.SK.includes(SOCREATIONS)) SOCreations.unshift(item);
                else if (item.SK.includes(INVOICES)) Invoices.unshift(item);
                else if (item.SK.includes(ASNSENT)) ASNSent.unshift(item);
            })

            let Validation_Data = sortingData(Validation);
            let Acknowledgement_Data = sortingData(Acknowledgement);
            let EANLookup_Data = sortingData(EANLookup);
            let MRPCheck_Data = sortingData(MRPCheck);
            let CASELOTCheck_Data = sortingData(CASELOTCheck);
            let SOCreations_Data = sortingData(SOCreations);
            let Invoices_Data = sortingData(Invoices);
            let MRPCheck2_Data = sortingData(MRPCheck2);
            let ASNSent_Data = sortingData(ASNSent);

            if (Validation.length) {
                setActiveEventTitle(VALIDATION_TITLE);
                setActiveEventData(Validation)
                setActiveEvent(VALIDATION);
            }
            eventsMapper.push({ key: VALIDATION, title: VALIDATION_TITLE, data: Validation_Data, status: getStatus(Validation_Data) },
                { key: ACKNOWLEDGEMENT, title: ACKNOWLEDGEMENT_TITLE, data: Acknowledgement_Data, status: getStatus(Acknowledgement_Data) },
                { key: EANLOOKUP, title: EANLOOKUP_TITLE, data: EANLookup_Data, status: getStatus(EANLookup) },
                { key: MRPCHECK, title: MRPCHECK_TITLE, data: MRPCheck_Data, status: getStatus(MRPCheck_Data) },
                { key: CASELOTCHECK, title: CASELOTCHECK_TITLE, data: CASELOTCheck_Data, status: getStatus(CASELOTCheck_Data) },
                { key: SOCREATIONS, title: SOCREATIONS_TITLE, data: SOCreations_Data, status: getStatus(SOCreations_Data) },
                { key: INVOICES, title: INVOICES_TITLE, data: Invoices_Data, status: getStatus(Invoices_Data) },
                { key: MRPCHECK2, title: MRPCHECK2_TITLE, data: MRPCheck2_Data, status: getStatus(MRPCheck2_Data, Invoices_Data) },
                { key: ASNSENT, title: ASNSENT_TITLE, data: ASNSent_Data, status: getStatus(ASNSent_Data, Invoices_Data) });

            setPOEvents(eventsMapper);
            let count = 0;
            eventsMapper.map((item, i) => {
                if (i > 1 && i < 6) { if (item.status != undefined && item.status === FAILED) count++; }
                // if (item.key === EANLOOKUP || item.key === MRPCHECK || item.key === CASELOTCHECK || item.key === SOCREATIONS) {
                //     if (item.status != undefined && item.status === FAILED || item.status === FAILEDSUCCESS) count++;
                // }
            })
            if (count > 0) setRetriggerBtnActiveState(true)
            else setRetriggerBtnActiveState(false)
        } catch (error) { console.log('error occured while fetching the data ', error); }
        setLoading(false);
    }

    const sortingData = (array) => {
        const data = array.sort((a, b) => { return new Date(b.GSI1SK) - new Date(a.GSI1SK); });
        return data;
    }

    const reTriggerPOEvenets = async (PK, SK) => {
        setIsModalVisible(true)
        try {
            const ApiData = await API.graphql({ query: sORetrigger, variables: { PK: getItems(SK, 1) } });
            const { statusCode, body } = JSON.parse(ApiData.data.SORetrigger);
            if (statusCode === 400) message.error(body.ERROR)
            else message.success(`Retrigged successfully!`);
            getPOEvenets(PK, SK);
            setIsModalVisible(false)
        } catch (err) { message.error('Something went wrong') }
    }
    // get status based on conditions 
    // 1. only success count, return "Success" (green color)
    // 2. last obj contains failed status return "Failed" (red color)
    // 3. contain both success and failed status & last obj contains success, return "FailedSuccess" (yellow color)
    const getStatus = (data, invoice) => {
        if (data.length) {
            let Scount = 0, Fcount = 0;
            data && data.length && data.map((item, i) => {
                if (item.Status === SUCCESS) { Scount++; }
                else if (item.Status === FAILED) { Fcount++; }
            });

            if (invoice != undefined) {
                if (Scount != 0 && Fcount === 0) { return SUCCESS }
                else if (Scount != 0 && Fcount != 0 && data[0].Status === SUCCESS) { return FAILED; }
                else if (data[0].Status === FAILED) { return FAILED }
            } else {
                if (Scount != 0 && Fcount === 0) { return SUCCESS }
                // else if (Scount != 0 && Fcount != 0 && data[data.length - 1].Status === SUCCESS) { return FAILEDSUCCESS; }
                // else if (data[data.length - 1].Status === FAILED) { return FAILED }
                else if (Scount != 0 && Fcount != 0 && data[0].Status === SUCCESS) { return FAILEDSUCCESS; }
                else if (data[0].Status === FAILED) { return FAILED }
            }

            // currentStatus=success;
            // previousStatusCount=2;
            // if(currentStatus===success&&previousStatusCount>0) amber
            // else if(currentStatus===success&&previousStatusCount==0) green
            // else if(currentStatus!=success) red

        }
    }

    const eventChange = ({ key, title, data }) => {
        setActiveEvent(key);
        setActiveEventTitle(title);
        setActiveEventData(data)
    }

    const navTOOrders = () => {
        if (currentComponent === COMPONENTFROM_CLOSEDPOS) {
            history.push({ pathname: ORDERS_DASHBOARD, state: { currentTab: '3', startDateTime, endDateTime, closePODataSet: location.state.closePODataSet } })
        }
        else if (currentComponent === COMPONENTFROM_OPENINVOICE || currentComponent === COMPONENTFROM_OPENPOS) {
            const { sk, pk, itemID } = details;
            history.push({
                pathname: ORDERS_ACTIVITY, state: {
                    sk: sk ? sk : '', pk, itemID, data: orderDetails, startDateTime, endDateTime,
                    currentTab: currentComponent === COMPONENTFROM_OPENINVOICE ? '1' : '2', openPODataSet: location.state.openPODataSet
                }
            })
        } else if (currentComponent === SEARCHBY_OPENINVOICE) {
            const { SK, PK } = details;
            history.push({ pathname: `/orders/${SK}`, state: { SK: "InvoiceItem#", PK: PK, wholeData: details, startDateTime, endDateTime, openPODataSet: location.state.openPODataSet } });
        } else {
            history.push({ pathname: ORDERS_DASHBOARD, state: { currentTab: '2', startDateTime, endDateTime, openPODataSet: location.state.openPODataSet, prevURL: window.location.pathname } })
        }
    }

    const nameChange = (e) => { }

    return (
        <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <div className="displaySpacebetween">
                        <h2 className="heading18Bold margin0"> PO Data </h2>
                        <ul className='custom-list-inline alignItemsCenter'>
                            {userDetails.GSI1PK !== SALES_PERSON_KEY && retriggerBtnActiveState ?
                                <li>
                                    <div className="paddingLeft20">
                                        <CustomButton icon={<RedoOutlined />} buttonLabel={'Re Trigger'} className="createUserBtn alignItemsRight" handleSubmit={() => reTriggerPOEvenets(PK, SK)} />
                                    </div>
                                </li>
                                : null}
                            <li className='paddingLeft10'>
                                <CustomNavigator onChange={navTOOrders} />
                            </li>
                        </ul>
                    </div>
                    {!loading ?
                        <>
                            <Table dataSource={POData} columns={columns} className="primary-color-table paddingTop10Bottom10" pagination={false} scroll={{ x: 500 }} />

                            <Row className="po-data">
                                {POEvents && POEvents.map(item =>
                                    // <Col md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6 }} xs={{ span: 24 }} sm={{ span: 24 }} className="elements">
                                    <Col md={{ span: 8 }} lg={{ span: 8 }} xl={{ span: item.key === CASELOTCHECK ? 4 : 5 }} xxl={{ span: 4 }} sm={{ span: 12 }} xs={{ span: 24 }} className="elements" key={item}>
                                        <div className="displayFlex" onClick={() => eventChange(item)}>
                                            {item.data.length === 0 ? <CheckOutlined className="tickIcon-greyColor-styles iconColorStyles" /> :
                                                item.status === FAILED ? <CloseOutlined className="cancelIcon-redColor-styles iconColorStyles" /> :
                                                    <CheckOutlined className={item.status == SUCCESS ? "tickIcon-greenColor-styles iconColorStyles" :
                                                        item.status == FAILEDSUCCESS ? "tickIcon-yellowColor-styles iconColorStyles" : ""} />}
                                            <div className="labels">
                                                <h3>{item.title}</h3>
                                                {item.data.length ? <p>{item.data.length} {item.key === INVOICES ? 'Invoice(s) Generated' : 'Time(s) Evaluated'}</p> : '-'}
                                                <p>{item.data.length ? moment(item.data[item.data.length - 1].GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE) : '-'}</p>
                                            </div>
                                        </div>
                                    </Col>)}
                            </Row>

                            {activeEvent ? <>
                                <div className="displaySpacebetween marginBottomRg paddingTop20">
                                    <h2 className="heading18Bold margin0">{activeEventTitle}</h2>
                                </div>
                                {activeEvent === VALIDATION || activeEvent === ACKNOWLEDGEMENT ? <ValidationAkgTable data={activeEventData} activeEvent={activeEvent} /> :
                                    activeEvent === MRPCHECK || activeEvent === MRPCHECK2 || activeEvent === EANLOOKUP || activeEvent === CASELOTCHECK ? <MRPCheckEANLookUpTable data={activeEventData} activeEvent={activeEvent} /> :
                                        activeEvent === INVOICES ? <InvoiceTable data={activeEventData} activeEvent={activeEvent} /> :
                                            activeEvent === SOCREATIONS ? <SOCreationTable data={activeEventData} POData={SOPOData} activeEvent={activeEvent} /> :
                                                activeEvent === ASNSENT ? <ASNSentTable data={activeEventData} activeEvent={activeEvent} /> : null}
                            </> : null}
                        </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>}
                </Col>
            </Row>

            <Modal visible={isModalVisible} className="loadingModal">
                <div className="textAlignCenter">
                    <p><Spin className='colorWhite' /> </p>
                    <h2 className="colorWhite">Please wait...</h2>
                </div>
            </Modal>

        </Dashboard>
    );
}

export default POData;
