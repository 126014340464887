import React from 'react';
import { Table } from 'antd';
import { DATE_PICKER_YEAR_MONTH_DATE } from '../../../constants';
import moment from 'moment';

const ValidationAkgTable = ({ data }) => {
    const tableData = [];
    data && data.map((item, i) => {
        var obj = {
            key: i,
            evaluatedCount: i + 1,
            evaluatedDate: moment(item.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE),
            status: <span className="capitalizeAllCaps">{item.Status.toLowerCase()}</span>,
            message: item.Message
        }
        tableData.push(obj);
    })

    const columns = [{
        title: 'Evaluated Count',
        dataIndex: 'evaluatedCount',
        key: 'evaluatedCount'
    }, {
        title: 'Evaluated Date',
        dataIndex: 'evaluatedDate',
        key: 'evaluatedDate'
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    }, {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        width: '35%'
    }];

    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default ValidationAkgTable;