import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { getItems } from '../../../functions';
import DefaultSorting from '../../../images/defaultSort.svg';
import AsendingSorting from '../../../images/asendingSort.svg';
import DecendingSorting from '../../../images/decendingSort.svg';
import { BB_MASTER_DATA_EDIT, MASTER_DATA_EDIT } from '../../../routingUrl';
import './index.scss';
import { SALES_PERSON_KEY } from '../../../constants';

const ASCENDING = 'ascending';
const DECENDING = 'decending';
const TCPLPSKU = 'TCPLPSKU';
const CustDC = 'CustDC';
const CustProdID = 'CustProdID';
const TCPLSSKU = 'TCPLSSKU';
const PO = 'PO';
const SO = 'SO';
const STATUS = 'Status';


const BBMasterTable = ({ data, activeSortOrder, activeElement, updateKeyValue, productArray, fromCount, toCount }) => {
    const history = useHistory();
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const [tableData, setTableData] = useState([]);

    useEffect(() => { if (data) setTableRowData(data); }, [data])

    // const setTableRowData = (data) => {
    //     const tableData = [];
    //     data && data.map((item, i) => {
    //         var obj = {
    //             key: i,
    //             division: item.Division, TCPLPSKU: getItems(item.PK, 0), TCPLSSKU: getItems(item.PK, 1),
    //             GTIN: '-', TCPLPlant: item.PlantCode, TCPLCustomer: item.CustomerCode,
    //             CustProdID: getItems(item.GSI1SK, 1), CustDC: getItems(item.GSI2, 1), CustProdDesc: item.CustomerProductDescription,
    //             ParentSKUDescription: item.ParentSKUDescription, SystemSKUDescription: item.SystemSKUDescription, Division: item.Division
    //         }
    //         tableData.push(obj);
    //     })
    //     setTableData(tableData)
    // }

    const setTableRowData =(data)=>{
        setTableData(data);
    }

    const handleSorting = (name, info) => { updateKeyValue(name, info, productArray, fromCount, toCount) }

    const getSorterIcon = useCallback((info) => {
        return activeSortOrder == ASCENDING && info == activeElement ? <span onClick={() => handleSorting(info, DECENDING)}><img src={AsendingSorting} className="cursorpointer" /></span> :
            activeSortOrder == DECENDING && info == activeElement ? <span onClick={() => handleSorting(info, ASCENDING)}><img src={DecendingSorting} className="cursorpointer" /></span> :
                <span onClick={() => handleSorting(info, ASCENDING)} > <img src={DefaultSorting} className="cursorpointer" /> </span>
    }, [activeSortOrder, activeElement])

    const toEditProductMapping = (record) => {
        //if (userDetails.GSI1PK !== SALES_PERSON_KEY) 
        history.push({ pathname: BB_MASTER_DATA_EDIT, state: { data: record } })
    }

    const columns = [{
        title: <p className="margin0">TCPL Parent SKU</p>,
        dataIndex: 'P_MATERIAL',
    }, {
        title: <p className="margin0">TCPL Parent SKU Desc</p>,
        dataIndex: 'P_DESC',
          
    }, {
        title: <p className="margin0">TCPL System SKU</p>,
        dataIndex: 'C_MATERIAL',
    },{
        title: <p className="margin0">TCPL System SKU Desc</p>,
        dataIndex: 'S_DESC',
          
    },{
        title: <p className="margin0">Customer Product ID</p>,
        dataIndex: 'BB_SKU_CODE',
          
    },{
        title: <p className="margin0">Brand</p>,
        dataIndex: 'BRAND',
          
    },{
        title: <p className="margin0">Division </p>,
        dataIndex: 'DIV',
          
    },
    // {
    //     title: <p className="margin0">S_DESC </p>,
    //     dataIndex: 'S_DESC',
          
    // }
];

    return (<Table dataSource={tableData} columns={columns} pagination={{ position: ["bottomCenter"] }}
        onRow={(record, i) => { return { onClick: event => { toEditProductMapping(record) } } }} scroll={{ x: 500 }}
        className={userDetails.GSI1PK !== SALES_PERSON_KEY ? 'primary-color-table masterDataMappingTable cursorTRPointer' : 'primary-color-table masterDataMappingTable'} />)
}

export default BBMasterTable;