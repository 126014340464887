import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { DATE_PICKER_YEAR_MONTH_DATE, KEY_ITEMAMEND, KEY_ITEMCASELOT, KEY_ITEMEAN, KEY_MRP_CHECK2 } from '../../../constants';
import moment from 'moment';
import { ORDERS_PODETAILS } from '../../../routingUrl';

const MRPCHECK = 'MRP_CHECK';
const MRPCHECK2 = 'MRP_CHECK2';
const EANLOOKUP = 'EAN_LOOKUP';
const CASELOTCHECK = 'CASELOT_CHECK';
var evaluatedCount = {
    title: 'Evaluated Count',
    dataIndex: 'evaluatedCount',
    key: 'evaluatedCount'
};
var evaluatedDate = {
    title: 'Evaluated Date',
    dataIndex: 'evaluatedDate',
    key: 'evaluatedDate'
};
var totallineItems = {
    title: 'Total Line Items',
    dataIndex: 'totallineItems',
    key: 'totallineItems'
};
var failedLineItems = {
    title: 'Failed Line Items',
    dataIndex: 'failedLineItems',
    key: 'failedLineItems'
};
var invoiceId = {
    title: 'Invoice ID',
    dataIndex: 'invoiceId',
    key: 'invoiceId'
};


const MRPCheckEANLookUpTable = ({ data, activeEvent }) => {
    const history = useHistory();
    const [columns, setColumns] = useState([]);

    var type = null;
    if (activeEvent === MRPCHECK) type = KEY_ITEMAMEND;
    else if (activeEvent === CASELOTCHECK) type = KEY_ITEMCASELOT;
    else if (activeEvent === EANLOOKUP) type = KEY_ITEMEAN;
    else if (activeEvent === MRPCHECK2) type = KEY_MRP_CHECK2;

    const navToOrdersActivity = (item) => { history.push({ pathname: ORDERS_PODETAILS, state: { PK: item.PK, SK: type, type: type, itemIds: item.FailedRecordSet ? item.FailedRecordSet : [], data: item } }) }

    const tableData = [];
    data && data.map((item, i) => {
        var obj = {
            key: i,
            evaluatedCount: <p onClick={() => navToOrdersActivity(item)}><span className="cursorpointer">{i + 1}</span></p>,
            evaluatedDate: <p onClick={() => navToOrdersActivity(item)} > <span className="cursorpointer">{moment(item.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)} </span></p>,
            totallineItems: <p onClick={() => navToOrdersActivity(item)} > <span className="cursorpointer">{item.TotalItemsCount ? item.TotalItemsCount : '0'} </span></p>,
            failedLineItems: <p onClick={() => navToOrdersActivity(item)} > <span className="cursorpointer">{item.FailedItemsCount ? item.FailedItemsCount : '0'} </span></p>,
            invoiceId: <p onClick={() => navToOrdersActivity(item)} > <span className="cursorpointer">{item.InvoiceID ? item.InvoiceID : '0'} </span></p>
        }
        tableData.push(obj);
    })

    useEffect(() => {
        if (activeEvent === MRPCHECK2) setColumns([evaluatedCount, invoiceId, evaluatedDate, totallineItems, failedLineItems])
        else setColumns([evaluatedCount, evaluatedDate, totallineItems, failedLineItems])
    }, [])

    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default MRPCheckEANLookUpTable;