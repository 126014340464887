import {
    ADMIN, CLOSED_POS, CUSTOMER_ADMIN, LOCALHOST_URL, OpenInvoice, OpenPOs, ERROR_MAIL_RECIPIENTS, ERROR_MAIL_RECIPIENTS_KEY,
    SALES_PERSONS, STATUS_EAN_LOOPUP_FAILED, STATUS_INVOICE_PENDING,
    STATUS_PO_AMENDMENT, STATUS_XML_VALIDATION_FAIL, SUB_ADMIN_KEY, SUB_ADMIN, SALES_PERSON_KEY, CUST_ADMIN_KEY, STATUS_CASELOT_AMENDMENT, STATUS_INSTANT_ACK_FAILED, POs,
    MASTER_DATA,SHIPPING_CODES
} from "../constants";
import { Select } from 'antd';
const Option = Select.Option;

export const OrdersTabs = [OpenInvoice, OpenPOs, CLOSED_POS];
export const UserTabs = [CUSTOMER_ADMIN, ADMIN, SALES_PERSONS];
export const BBOrdersTabs = [POs];
export const BBMasterTabs = [MASTER_DATA, SHIPPING_CODES]


export function getSerachID(data) {
    // if (data.length) {
    //   return data.map((item) => {
    //     return (<Option key={item} value={item.category}> {item} </Option>)
    //   })
    // }
}

export const getOnlyID = (string) => {
    let str = string ? string.replace(/\D/g, "") : "--"
    return str;
}

export const getStatus = (string) => {
    let str = string.substring(0, 6)
    if (str === "INVOIC") return STATUS_INVOICE_PENDING;
    if (str === "ItemAm") return STATUS_PO_AMENDMENT;
    if (str === "ItemEA") return STATUS_EAN_LOOPUP_FAILED;
    if (str === "XMLFai") return STATUS_XML_VALIDATION_FAIL;
    if (str === "ItemCa") return STATUS_CASELOT_AMENDMENT;
    if (str === "Instan") return STATUS_INSTANT_ACK_FAILED;
    return string;
}

export const getItems = (str, number) => {
    // var str = 'one:two;three';
    var popedString = str.split('#');
    if (popedString.length >= 2) {
        popedString = popedString[number];
        return popedString;
    }
}

export const getLineItemID = (str) => {
    // var str = 'one:two;three';
    var popedString = str.split('#');
    if (popedString.length >= 2) {
        popedString = popedString[1];
        return popedString;
    } else {
        return "--";
    }
}

export const getInvoiceID = (str) => {
    // var str = 'InvoiceItem#0910047401#Item#00002';
    var popedString = str.split('#');
    if (popedString.length >= 2) {
        popedString = popedString[3];
        return popedString;
    } else {
        return "--";
    }
}

export const getCompanyNamesList = (data) => {
    if (data && data.length) {
        return data.map((item) => {
            if (item.IsActive) {
                return (<Option key={item.CompanyName} value={item.CompanyName}> {item.CompanyName} </Option>)
            }
        })
    }
}

export const getSystemSKUList = (data) => {
    if (data && data.length) {
        return data.map((item) => {
            return (<Option key={item.SK} value={item.SK}> {item.SK} </Option>)
        })
    }
}

// export const auth = () => {
//     return {
//         // domain: 'mtedi-staging.auth.ap-south-1.amazoncognito.com',
//         // domain: 'mtedi-prod.auth.ap-south-1.amazoncognito.com',
//         domain: process.env.REACT_APP_DOMAIN || 'mtedi-staging.auth.ap-south-1.amazoncognito.com',
//         scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
//         redirectSignIn: window.location.href == "http://localhost:3000/" ? LOCALHOST_URL : process.env.REACT_APP_REDIRECT_URL || STAGING_URL,
//         redirectSignOut: window.location.href == "http://localhost:3000/" ? LOCALHOST_URL : process.env.REACT_APP_REDIRECT_URL || STAGING_URL,
//         responseType: 'token',
//         region: process.env.REACT_APP_REGION || 'ap-south-1',
//         userPoolId: process.env.REACT_APP_USER_POOLID || 'ap-south-1_WvKWuEvMC',
//         userPoolWebClientId: process.env.REACT_APP_USER_POOLWEB_CLIENTID || '3qlrsg26lntpfl2uiae45uvign'
//     }
// }


// custom pagination - no of records per page
export const getShowOptionsList = (data) => {
    if (data && data.length) {
        return data.map((item) => {
            return (<Option key={item} value={item}> {item} </Option>)
        })
    }
}

// get user types
export const getUserTypeBySuperAdmin = [<Option key={CUST_ADMIN_KEY}>{CUSTOMER_ADMIN}</Option>, <Option key={SUB_ADMIN_KEY}>{SUB_ADMIN}</Option>, <Option key={SALES_PERSON_KEY}>{SALES_PERSONS}</Option>]
export const getUserTypeByCustAdmin = [<Option key={SUB_ADMIN_KEY}>{SUB_ADMIN}</Option>, <Option key={SALES_PERSON_KEY}>{SALES_PERSONS}</Option>, <Option key={ERROR_MAIL_RECIPIENTS_KEY}>{ERROR_MAIL_RECIPIENTS}</Option>]
export const getUserTypeBySubAdmin = [<Option key={SALES_PERSON_KEY}>{SALES_PERSONS}</Option>, <Option key={ERROR_MAIL_RECIPIENTS_KEY}>{ERROR_MAIL_RECIPIENTS}</Option>]
