import React from 'react';
import { Form, Checkbox } from 'antd';
import './index.scss';

const CustomCheckBox = ({ label, className, onChange, checked }) => {
    const FormItem = Form.Item;
    return (<FormItem label={label} colon={false} className={className}>
        <Checkbox onChange={onChange} checked={checked}></Checkbox>
    </FormItem>)
}

export default CustomCheckBox;