import React from 'react';
import { Form, Switch } from 'antd';
import "./styles.scss";

const SwitchDesign = ({ label, onChange, className, checked }) => {
    const FormItem = Form.Item;

    return (<FormItem label={label} className={className}>
        <Switch className={className} checked={checked} onChange={onChange} />
    </FormItem>)
}

export default SwitchDesign;