import React from 'react';
import { DatePicker, Form } from 'antd';
import './index.scss';

const CustomDatePicker = ({ label, placeholder, value, style, onChange, showTime, errorText, disabledDate, className, format, datePickerStyles, disabled }) => {
    const FormItem = Form.Item;
    return (<FormItem label={label} colon={false} className={className && className}>
        <DatePicker showTime={showTime} disabled={disabled} getPopupContainer={triggerNode => triggerNode.parentNode}
            placeholder={placeholder} onChange={onChange} style={style} format={format ? format : "MMMM-DD-YYYY HH:mm:ss"}
            value={value} disabledDate={disabledDate} className={datePickerStyles} />
        <p className="errorDatePickerStyles">{errorText}</p>
    </FormItem>)
}

export default CustomDatePicker;