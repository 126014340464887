import React, { useState } from 'react';
import { Row, Col, message } from 'antd';
import { useHistory } from 'react-router-dom';
import Dashboard from '../../../dashboard';
import CustomeButton from '../../../../utils/components/buttons';
import CustomInput from '../../../../utils/components/customeInputFields/input';
// import { createEDIErrorRecipients, createEDISOCreationRecipients } from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { USERS_DASHBOARD, USERS_EDIT } from '../../../../utils/routingUrl';
import { queryAzureADUser } from '../../../../graphql/queries';
import AutoCompleteSection from '../../../../utils/components/autoCompleteSelection';

const CreateSOMail = (props) => {
    const history = useHistory();
    const [state, setState] = useState({
        email: '',
        errors: {},
        name: ''
    });
    const [emailList, setEmailList] = useState([]);
    const { email, name, errors } = state;

    const handleValueChange = (info, e) => {
        if (e !== "") errors[info] = "";
        setState(state => ({ ...state, [info]: e }))
        if (e) {
            var subString = e.substring(0, e.indexOf("@"));
            setState(state => ({ ...state, ['name']: subString.split('.').join(' ') }))
        }
    }

    const handleSubmit = async () => {
        let errors = {};
        if (email === "" || email === undefined) errors.email = "Please select an email ID";
        if (name === "" || name === undefined) errors.name = "Please select a user type";
        setState(state => ({ ...state, errors }));
        if (Object.keys(errors).length === 0) {
            var body = { email, name, isActive: 'true' }, apiData;
            try {
                // if (window.location.pathname.includes('error')) apiData = await API.graphql(graphqlOperation(createEDIErrorRecipients, { input: body }));
                // else apiData = await API.graphql(graphqlOperation(createEDISOCreationRecipients, { input: body }));
                // message.success('User created successfully!');
                // history.push(USERS_DASHBOARD);
            } catch (error) {
                if (error.errors[0].errorType.includes("ConditionalCheckFailedException")) {
                    message.error('Already existed user data');
                } else message.error('Invalid Data');
            }
        }
    }

    const handleCancel = () => { history.push(USERS_DASHBOARD) }

    const handleSearch = async (value) => {
        let res = [];
        if (!value || value.length < 3 || value.indexOf('@') >= 0) {
            res = [];
            setState(state => ({ ...state, email: '' }))
        } else {
            const apiData = await API.graphql({ query: queryAzureADUser, variables: { Email: value } });
            res = apiData.data.queryAzureADUser.body ? apiData.data.queryAzureADUser.body : [];
        }
        setEmailList(res);
    };
    const nameChange = (e) => { }
    return (
        <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <h2 className="heading18Bold margin0">Create User</h2>
                    <div className="CreateUserForm">
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">E-Mail ID</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <AutoCompleteSection options={emailList} onSearch={handleSearch} placeholder="Email"
                                    value={email || undefined} onChange={(e) => { handleValueChange("email", e) }} autoCompleteStyles="autoCompleteStyles"
                                    errorMsg="true" errorText={errors.email} disabled={window.location.pathname === USERS_EDIT} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">Name</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={name || undefined} placeholder='Name'
                                    onChange={(e) => { handleValueChange("name", e) }} inputClassname="inputBoxField" disabled />
                            </Col>
                        </Row>

                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}> </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }} className="displaySpacebetween ">
                                <CustomeButton buttonLabel="Cancel" className="cancelBtn" handleSubmit={handleCancel} />
                                <CustomeButton buttonLabel={window.location.pathname === USERS_EDIT ? 'Update' : "Submit"} className="submitBtn" handleSubmit={handleSubmit} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Dashboard>
    )
}
export default CreateSOMail;