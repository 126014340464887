import React from 'react';
import { Modal } from 'antd';
import CustomButton from '../../buttons';
import './styles.scss';

const DeleteModal = (props) => {
    const handleOK = async () => { props.handleOK() }
    const handleCancel = async () => { props.handleCancel() }
    const { message, modalVisible } = props;
    return (<Modal visible={modalVisible} onOk={handleOK} onCancel={handleCancel} className='FilterModal'
        footer={null} width={'80vh'} centered>
        <h3>{message}</h3>
        <div className="buttonsInline paddingTop10">
            <CustomButton handleSubmit={handleCancel} className="clearFilter" buttonLabel="Cancel" />
            <CustomButton handleSubmit={handleOK} className="filterButton" buttonLabel="Ok" />
        </div>
    </Modal>
    )
}

export default DeleteModal;