import React from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { DATE_PICKER_YEAR_MONTH_DATE } from '../../../constants';
import moment from 'moment';
import { ORDERS_PODETAILS } from '../../../routingUrl';

const TYPE_INVOICE = 'InvoiceItem';
const INVOICES = 'INVOICES';

const InvoiceTable = ({ data, activeEvent }) => {
    const history = useHistory();

    var type = null;
    if (activeEvent === INVOICES) type = TYPE_INVOICE;

    // For navigating to inner pages based on status
    const navToOrdersActivity = (item) => { history.push({ pathname: ORDERS_PODETAILS, state: { PK: item.PK, SK: type + '#' + item.InvoiceID + '#Item#', type: type, data: item } }) }

    const tableData = [];
    data && data.map((item, i) => {
        var obj = {
            key: i,
            invoiceCount: <p onClick={() => navToOrdersActivity(item)}> <span className="cursorpointer">{item.InvoiceID}</span></p>,
            invoiceDate: <p onClick={() => navToOrdersActivity(item)}> <span className="cursorpointer">{moment(item.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}</span></p>,
            invoiceStatus: <p onClick={() => navToOrdersActivity(item)}> <span className="cursorpointer capitalizeAllCaps">{item.Status.toLowerCase()}</span></p>,
        }
        tableData.push(obj);
    })

    const columns = [{
        title: 'Invoice ID',
        dataIndex: 'invoiceCount',
        key: 'invoiceCount'
    }, {
        title: 'Invoice Date',
        dataIndex: 'invoiceDate',
        key: 'invoiceDate'
    }, {
        title: 'Invoice Status',
        dataIndex: 'invoiceStatus',
        key: 'invoiceStatus'
    }];

    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default InvoiceTable;