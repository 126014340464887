import React from 'react';
import { Menu } from 'antd';
import { Auth } from 'aws-amplify';
import { HOME } from '../../../utils/routingUrl';
import { useHistory } from 'react-router-dom';

function Dashboard() {
    const history = useHistory();

    const logout = () => {
        // sessionStorage.clear();
        // var timer;
        // // window.clearTimeout(timer);
        // timer = window.setTimeout(function () { Auth.signOut(); clearTimeout(timer) }, 100);
        try {
            history.push(HOME)
            sessionStorage.clear();
            const timer = setTimeout(() => { Auth.signOut(); }, 100);
            clearTimeout(timer);
        } catch (error) { console.log('error signing out: ', error); }
    }
    return (<Menu> <Menu.Item key="2"><span onClick={logout}> Log out</span> </Menu.Item> </Menu>)
}

export default Dashboard;