import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import Dashboard from '../../dashboard';
import './index.scss';
import { ORDERS_PODATA } from '../../../utils/routingUrl';
import CustomNavigator from '../../../utils/components/backToOrders';
import CustomRadioButtonTabs from '../../../utils/components/radioButtonTabs';
import EanLookUpTable from '../../../utils/components/tables/eanLookUp';
import POByID from '../../../utils/components/tables/searchPage/openPO';
import { API } from 'aws-amplify';
import { queryCompletePOEvents, queryInvoiceItemsByInvoiceID } from '../../../graphql/queries';
import OrdersActivityTable from '../../../utils/components/tables/orderAmemndament';
import InvoiceByID from '../../../utils/components/tables/searchPage/invoice';
import { HASH_KEY, KEY_ITEMAMEND, KEY_ITEMCASELOT, KEY_ITEMEAN, KEY_MRP_CHECK2 } from '../../../utils/constants';
import MRPCheck2PassedItems from '../../../utils/components/tables/MRPCheck2';
import ASNSentLineItems from '../../../utils/components/tables/ANSSent/ASNSentLineItems';

const TYPE_INVOICE = 'InvoiceItem';
const TYPE_ASN = 'ASN';

const FAILED = 'FAILED';

const POInnerScreens = (props) => {
    const location = useLocation();
    const history = useHistory();
    const [type, setType] = useState(location.state.type);
    const [activeTab, setActiveTab] = useState('PassedItems');
    const [nextToken, setNextToken] = useState(null)
    const [EANFailedData, setEANFailedData] = useState([]);
    const [amendmentItems, setAmendmentItems] = useState([]);
    const [passedItems, setPassedItems] = useState([]);
    const [invoiceItems, setInvoiceItems] = useState([]);
    const [PKValue, setPKValue] = useState('');
    const [SKValue, setSKValue] = useState('');

    useEffect(() => {
        const { PK, SK, itemIds, data } = location.state;
        if (type === KEY_ITEMEAN || type === KEY_ITEMAMEND || type === KEY_ITEMCASELOT) getFailedNPassedItems(PK, SK, type, itemIds);
        else if (type === TYPE_INVOICE || type === TYPE_ASN) getInvoiceLineItemsByInvoiceID(PK, SK);
        else if (type === KEY_MRP_CHECK2) getFailedNPassedItems(PK, TYPE_INVOICE + HASH_KEY + data.InvoiceID, TYPE_INVOICE, itemIds);
        var splitData = PK.split('#');
        setPKValue(splitData[0] + '#' + splitData[1])
        setSKValue(splitData[2] + '#' + splitData[3])
        if (type === KEY_ITEMEAN && data.Status === FAILED) setActiveTab('EANFailedItems');
        if (type === KEY_ITEMAMEND && data.Status === FAILED) setActiveTab('AmendmentItems');
    }, []);

    async function getInvoiceLineItemsByInvoiceID(PK, SK) {
        try {
            const apiData = await API.graphql({ query: queryInvoiceItemsByInvoiceID, variables: { PK, SK, first: 100, after: nextToken } });
            setInvoiceItems(apiData.data.queryInvoiceItemsByInvoiceID.items)
        } catch (error) { console.log('error occured while fetching the data ', error); }
    }

    async function getFailedNPassedItems(PK, SK, Type, itemIds) {
        try {
            const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK, SK, Type, itemIds } });
            var data = apiData.data.queryCompletePOEvents.body;
            if (type === KEY_MRP_CHECK2) setPassedItems(data.PassedInvoiceItems);
            else setPassedItems(data.PassedItems);
            if (type === KEY_ITEMEAN) setEANFailedData(data.EANItems);
            else if (type === KEY_ITEMAMEND) setAmendmentItems(data.AmendmentItems);
            else if (type === KEY_ITEMCASELOT) setAmendmentItems(data.CaseLotAmendmentItems);
            else if (type === KEY_MRP_CHECK2) setAmendmentItems(data.AmendmentItems);
        } catch (error) { console.log('error occured while fetching the data ', error); }
    }

    const tabChange = e => { setActiveTab(e.target.value); };
    const navTOOrders = () => { history.push({ pathname: ORDERS_PODATA, state: { PK: PKValue, SK: SKValue } }) }

    const nameChange = (e) => { }

    return (
        <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <div className="displaySpacebetween">
                        <h2 className="heading18Bold margin0"> PO Event Details</h2>
                        <div className="po-data-innerScreen paddingTop10">
                            {type === KEY_ITEMEAN ?
                                <CustomRadioButtonTabs onChange={tabChange} value={activeTab} radioBtnTabsStyles="radioBtnTabsStyles EANRadioBtnStyles"
                                    radioOptions={[{ key: "EANFailedItems", value: "Article Faild Items" }, { key: "PassedItems", value: "Passed Items" }]} />
                                : type === KEY_ITEMAMEND || type === KEY_ITEMCASELOT || type === KEY_MRP_CHECK2 ?
                                    <CustomRadioButtonTabs onChange={tabChange} value={activeTab} radioBtnTabsStyles="radioBtnTabsStyles MRPRadioBtnStyles"
                                        radioOptions={[{ key: "AmendmentItems", value: "Amendment Items" }, { key: "PassedItems", value: "Passed Items" }]} />
                                    : null}
                            <CustomNavigator onChange={navTOOrders} />
                        </div>
                    </div>

                    {type === TYPE_INVOICE ? <div className="paddingTop10"><InvoiceByID data={invoiceItems} /></div> :
                        type === TYPE_ASN ? <div className="paddingTop10"><ASNSentLineItems data={invoiceItems} /></div>
                            : type === KEY_ITEMEAN ?
                                <> {activeTab === "PassedItems" ?
                                    <POByID data={passedItems} /> : <EanLookUpTable data={EANFailedData} />}</>
                                : type === KEY_ITEMAMEND || type === KEY_ITEMCASELOT ?
                                    <>{activeTab === "PassedItems" ?
                                        <POByID data={passedItems} /> : <OrdersActivityTable data={amendmentItems} type={type} />}</>
                                    : type === KEY_MRP_CHECK2 ?
                                        <>{activeTab === "PassedItems" ?
                                            <MRPCheck2PassedItems data={passedItems} /> : <OrdersActivityTable data={amendmentItems} type={type} />}</>
                                        : null}
                </Col>
            </Row>
        </Dashboard>
    );
}

export default POInnerScreens;
