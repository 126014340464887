import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, message } from 'antd';
import Dashboard from '../dashboard';
import CustomButton from '../../utils/components/buttons';
import { useHistory } from 'react-router-dom';
import { CUSTOMER_DATA_CREATE } from '../../utils/routingUrl';
import CustomPagination from '../../utils/components/pagination';
import { NUMBER_ONE } from '../../utils/constants';
import CustomSearchBar from '../../utils/components/customeInputFields/searchField';
import CustomEditableTable from '../../utils/components/tables/editableTable/customEditableTable';

const data = [{
    division: 'Foods', vendorCode: 22523533, TCPLCFACode: '2352525', RRLDCNo: 'TATA', customerCode: '445454',
}]

const CustomerDataDashboard = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [customData, setCustomData] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    // pagination initial state
    const [paginationData, setPaginationData] = useState({
        nextToken: null,
        nextBtn: false,
        previousBtn: true,
        currentPage: 1,
        fromCount: NUMBER_ONE,
        toCount: NUMBER_ONE,
        tokenObj: {}
    });
    const { nextToken, nextBtn, previousBtn, currentPage, fromCount, toCount, tokenObj } = paginationData;

    useEffect(() => { getcustomData(nextToken); }, [])

    const getcustomData = (nextToken) => {
        // const apiData = await API.graphql({ query: queryOpenInvoiceByDateRange, variables: { GSI1PK: companySKSelected + '#OpenInvoice', start: startDateFormat, end: endDateFormat, first: 10, after: nextToken } });
        // var data = apiData.data.queryOpenInvoiceByDateRange;
        // setcustomData(data.items)
        // setLoading(false);
    }

    // to detch next Data
    const onNext = (cPage) => {
        let tokenTobeSend = tokenObj[cPage] ? tokenObj[cPage] : null;
        // getcustomData(tokenTobeSend, PAGINATION_NEXT, cPage + 1);
    }

    // after click on Previous icon
    const onPre = (cPage) => {
        let tokenTobeSend = tokenObj[cPage - 2] ? tokenObj[cPage - 2] : null;
        // getcustomData(tokenTobeSend, PAGINATION_PREVIOUS, cPage - 2);
    }

    const handleSave = async (tableData) => {
        // try {
        //     const newTodo = await API.graphql(graphqlOperation(addUser, body));
        //     message.success('product updated successfully!');
        // } catch (error) {
        //     if (error.errors[0].errorType.includes("ConditionalCheckFailedException")) {
        //         message.error('Already existed user data');
        //     } else message.error('Invalid Data');
        // }
    }

    const handleValueChange = (e) => { setSearchValue(e.target.value); }

    // fetching Data while click on search
    const onSearch = async () => {
        setSearchValue(searchValue);
        // const apiData = await API.graphql({ query: querySearchAnythingByID, variables: { GSI2: searchValue, first: 10, after: nextToken } });
        // var data = apiData.data.querySearchAnythingByID.items;
        // if (data.length) {
        //      if (data[0].Type === ORDER) { history.push({ pathname: ORDERS_PODATA, state: { PK: data[0].PK, SK: data[0].SK } }) }
        //     else history.push({ pathname: `/orders/${data[0].SK}`, state: { SK: "InvoiceItem#", PK: data[0].PK, wholeData: data[0] } });
        // } else message.warning("No Data Found")
    }

    const createcustomData = () => { history.push(CUSTOMER_DATA_CREATE) }

    return (
        <Dashboard>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <Row className="displayFlex justifyContentCenter alignItemsCenter">
                        <Col md={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="tableTitle paddingTop10Bottom20">
                            <h2 className="margin0">Customer Master Data </h2>
                        </Col>
                        <Col md={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="create-user paddingTop10Bottom20">
                            <div className="displayFlex justifyContentFlexEnd">
                                <CustomSearchBar placeholder="Search by CFA PLANT Code" className={"width200"} value={searchValue}
                                    inputClassname="Search-main" onChange={(e) => { handleValueChange(e) }} onSearch={onSearch} />
                                {/* <div className="paddingLeft20">
                                    <CustomButton buttonLabel="Add New" className="createUserBtn alignItemsRight" handleSubmit={createcustomData} />
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: '24' }}>
                            <>{!loading ? <>
                                <CustomEditableTable handleSave={handleSave} dataSource={data} />
                                <CustomPagination onPre={() => onPre(currentPage)} onNext={() => onNext(currentPage)}
                                    from={fromCount} to={toCount} nextBtn={nextBtn} previousBtn={previousBtn} />
                            </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>} </>

                        </Col>
                    </Row>

                </Col>
            </Row>
        </Dashboard>
    );
}

export default CustomerDataDashboard;
