import React, { useState } from 'react';
import { Table, Input, Form, Typography, Select } from 'antd';
import './index.scss';
import CustomButton from '../../buttons';
const Option = Select.Option;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const inputNode = inputType === 'selection' ? <Select> {[<Option value={'Yes'}> Yes </Option>, <Option value={'No'}> No </Option>]}</Select>
        : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `Please enter a ${title}!` }]} className="editableTableError">
                    {inputNode}
                </Form.Item>
            ) : (children)}
        </td>
    );
};

const CustomEditableTable = ({ dataSource, handleSave }) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(dataSource);
    const [editingKey, setEditingKey] = useState('');

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            division: '',
            vendorCode: '',
            TCPLCFACode: '',
            RRLDCNo: '',
            customerCode: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                handleSave(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                handleSave(newData);
                setEditingKey('');
            }
        } catch (errInfo) { console.log('Validate Failed:', errInfo); }
    };

    const columns = [{
        title: 'Division',
        dataIndex: 'division',
        width: '20%'
    }, {
        title: 'Vendor Code',
        dataIndex: 'vendorCode',
        sorter: (a, b) => a.vendorCode - b.vendorCode,
        width: '20%'
    }, {
        title: 'TCPL CFA PLANT Code',
        dataIndex: 'TCPLCFACode',
        width: '20%'
    }, {
        title: 'RRL DC No',
        dataIndex: 'RRLDCNo',
        sorter: (a, b) => a.RRL - b.RRL,
        editable: true,
        fixed: 'right',
        width: '13%'
    }, {
        title: 'Customer Code',
        dataIndex: 'customerCode',
        sorter: (a, b) => a.customerCode - b.customerCode,
        editable: true,
        fixed: 'right',
        width: '13%'
    }, {
        title: 'Edit',
        dataIndex: 'operation',
        fixed: 'right',
        width: '8%',
        render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (<span>
                <CustomButton buttonLabel="Save" className="primaryBtn" handleSubmit={() => save(record.key)} />
            </span>)
                : (<Typography.Link disabled={editingKey !== ''}>
                    <CustomButton buttonLabel="Edit" className="secondaryBtn" handleSubmit={() => edit(record)} />
                </Typography.Link>);
        },
    }];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) { return col; }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'customerCode' ? 'selection' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table components={{ body: { cell: EditableCell } }} pagination={false}
                className="primary-color-table" rowClassName="editable-row" dataSource={data} columns={mergedColumns} />
            {/* sortUpIcon={<SwapLeftOutlined />} sortDownIcon={<SwapLeftOutlined />} sortNoneIcon={<SwapLeftOutlined />}  */}
        </Form>
    );
};

export default CustomEditableTable;

