import React from 'react';
import { Form, Input } from 'antd';
import './index.scss';

const { Search } = Input;

const CustomSearchBar = ({ label, placeholder, value, style, className, onChange, showTime, errorMsg, errorText, inputClassname, disable, onSearch }) => {
    const FormItem = Form.Item;

    return (<FormItem label={label} colon={false} className={className && className}>
        <Search placeholder="input search text" disabled={disable} placeholder={placeholder} onChange={onChange}
            onSearch={onSearch} enterButton value={value} className={inputClassname} />
        {errorMsg ? <p className="errorInputSearchStyles">{errorText}</p> : null}
    </FormItem>)
}

export default CustomSearchBar;