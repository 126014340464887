import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col, Spin, Table, Button, message } from 'antd';
import Dashboard from '../../dashboard';
import moment from 'moment';
import './index.scss';
import { queryAmendmentItems, queryInvoicePendingItems, queryEANFailedItems, queryCaseLotAmendItems, queryCompletePOEvents } from '../../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { getItems, getOnlyID, getStatus, getSystemSKUList } from '../../../utils/functions';
import OrdersActivityTable from '../../../utils/components/tables/orderAmemndament';
import EanLookUpTable from '../../../utils/components/tables/eanLookUp';
import PendingInvoicesTable from '../../../utils/components/tables/pendingInvoices';
import { BB_ORDERS_DASHBOARD, ORDERS_PODATA } from '../../../utils/routingUrl';
import {
    COMPONENTFROM_OPENINVOICE, COMPONENTFROM_OPENPOS, DATE_PICKER_YEAR_MONTH_DATE, KEY_AMENDMENT, KEY_ITEMAMEND,
    KEY_ITEMCASELOT, KEY_ITEMEAN, STATUS_CASELOT_AMENDMENT, STATUS_EAN_LOOPUP_FAILED, STATUS_INVOICE_PENDING, STATUS_PO_AMENDMENT,
    STATUS_INVOICE_AMENDMENT, HASH_KEY, NUMBER_ONE, NUMBER_TWO
} from '../../../utils/constants';
import CustomNavigator from '../../../utils/components/backToOrders';
import CustomButton from '../../../utils/components/buttons';
import POByID from '../../../utils/components/tables/searchPage/openPO';
import InvoiceByID from '../../../utils/components/tables/searchPage/invoice';
import { createEDIBigBasketPODetails, deliveryStatus, unblockMaterial, updateEDIBigBasketPODetails } from '../../../graphql/mutations';
import { listEDIBigBasketPODetails, } from '../../../graphql/queries';
import Axios from "../../../../node_modules/axios";
import { SALES_PERSON_KEY } from '../../../utils/constants/index';
const INVOICE = "Invoice";
const TYPE_INVOICE = 'InvoiceItem';


const BBOrdersActivity = (props) => {
    const location = useLocation();
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    console.log("userDetails", userDetails);
    const [activeStatus, setActiveStatus] = useState("");
    const [orderDetails, setOrderDetails] = useState({
        PO: "1113456678",
        SO: "1233789556",
        Status: "In Progress"
    });
    const tempData = [
        {
            POMAT: "605537#14000000003808",
            DATE: "04/04/2022, 14:57:48",
            DESCRIPTION: "Himalayan Nat Mineral Water 1 L Bottle",
            SHIP_TO: "BB000001",
            SKU_CODE: "253557",
            SO: "12345",
            STATUS: "Blocked",

        }
    ]
    // const tempPayload = {
    //     "SO_Number" : "0110273371",
    //     "NAVITEM" : {
    //       "results" : [
    //         {
    //           "SO_Number" : "0110273371",
    //           "Item_Number" : "000010",
    //           "Material_Number" : "000014000000000673",
    //           "MRP" : "200"
    //         }
    //       ]
    //     }
    //   }
    const [allDetails, setAllDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(1);
    const history = useHistory();
    const [nextToken, setNextToken] = useState(null)
    const [details, setDetails] = useState({});

    const [startDateTime, setStartDate] = useState();
    const [endDateTime, setEndDate] = useState();
    const [updatedMRP, setUpdatedMRP] = useState("Hello");
    const [deliveryStatusArray, setDeliveryStatusArray] = useState([]);

    const columns = [
        // {
        //     title: "MAT. ID.",
        //     dataIndex: 'POMAT',
        //     key: 'POMAT',
        // }, 
        {
            title: 'CASES',
            dataIndex: 'CASES',
            key: 'CASES',
        }, {
            title: 'DATE',
            dataIndex: 'DATE',
            key: 'DATE',
        }, {
            title: 'DESCRIPTION',
            dataIndex: 'DESCRIPTION',
            key: 'DESCRIPTION',
            width: '25%'
        }, {
            title: 'EAN',
            dataIndex: 'EAN',
            key: 'EAN',
        }, {
            title: 'HSN CODE',
            dataIndex: 'HSN_CODE',
            key: 'HSN_CODE',
        }, {
            title: 'MAT',
            dataIndex: 'MAT',
            key: 'MAT',
        }, {
            title: 'PO',
            dataIndex: 'PO',
            key: 'PO',
        }, {
            title: 'QTY',
            dataIndex: 'QTY',
            key: 'QTY',
        }, {
            title: 'SHIP CODE',
            dataIndex: 'SHIP_CODE',
            key: 'SHIP_CODE',
        }, {
            title: 'SHIP_FROM',
            dataIndex: 'SHIP_FROM',
            key: 'SHIP_FROM',
        }, {
            title: 'SHIP TO',
            dataIndex: 'SHIP_TO',
            key: 'SHIP_TO',
        }, {
            title: 'SKU CODE',
            dataIndex: 'SKU_CODE',
            key: 'SKU_CODE',
        }, {
            title: 'SO',
            dataIndex: 'SO',
            key: 'SO',
        }, {
            title: 'TOTAL AMOUNT',
            dataIndex: 'TOTAL_AMOUNT',
            key: 'TOTAL_AMOUNT',
        }, {
            title: <p className="margin0">BASIC COST </p>,
            dataIndex: 'BASIC_COST',
            key: 'BASIC_COST',
            width: '10%',
            className: "orderActivityTable_first orderActivity_FixedRight"
        }, {
            title: <p className="margin0">MRP</p>,
            dataIndex: 'MRP',
            key: 'MRP',
            width: '10%',
            className: "orderActivityTable_second orderActivity_FixedRight"
        }, {
            title: <p className="margin0">CURRENT MRP</p>,
            dataIndex: 'UPD_MRP',
            key: 'UPD_MRP',
            width: '10%',
            className: "orderActivityTable_third orderActivity_FixedRight"
        }, {
            title: <p className="margin0">STATUS</p>,
            dataIndex: 'STATUS',
            key: 'STATUS',
            width: '10%',
            className: "orderActivityTable_fourth orderActivity_FixedRight"
        }, {
            title: <p className="margin0">ACTIONS </p>,
            dataIndex: 'UNBLOCK_BUTTON',
            key: 'UNBLOCK_BUTTON',
            width: '10%',
            className: "orderActivityTable_fifth orderActivity_FixedRight"
        },]

    const [tableData, setTableData] = useState();

    useEffect(() => {
        // const { sk, pk, itemID, currentTab, startDateTime, endDateTime } = location.state;
        // if (activeStatus === STATUS_PO_AMENDMENT || activeStatus === STATUS_EAN_LOOPUP_FAILED || activeStatus === STATUS_CASELOT_AMENDMENT) getDetails(currentTab, sk, pk, itemID);
        // if (activeStatus === STATUS_INVOICE_PENDING) getDetails(currentTab, "Item#", pk);
        // setCurrentTab(currentTab);
        // setStartDate(startDateTime);
        // setEndDate(endDateTime);
        // setDetails({ activeStatus, sk, pk, itemID })
        setLoading(false)
    }, [location])

    useEffect(() => {
        // if (nextToken !== null) {
        //     const { sk, pk, itemID } = location.state;
        //     if (activeStatus === STATUS_PO_AMENDMENT || activeStatus === STATUS_EAN_LOOPUP_FAILED || activeStatus === STATUS_CASELOT_AMENDMENT) getDetails(sk, pk, itemID);
        //     if (activeStatus === STATUS_INVOICE_PENDING) getDetails("Item#", pk);
        // }
        if (deliveryStatusArray && deliveryStatusArray.length > 0) {
            setTableRowData(tableData);
        }
    }, [])

    useEffect(() => {
        console.log(props)
        let PONumber, SONumber;

        if (props) {
            if (props.PODetails) {
                PONumber = props?.PODetails?.PO;
                //SONumber = props.PODetails.SO;
            }
        }
        setLoading(true);
        fetchPODetails(PONumber);

        //fetchPODetails(tempData);
        //unblockMaterial();
    }, [])

    // useEffect(async () => {
    //     //tempCreate(tempData);
    //       //fetchPOData();
    //       //setTableRowData(tempData);
    //       unblockMaterials(tempPayload);

    //   },[tableData])



    const getDeliveryStatus = async (SO) => {
        try {
            console.log("SO", SO);
            const res = await API.graphql({
                query: deliveryStatus,
                variables: { PONUMBER: SO + "" },
            });
            //console.log(JSON.parse(res.data.DeliveryStatus.d));
            if (res) {
                let response = JSON.parse(res.data.DeliveryStatus);
                //console.log("delivery status response", response.d.NAVITEM.results) 
                setDeliveryStatusArray(response.d.NAVITEM.results);
                return response.d.NAVITEM.results;
                //setTableRowData(tableData, response.d.NAVITEM.results)
                //setTimeout(() => {console.log(">>>>>",deliveryStatusArray);}, 6000) 
            }

        } catch (error) {

        }

    }

    const unblockMaterials = async (data) => {
        if (userDetails.GSI1PK == SALES_PERSON_KEY) {
            let so = props.PODetails.SO;
            let temp = data;
            const tempPayload = {
                "SO_Number": `${temp.SO_Number}`,
                "NAVITEM": {
                    "results": [
                        {
                            "SO_Number": `${temp.SO_Number}`,
                            "Item_Number": `${temp.Item_Number}`,
                            "Material_Number": `${temp.Material_Number}`,
                            "MRP": `${temp.MRP}`
                        }
                    ]
                }
            }

            try {
                let pay = JSON.stringify(tempPayload);
                console.log("payload string", pay);
                const res = await API.graphql({
                    query: unblockMaterial,
                    variables: { payload: pay },
                });
                //console.log(JSON.parse(res.data.UnblockMaterial));
                if (res) {
                    let response = JSON.parse(res.data.UnblockMaterial);
                    console.log(response);
                    message.error(response?.d?.NAVITEM?.results[0]?.Status)
                }

            } catch (error) {
                message.error("Something went wrong")
            }
        }
        else{
            message.error('User does not have required permissions to unblock material')
        }

    }


    async function fetchPODetails(PO) {
        try {
            setLoading(true);
            let filter = {};
            filter.PO = { eq: PO }
            let res = await API.graphql(graphqlOperation(listEDIBigBasketPODetails, { filter: filter }));
            console.log(res.data);
            if (res) {
                if (res.data.listEDIBigBasketPODetails.items) {
                    let tempData = res.data.listEDIBigBasketPODetails.items;
                    let deliveryData = await getDeliveryStatus(props?.PODetails?.SO);
                    //console.log("delivery array", deliveryData);
                    setTableRowData(tempData, deliveryData);
                    //setTableData(tempData);
                    setLoading(false); 
                }
            }
        }
        catch (e) {
            message.error(`${e}`)
        }
    }

    const setTableRowData = (data, delData) => {
        // Mapping Data to the table
        let dataMapper = [];

        if (data && data.length > 0 && delData && delData.length > 0) {
            var obj = {};
            data.map((itemData, i) => {
                delData.map((arrayData, j) => {
                    if (parseInt(itemData.MAT) == parseInt(arrayData.Material_Number)) {
                        console.log("arrayData", arrayData);
                        obj = {
                            key: i + "-" + j,
                            BASIC_COST: itemData.BASIC_COST,
                            CASES: itemData.CASES,
                            DATE: itemData.DATE,
                            DESCRIPTION: itemData.DESCRIPTION,
                            EAN: itemData.EAN,
                            HSN_CODE: itemData.HSN_CODE,
                            MAT: itemData.MAT,
                            MRP: itemData.MRP,
                            PO: itemData.PO,
                            QTY: itemData.QTY,
                            SHIP_CODE: itemData.SHIP_CODE,
                            SHIP_FROM: itemData.SHIP_FROM,
                            SHIP_TO: itemData.SHIP_TO,
                            SKU_CODE: itemData.SKU_CODE,
                            SO: itemData.SO,
                            STATUS: arrayData.Status != "Delivery Completed" ? "Blocked" : "Succcess",
                            TOTAL_AMOUNT: itemData.TOTAL_AMOUNT,
                            UPD_MRP: arrayData.MRP,
                            UNBLOCK_BUTTON: arrayData.Status != "Delivery Completed" ? userDetails.GSI1PK == SALES_PERSON_KEY ? <CustomButton buttonLabel="Unblock" className="regularSize" handleSubmit={() => unblockMaterials(arrayData)} /> : <CustomButton buttonLabel="Unblock" className="regularSize" handleSubmit={() => unblockMaterials(arrayData)} disabled/>  : "Success"
                        }
                    }
                });
                dataMapper.push(obj);
            })
        }
        setTableData(dataMapper)
    }
    // Fetching Data Based on Status
    async function getDetails(currentTab, sk, pk, itemID) {
        try {
            if (activeStatus === STATUS_PO_AMENDMENT) {
                if (currentTab === NUMBER_TWO) {
                    //PO Amendment
                    const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK: pk, SK: sk, Type: sk, itemIds: itemID } });
                    let obj = {}
                    obj.PassedItems = apiData.data.queryCompletePOEvents.body.PassedItems
                    obj.FailedItems = apiData.data.queryCompletePOEvents.body.AmendmentItems
                    setAllDetails(obj);

                    setLoading(false);
                } else if (currentTab === NUMBER_ONE) {
                    const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK: pk, SK: sk, Type: sk, itemIds: itemID, PassedItemsSK: TYPE_INVOICE + HASH_KEY + getItems(pk, 3) } });
                    let obj = {}
                    obj.PassedItems = apiData.data.queryCompletePOEvents.body.PassedInvoiceItems;
                    obj.FailedItems = apiData.data.queryCompletePOEvents.body.AmendmentItems;
                    setAllDetails(obj);
                    setLoading(false);
                }
            } else if (activeStatus === STATUS_INVOICE_PENDING) {
                const apiData = await API.graphql({ query: queryInvoicePendingItems, variables: { PK: pk, SK: sk, first: 100, after: nextToken } });
                setAllDetails([...allDetails, ...apiData.data.queryInvoicePendingItems.items]);
                if (apiData.data.queryInvoicePendingItems.nextToken != null) setNextToken(apiData.data.queryInvoicePendingItems.nextToken);
                else setNextToken(null);
                setLoading(false);
            } else if (activeStatus === STATUS_EAN_LOOPUP_FAILED) {
                const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK: pk, SK: sk, Type: sk, itemIds: itemID } });
                setAllDetails(apiData.data.queryCompletePOEvents.body);

                // const apiData = await API.graphql({ query: queryEANFailedItems, variables: { PK: pk, SK: sk, itemIds: itemID, first: 100, after: nextToken } });
                // setAllDetails([...allDetails, ...apiData.data.queryEANFailedItems.items]);
                // if (apiData.data.queryEANFailedItems.nextToken != null) setNextToken(apiData.data.queryEANFailedItems.nextToken);
                // else setNextToken(null);
                setLoading(false);
            } else if (activeStatus === STATUS_CASELOT_AMENDMENT) {
                const apiData = await API.graphql({ query: queryCompletePOEvents, variables: { PK: pk, SK: sk, Type: sk, itemIds: itemID } });
                let obj = {}
                obj.PassedItems = apiData.data.queryCompletePOEvents.body.PassedItems
                obj.FailedItems = apiData.data.queryCompletePOEvents.body.CaseLotAmendmentItems
                setAllDetails(obj);

                // const apiData = await API.graphql({ query: queryCaseLotAmendItems, variables: { PK: pk, SK: sk, itemIds: itemID, first: 100, after: nextToken } });
                // setAllDetails([...allDetails, ...apiData.data.queryCaseLotAmendItems.items]);
                // if (apiData.data.queryCaseLotAmendItems.nextToken != null) setNextToken(apiData.data.queryCaseLotAmendItems.nextToken);
                // else setNextToken(null);
                setLoading(false);
            }
        } catch (error) { console.log('error occured while fetching the data ', error); }
    }

    const navTOOrders = () => {
        //history.push({ pathname: BB_ORDERS_DASHBOARD, state: { currentTab, startDateTime, endDateTime } }) 
        setTableData([]);
        props.backToPOTable();
    }
    const navTOPOData = () => {
        if (orderDetails.Type === INVOICE) {
            var splitData = orderDetails.PK.split('#');
            history.push({ pathname: ORDERS_PODATA, state: { PK: splitData[0] + '#' + splitData[1], SK: splitData[2] + '#' + splitData[3], currentTab, componentFrom: COMPONENTFROM_OPENINVOICE, componentDetails: details, componentOrderDetails: orderDetails, startDateTime, endDateTime } })
        } else history.push({ pathname: ORDERS_PODATA, state: { PK: orderDetails.PK, SK: orderDetails.SK, currentTab, componentFrom: COMPONENTFROM_OPENPOS, componentDetails: details, componentOrderDetails: orderDetails, startDateTime, endDateTime } })
    }
    const keyName = 'Item Details';
    const nameChange = (e) => { }

    return (
        <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <div className="displaySpacebetween marginBottomRg">
                        <h2 className="heading18Bold margin0">{"PO Details"}</h2>
                        {/* <CustomButton handleSubmit={navTOOrders} buttonLabel="Back To Orders" className="regularSize" /> */}
                        <CustomNavigator onChange={navTOOrders} />
                    </div>
                    <div className="detailsInline">
                        <p className="sub14regular"><span className="sub14Bold">Status : </span> {props.PODetails ? props.PODetails.Status : ''}</p>
                        {/* <p className="sub14regular"><span className="sub14Bold"> {activeStatus === KEY_AMENDMENT ? "Invoice ID :" : ""}</span> {activeStatus === KEY_AMENDMENT ? getOnlyID(orderDetails.PK) : ""}</p> */}
                        {/* {orderDetails.Status ? <p className="sub14regular"><span className="sub14Bold">{INVOICE} ID : </span>{orderDetails.SO}</p> : ''} */}
                    </div>
                    <div className="detailsInline">
                        {/* <p className="sub14regular"><span className="sub14Bold"> PO ID : </span>{activeStatus === KEY_AMENDMENT ? getOnlyID(orderDetails.PK) : getOnlyID(orderDetails.SK)}</p> */}
                        <p className="sub14regular"><span className="sub14Bold"> PO ID : </span><span className="cursorpointer colorBlue">{props.PODetails ? props.PODetails.PO : ''}</span></p>
                        <p className="sub14regular"><span className="sub14Bold">Created Date :</span> {` ${moment(orderDetails.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}`}</p>
                    </div>

                    {orderDetails.Status ?
                        <>{!loading ?
                            <>
                                <Table dataSource={tableData} columns={columns} className="primary-color-table master masterDataMappingTable" pagination={true} scroll={{ x: 1000 }} />
                            </> :
                            <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>}
                        </>
                        : ""}

                </Col>
            </Row>
        </Dashboard>
    );
}

export default BBOrdersActivity;
