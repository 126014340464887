import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, message } from 'antd';
import Dashboard from '../dashboard';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { DATE_PICKER_TIME_ZONE_Z, GTIN_KEY, HASH_KEY, SALES_PERSON_KEY, YES_KEY } from '../../utils/constants';
import { PRODUCT_DATA_CREATE } from '../../utils/routingUrl';
import CustomSearchBar from '../../utils/components/customeInputFields/searchField';
import { queryProductMasterData } from '../../graphql/queries';
import { API } from 'aws-amplify';
import ProductTable from '../../utils/components/tables/productTable';
import CustomPaginationWithPages from '../../utils/components/pagination/index1';
import { productMapping } from '../../graphql/mutations';
import ProductEditableTable from '../../utils/components/tables/editableTable/productEditableTable';
import DeleteModal from '../../utils/components/modal/deleteModal';
const NUMBER_ONE = 1;
const NUMBER_TEN = 10;

const ProductDataDashboard = (props) => {
  const history = useHistory();
  const inititalPginationState = {
    noOfRecordsPerPage: NUMBER_TEN,
    nextBtn: true,
    previousBtn: true,
    fromCount: NUMBER_ONE,
    toCount: NUMBER_TEN,
    showItems: []
  }
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const companySKSelected = sessionStorage.getItem('companySK');
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const [modalVisible, setModalVisible] = useState(false);
  const [deletedData, setDeletedData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);

  // pagination initial state
  const [paginationData, setPaginationData] = useState(inititalPginationState);
  const { noOfRecordsPerPage, nextBtn, previousBtn, fromCount, toCount, showItems } = paginationData;

  useEffect(() => { getProductData(); }, [])

  const getProductData = async () => {
    try {
      setLoading(true);
      const apiData = await API.graphql({
        query: queryProductMasterData,
        variables: { CustomerID: companySKSelected, Type: "GET_PRODUCT_MAPPING" }
      });
      var data = apiData.data.queryProductMasterData;
      setProductData(data.body);
      if (data.body && data.body.length) {
        var array = [10, 20, 50, 100], optionArray = [];
        array.map(item => {
          if (data.body.length > item) {
            optionArray.push(item);
            setPaginationData(state => ({ ...state, nextBtn: false }))
          }
        })
        setPaginationData(state => ({ ...state, showItems: optionArray }))
      }
      getTableData(data.body, 0, noOfRecordsPerPage);
    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
  }

  const getTableData = (data, fromRecord, toRecord) => {
    let slicedData = data.slice(fromRecord, toRecord);
    setTableData(slicedData);
  }

  const noOfRecordsPerPageChanged = (number) => {
    setPaginationData(state => ({ ...state, noOfRecordsPerPage: number, toCount: number, fromCount: NUMBER_ONE, previousBtn: true, nextBtn: false }))
    getTableData(productData, 0, number)
  }

  // to detch next Data
  const onNext = () => {
    var fromCount1 = toCount + NUMBER_ONE;
    var toCount1 = toCount + noOfRecordsPerPage;
    if (productData.length > toCount1) {
      setPaginationData(state => ({ ...state, fromCount: fromCount1, toCount: toCount1, previousBtn: false }))
      getTableData(productData, toCount, toCount1);
    } else if (productData.length >= fromCount1 && productData.length <= toCount1) {
      setPaginationData(state => ({ ...state, fromCount: fromCount1, toCount: toCount1, previousBtn: false, nextBtn: true }))
      getTableData(productData, toCount, toCount1);
    }
  }

  // after click on Previous icon
  const onPre = () => {
    var fromCount1 = fromCount - noOfRecordsPerPage;
    var toCount1 = fromCount - 1;
    if (fromCount1 != NUMBER_ONE) {
      setPaginationData(state => ({ ...state, fromCount: fromCount1, toCount: toCount1, nextBtn: false }))
      getTableData(productData, fromCount1 - 1, toCount1)
    } else if (fromCount1 == NUMBER_ONE) {
      setPaginationData(state => ({ ...state, fromCount: fromCount1, toCount: toCount1, nextBtn: false, previousBtn: true }))
      getTableData(productData, fromCount1 - 1, toCount1)
    }
  }

  const handleValueChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setPaginationData(state => ({ ...state, noOfRecordsPerPage: NUMBER_TEN, nextBtn: false, previousBtn: true, fromCount: NUMBER_ONE, toCount: NUMBER_TEN }))
      getProductData();
    }
  }

  // fetching Data while click on search
  const onSearch = async () => {
    setSearchValue(searchValue);
    const apiData = await API.graphql({
      query: productMapping, variables: {
        Type: "GET_PRODUCT_MAP",
        ProductIds: { CustomerProductID: companySKSelected + HASH_KEY, GTIN: GTIN_KEY + HASH_KEY + searchValue.trim() }
      }
    })
    var data = apiData.data.productMapping.body;
    if (data != null) { setProductData([data]); setTableData([data]); }
    else { setProductData([]); setTableData([]); }
  }

  const createProductData = () => { history.push(PRODUCT_DATA_CREATE) }

  const updateProductData = (data) => {
    setProductData(data);
    setPaginationData(state => ({ ...state, nextBtn: false, previousBtn: true, fromCount: NUMBER_ONE, toCount: NUMBER_TEN }))
    getTableData(data, 0, noOfRecordsPerPage);
  }

  const handleSave = (tableData) => {
    setUpdatedData(tableData);
    getRRLArticles(tableData);
  }

  const getRRLArticles = async (data) => {
    if (data) {
      const { RRLArticle, GTIN, RRLArticleDesc } = data;
      var PKValue = GTIN_KEY + HASH_KEY + GTIN.props.children, SKValue = companySKSelected + HASH_KEY + RRLArticle, obj = {};
      const apiData = await API.graphql({
        query: productMapping, variables: {
          Type: "GET_PRODUCT_MAP",
          ProductIds: { CustomerProductID: companySKSelected + HASH_KEY + RRLArticle }
        }
      })
      var data1 = apiData.data.productMapping.body;
      if (data1 != null) {
        const { SKUMapping: { PK, GSI2 }, CustomerProduct: { SK, RRLArticleDescription } } = data1;
        if (PK != PKValue && SK === SKValue) {
          obj = { PK: GSI2, SK: RRLArticle };
          setDeletedData(obj);
          setModalVisible(true);
        } else if (PK === PKValue && SK === SKValue && RRLArticleDesc === RRLArticleDescription) {
          message.success(`The RRL your enter ${RRLArticle} is mapped with GTIN ${GSI2}`);
        } else { handleOK(data); }
      } else { handleOK(data); }
    }
  }

  const handleOK = async (data) => {
    if (updatedData) {
      const { RRL, RRLArticle, RRLArticleDesc, GTIN } = data ? data : updatedData;
      var PKValue = GTIN_KEY + HASH_KEY + GTIN.props.children, SKValue = companySKSelected + HASH_KEY + RRLArticle;
      var body = {
        Type: "PRODUCT_MAPPING",
        MapProduct: {
          GSI1PK: companySKSelected + HASH_KEY + "PRODUCT", GSI1SK: moment.utc(new Date()).format(DATE_PICKER_TIME_ZONE_Z), GSI2: RRLArticle,
          IsActive: RRL === YES_KEY ? true : false, PK: PKValue, RRLArticleDescription: RRLArticleDesc, SK: SKValue
        }
      }
      if (modalVisible) {
        const { PK, SK } = deletedData;
        var obj;
        tableData && tableData.map(item => {
          if (item.SKUMapping.PK === PKValue) { obj = { PK: PKValue, SK: item.CustomerProduct.SK } }
        })
        body.DeleteMap = [{ PK: GTIN_KEY + HASH_KEY + PK, SK: companySKSelected + HASH_KEY + SK }, obj];
      }
      try {
        const apiData = await API.graphql({ query: productMapping, variables: body });
        message.success(`The RRL your enter ${RRLArticle} is mapped with GTIN ${GTIN.props.children}`);
        setModalVisible(false);
        getProductData();
      } catch (error) { message.error('Invalid Data'); }
    }
  }
  const handleCancel = () => { setModalVisible(false); getProductData(); }

  return (
    <Dashboard>
      <Row>
        <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
          <Row className="displayFlex justifyContentCenter alignItemsCenter">
            <Col lgmd={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="tableTitle paddingTop10Bottom20">
              <h2 className="margin0">Product Master Data </h2>
            </Col>
            <Col lgmd={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="create-user paddingTop10Bottom20">
              <div className="displayFlex justifyContentFlexEnd">
                <CustomSearchBar placeholder="Search by GTIN" className={"width200"} value={searchValue}
                  inputClassname="Search-main" onChange={(e) => { handleValueChange(e) }} onSearch={onSearch} />
                {/* <div className="paddingLeft20">
                  <CustomButton buttonLabel="Add New" className="createUserBtn alignItemsRight" handleSubmit={createProductData} />
                </div> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: '24' }}>
              <>{!loading ? <>
                {userDetails.GSI1PK == SALES_PERSON_KEY ? <ProductTable data={tableData} productData={productData} updateProductData={updateProductData} /> :
                  <ProductEditableTable data={tableData} handleSave={handleSave} productData={productData} updateProductData={updateProductData} />}
                {tableData && tableData.length && searchValue === "" ? <CustomPaginationWithPages onPre={() => onPre()} onNext={() => onNext()} noOfRecordsPerPageChanged={(e) => noOfRecordsPerPageChanged(e)}
                  from={fromCount} to={toCount} nextBtn={nextBtn} previousBtn={previousBtn} noOfRecordsPerPage={noOfRecordsPerPage} showItems={showItems} dataLength={productData.length} /> : null}
              </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>} </>
            </Col>
          </Row>

        </Col>
      </Row>
      <DeleteModal message={`The RRL your enters "${deletedData.SK}" is mapped with other GTIN "${deletedData.PK}".  Are you sure you want to unmap the old Record?`}
        handleCancel={handleCancel} handleOK={handleOK} modalVisible={modalVisible} />
    </Dashboard>
  );
}

export default ProductDataDashboard;
