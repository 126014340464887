import React, { useEffect, useState } from 'react';
import { Card, message } from 'antd';
import './index.scss';
import Dashboard from '../dashboard'
import { useHistory } from 'react-router-dom';
import { COMAPNIES_DASHBOARD, ORDERS_DASHBOARD, USERS_DASHBOARD, BB_ORDERS_DASHBOARD, BB_USERS_DASHBOARD } from '../../utils/routingUrl';
import { listCompanies, queryUserRoles } from '../../graphql/queries';
import { API } from 'aws-amplify';
import { CUST_ADMIN_KEY, SALES_PERSON_KEY, SUB_ADMIN_KEY, SUPER_ADMIN_KEY, TRUE_CONDITION, SO_MAIL_RECIPIENTS_KEY, ERROR_MAIL_RECIPIENTS_KEY, NOTIFICATION_KEY , BIGBASKET } from '../../utils/constants';

const HomePage = () => {
    const history = useHistory();
    const [allCompanies, setAllCompanies] = useState([]);
    const email = sessionStorage.getItem("email");
    const [userDetails, setUserDetails] = useState({
        CompanyName: '',
        GSI1PK: '',
        GSI1SK: '',
        GSI2: '',
        IsActive: '',
        PK: '',
        SK: '',
        companiesList: []
    })

    const navToOrders = (e) => {
        console.log(e);
        sessionStorage.setItem('companyName', e.CompanyName);
        sessionStorage.setItem('companySK', e.SK);
        sessionStorage.setItem('companiesList', JSON.stringify(allCompanies));

        if (e.Type == "Company") {
            sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
            history.push({ pathname: COMAPNIES_DASHBOARD })
        } else {
            let details = {
                CompanyName: e.CompanyName,
                GSI1PK: e.GSI1PK,
                GSI1SK: e.GSI1SK,
                GSI2: e.GSI2,
                IsActive: e.IsActive,
                PK: e.PK,
                SK: e.SK,
                companiesList: userDetails.companiesList
            }
            sessionStorage.setItem('userDetails', JSON.stringify(details));
            if (e.GSI1PK === CUST_ADMIN_KEY || e.GSI1PK === SUB_ADMIN_KEY) { 
                if(e.CompanyName == BIGBASKET) history.push({ pathname: BB_USERS_DASHBOARD })
                else history.push({ pathname: USERS_DASHBOARD })
                 
            }
            else if (e.GSI1PK === SALES_PERSON_KEY){
              if (e.CompanyName == BIGBASKET)  history.push({ pathname: BB_ORDERS_DASHBOARD })
              else history.push({ pathname: ORDERS_DASHBOARD })
            } 
            else message.error('Something went wrong')
        }
    }

    useEffect(() => { getUserRoles() }, []);

    const getUserRoles = async () => {
        const apiData1 = await API.graphql({ query: queryUserRoles, variables: { PK: email } });
        var data = apiData1.data.queryUserRoles.items;
        if (data && data.length > 0) {
            let updatedData = [], count = 0;
            data.map(item => {
                if (item.Type != NOTIFICATION_KEY) {
                    updatedData.push(item);
                    if (item.GSI1PK === SUPER_ADMIN_KEY) count++;
                }
            })

            if (count > 0) {
                getCompaniesList();
                updatedData.map(item => {
                    if (item.GSI1PK === SUPER_ADMIN_KEY)
                        setUserDetails(state => ({
                            ...state, CompanyName: item.CompanyName,
                            GSI1PK: item.GSI1PK,
                            GSI1SK: item.GSI1SK,
                            GSI2: item.GSI2,
                            IsActive: item.IsActive,
                            PK: item.PK,
                            SK: item.SK
                        }));
                });
            } else {
                setAllCompanies(updatedData);
                setUserDetails(state => ({ ...state, companiesList: updatedData }));
            }
        }
    }

    const getCompaniesList = async () => {
        const apiData = await API.graphql({ query: listCompanies, variables: { PK: 'Company', first: 50, after: null } });
        setUserDetails(state => ({ ...state, companiesList: apiData.data.listCompanies.items }))
        setAllCompanies(apiData.data.listCompanies.items);
    }

    return (
        <Dashboard>
            <div className="companies-li">
                <Card title="Customers" className="card-li borderRadius5rem">
                    <p className="selectCompany">Select a customer</p>
                    {allCompanies && allCompanies.map((company, i) =>
                        company.IsActive === TRUE_CONDITION || company.IsActive ?
                            <div className={"companyDiv cursorpointer"} onClick={e => navToOrders(company)} key={i}>
                                <h2>{company.CompanyName}</h2>
                            </div> : null
                    )}
                </Card>
            </div>
        </Dashboard>
    )
}

export default HomePage;