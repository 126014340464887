import React from 'react'
import { Modal } from 'antd';
import './index.scss';
import CustomDatePicker from '../../customeInputFields/datePicker';
import CustomButton from '../../buttons';

const AsnDateModal = ({ isVisible, onCancel, onSubmit }) => {

    const handleOk = () => { onCancel(); };
    const handleCancel = () => { onCancel(); };

    return (
        <Modal visible={isVisible} onOk={handleOk} onCancel={handleCancel} footer={null} width={'60vh'} centered>
            <div className="asnData">
                <p>Enter ASN Date</p>
                <CustomDatePicker />
            </div>
            <div className="buttonsInline">
                <CustomButton buttonLabel="Cancel" />
                <CustomButton buttonLabel="Submit" />
            </div>
        </Modal>
    )
}

export default AsnDateModal;
