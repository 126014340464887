import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, HttpLink, InMemoryCache } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'antd/dist/antd.css';
import './globalStyles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ApolloClient from 'apollo-client';
const cache = new InMemoryCache();
const link = new HttpLink({ uri: '' })
const client = new ApolloClient({ cache, link });

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
