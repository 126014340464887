import React from 'react';
import { Input, Form } from 'antd';
import "./index.scss";

const CustomInput = ({ label, placeholder, errorText, value, onChange, style, className, maxLength = "", disabled, inputClassname, name, prefix, onMouseLeave, onBlur, type, onKeyPress, onKeyDown, suffix, readOnlyCondition }) => {
    const FormItem = Form.Item;
    return (<FormItem label={label} colon={false} className={className}>
        <Input placeholder={placeholder} value={value} onKeyPress={onKeyPress} style={style} onChange={onChange} type={type} onKeyDown={onKeyDown} onMouseLeave={onMouseLeave}
            maxLength={maxLength} className={inputClassname} prefix={prefix} name={name} autoComplete="off" disabled={disabled} onBlur={onBlur} readOnly={readOnlyCondition} />
        {suffix === undefined || (suffix !== undefined && errorText) ? <p className="errorInputSearchStyles">{errorText}</p> : null}
    </FormItem>)
}

export default CustomInput;