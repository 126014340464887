import React, { useEffect, useState } from 'react';
import { Row, Col, message, Select } from 'antd';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import Dashboard from '../../../dashboard';
import CustomeButton from '../../../../utils/components/buttons'
import DropDownSelect from '../../../../utils/components/customeInputFields/dropDownSelect';
import CustomInput from '../../../../utils/components/customeInputFields/input';
// import './index.scss';
import { productMapping } from '../../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { PRODUCT_DATA_DASHBOARD, PRODUCT_DATA_EDIT, USERS_DASHBOARD, USERS_EDIT } from '../../../../utils/routingUrl';
import { DATE_PICKER_TIME_ZONE_Z, NO_KEY, TRUE_CONDITION, YES_KEY } from '../../../../utils/constants';
import CustomSearchBar from '../../../../utils/components/customeInputFields/searchField';
const Option = Select.Option;

const CreateProductData = (props) => {
    const history = useHistory();
    const location = useLocation();
    const companySKSelected = sessionStorage.getItem('companySK');

    const [state, setState] = useState({
        RRLArticle: '',
        GTIN: '',
        hanaProdDesc: '',
        hanaParentCode: '',
        hanaCode: '',
        RRL: '',
        RRLArticleDescription: '',
        errors: {},
        deletedMap: []
    });
    const { RRLArticle, GTIN, hanaProdDesc, hanaParentCode, hanaCode, RRL, RRLArticleDescription, deletedMap, errors } = state;

    useEffect(() => {
        if (location.state) {
            const { data: { CustomerProduct, SKUMapping } } = location.state;
            setState(state => ({
                ...state, GTIN: SKUMapping.GSI2, hanaProdDesc: SKUMapping.HANAProductDescription,
                hanaParentCode: SKUMapping.S4HANAParentCode, hanaCode: SKUMapping.S4HANACode,
                RRL: CustomerProduct.IsActive || CustomerProduct.IsActive === TRUE_CONDITION ? YES_KEY : NO_KEY,
                RRLArticle: CustomerProduct.GSI2, RRLArticleDescription: CustomerProduct.RRLArticleDescription
            }))
        }
    }, [location])

    const handleValueChange = (info, e) => {
        if (e !== "") errors[info] = "";
        if (info === 'RRL') setState(state => ({ ...state, [info]: e }))
        else setState(state => ({ ...state, [info]: e.target.value }))
        if (info === 'GTIN') { setState(state => ({ ...state, hanaProdDesc: '', hanaParentCode: '', hanaCode: '', errors: { ...errors, GTIN: '' } })) }
        if (info === 'RRLArticle') { setState(state => ({ ...state, RRLArticleDescription: '', RRL: '', errors: { ...errors, RRLArticle: '' } })) }
    }

    const handleKeyPress = async (e) => {
        const apiData = await API.graphql({ query: productMapping, variables: { Type: "GET_PRODUCT_MAP", ProductIds: { CustomerProductID: companySKSelected + "#" + RRLArticle } } })
        var data = apiData.data.productMapping.body;
        if (data != null) {
            const { CustomerProduct: { RRLArticleDescription, IsActive } } = data;
            setState(state => ({
                ...state, RRLArticleDescription, RRL: IsActive ? YES_KEY : NO_KEY,
                errors: { ...errors, RRLArticle: 'This RRL article already mapped with other GTIN' },
                deletedMap: [data]
            }))
        }
    }

    // fetching Data while click on search
    const onSearch = async () => {
        const apiData = await API.graphql({ query: productMapping, variables: { Type: "SEARCH_GTIN", SearchID: GTIN } })
        var data = apiData.data.productMapping.body.GTINList;
        data && data.length > 0 && data.map(item => {
            setState(state => ({
                ...state, hanaProdDesc: item.HANAProductDescription, hanaParentCode: item.S4HANAParentCode, hanaCode: item.S4HANACode,
                errors: { ...errors, GTIN: 'This GTIN already mapped with other RRL Code' }
            }))
        })
    }

    const handleSubmit = async () => {
        let errors = {};
        if (RRLArticle === "" || RRLArticle === undefined) errors.RRLArticle = "Please enter a RRL article";
        if (GTIN === "" || GTIN === undefined) errors.GTIN = "Please enter a GTIN";
        if (hanaProdDesc === "" || hanaProdDesc === undefined) errors.hanaProdDesc = "Please enter a hana prod description";
        if (hanaParentCode === "" || hanaParentCode === undefined) errors.hanaParentCode = "Please enter a hana parent code";
        if (hanaCode === "" || hanaCode === undefined) errors.hanaCode = "Please enter a hana code";
        if (RRL === "" || RRL === undefined) errors.RRL = "Please select a RRL";
        if (RRLArticleDescription === "" || RRLArticleDescription === undefined) errors.RRLArticleDescription = "Please enter a RRL article description";

        setState(state => ({ ...state, errors }));
        if (Object.keys(errors).length === 0) {
            var body = {
                Type: "PRODUCT_MAPPING",
                MapProduct: {
                    GSI1PK: "Rel#PRODUCT", GSI1SK: moment.utc(new Date()).format(DATE_PICKER_TIME_ZONE_Z),
                    GSI2: RRLArticle, IsActive: RRL === YES_KEY ? true : false, PK: "GTIN#" + GTIN, RRLArticleDescription, SK: companySKSelected + "#" + RRLArticle
                },
                DeleteMap: [{
                    "PK": "GTIN#8904043901039",
                    "SK": "Rel#491188727"
                }]
            }
            try {
                // if (window.location.pathname === PRODUCT_DATA_EDIT) {
                //     const apiData = await API.graphql({ query: productMapping, variables: body });
                //     message.success('Product mapped successfully!');
                // } else {
                //     const newTodo = await API.graphql(graphqlOperation(productMapping, body));
                //     message.success('Product mapped successfully!');
                // }
            } catch (error) {
                if (error.errors[0].errorType.includes("ConditionalCheckFailedException")) {
                    message.error('Already existed user data');
                } else message.error('Invalid Data');
            }
        }
    }

    const handleCancel = () => { history.push(PRODUCT_DATA_DASHBOARD) }

    return (
        <Dashboard>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <h2 className="heading18Bold margin0">Add New Product Data</h2>
                    <div className="CreateUserForm">
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }} >
                                <p className="customeSideLabel">RRL Article</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={RRLArticle || undefined} placeholder='Enter Article' onMouseLeave={handleKeyPress}
                                    onChange={(e) => { handleValueChange("RRLArticle", e) }} inputClassname="inputBoxField" errorText={errors.RRLArticle} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">GTIN</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomSearchBar className="beforeStar" placeholder="Search by GTIN" value={GTIN} errorText={errors.GTIN} errorMsg={true}
                                    inputClassname="Search-main" onChange={(e) => { handleValueChange("GTIN", e) }} onSearch={onSearch} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">Hand Prod Desc</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={hanaProdDesc || undefined} placeholder='Hand Prod Desc' disabled
                                    onChange={(e) => { handleValueChange("hanaProdDesc", e) }} inputClassname="inputBoxField" errorText={errors.hanaProdDesc} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">HANA Parent Code</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={hanaParentCode || undefined} placeholder='Hana Parent Code' disabled
                                    onChange={(e) => { handleValueChange("hanaParentCode", e) }} inputClassname="inputBoxField" errorText={errors.hanaParentCode} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">HANA Code</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar" value={hanaCode || undefined} placeholder='Hana Code' disabled
                                    onChange={(e) => { handleValueChange("hanaCode", e) }} inputClassname="inputBoxField" errorText={errors.hanaCode} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">RRL</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <DropDownSelect placeholder='RRL' className="beforeStar" value={RRL || undefined} onChange={(e) => { handleValueChange("RRL", e) }}
                                    selectStyles="selectStyles" options={[<Option value={YES_KEY}> Yes </Option>, <Option value={NO_KEY}> No </Option>]} errorText={errors.RRL} />
                            </Col>
                        </Row>
                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}>
                                <p className="customeSideLabel">RRL Article Desc</p>
                            </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }}>
                                <CustomInput className="beforeStar " value={RRLArticleDescription || undefined} placeholder='Enter article description'
                                    onChange={(e) => { handleValueChange("RRLArticleDescription", e) }} inputClassname="inputBoxField" errorText={errors.RRLArticleDescription} />
                            </Col>
                        </Row>

                        <Row className="gap1emTopBottom">
                            <Col lg={{ span: '4' }} xs={{ span: '24' }}> </Col>
                            <Col lg={{ span: '7' }} xs={{ span: '24' }} className="displaySpacebetween ">
                                <CustomeButton buttonLabel="Cancel" className="cancelBtn" handleSubmit={handleCancel} />
                                <CustomeButton buttonLabel={window.location.pathname === USERS_EDIT ? 'Update' : "Submit"} className="submitBtn" handleSubmit={handleSubmit} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Dashboard>
    )
}
export default CreateProductData;