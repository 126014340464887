import React from 'react';
import { Form, Radio } from 'antd';
import "./styles.scss";

const CustomRadioButtonTabs = ({ label, errorText, value, onChange, className, radioOptions, radioBtnTabsStyles }) => {
    const FormItem = Form.Item;

    return (<FormItem label={label} className={className}>
        <Radio.Group onChange={onChange} value={value} className={radioBtnTabsStyles}>
            {radioOptions && radioOptions.map(item => <Radio.Button value={item.key}>{item.value}</Radio.Button>)}
        </Radio.Group>
        <p className="errorRadioBtnTabsStyles">{errorText}</p>
    </FormItem>)
}

export default CustomRadioButtonTabs;