import React, { useState, useEffect } from 'react';
import Dashboard from '../dashboard';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { Row, Col, Spin, Tabs, message } from 'antd';
import CustomDatePicker from '../../utils/components/customeInputFields/datePicker';
import {
  CLOSED_POS, DATE_PICKER_YEAR_MONTH_DATE, NUMBER_TWO, NUMBER_THREE, DATE_PICKER_TIME_ZONE_Z, OpenPOs,
  KEY_INSTANTACK, KEY_INVOICE, KEY_ITEMAMEND, KEY_ITEMCASELOT, KEY_ITEMEAN, KEY_XMLFAI,KEY_PARTIAL_INVOICE, PARTIAL_INVOICE
} from '../../utils/constants';
import OrdersTable from '../../utils/components/tables/orders';
import InvoiceTable from '../../utils/components/tables/orderInvoice';
import ClosedPOs from '../../utils/components/tables/closedPO';
import { OrdersTabs } from '../../utils/functions';
import AsnDateModal from '../../utils/components/modal/asnInvoice';
import { queryOpenInvoiceByDateRange, queryOpenPOByDateRange, queryClosedPOByDateRange, querySiteCode } from '../../graphql/queries';
import { API } from 'aws-amplify';
import './index.scss';
import CustomPaginationWithPages from '../../utils/components/pagination/index1';
import OrdersActivity from '../orders/features/orderActivity';
const { TabPane } = Tabs;

const INVOICE = 'invoice';
const CLOSEDPO = 'closedPO';
const OPENPO = 'openOP';
const NUMBER_ONE = 1;
const NUMBER_TEN = 10;
const POID = 'poID';
const INID = 'inID';
const SALESORDER = 'SalesOrder';
const CREATEDDATE = 'createdDate';
const ASCENDING = 'ascending';
const DECENDING = 'decending';
const NOOFRECORDS = 'noofRecords';
const NEXTBTN = 'nextBtn';

const OrdersDashboard = () => {
  const { state: st } = useLocation();
  const [companyName, setCompanyName] = useState();
  const COMPANY_CODE = sessionStorage.getItem('companySK');
  const [company, setCompany] = useState(sessionStorage.getItem('companyName'));
  const [tabKey, setTabKey] = useState(NUMBER_ONE);
  const [startDateTime, setStartDate] = useState();
  const [endDateTime, setEndDate] = useState();
  const [totalAsnCount, setTotalAsnCount] = useState(0);
  const [totalAmendCount, setTotalAmendCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setModalVisible] = useState(false);
  const [tabData, setTabData] = useState({ tab1Date: false, tab2Date: false, tab3Date: false });
  const [isPOSeletected, setIsPOSeletected] = useState(false);
  const [tempState, setTempstate] = useState();
  const [openPODataSet, setOpenPODataSet] = useState();
  const [closePODataSet, setClosePODataSet] = useState();
  const [siteCode,setSiteCode] = useState([])
  const [closedSiteCode,setClosedSiteCode] =useState([])
  // invoice pagination initial state
  const [invoicePOData, setInvoicePOData] = useState({
    invoicePOTotalData: {}, invoicePOTableData: [],
    invoicePOPageNumber: '', invoicePONextToken: null,
    invoicePOPaginationData: {
      invoicePONoOfRecordsPerPage: NUMBER_TEN,
      invoicePONextBtn: true,
      invoicePOPreviousBtn: true,
      invoicePOFromCount: NUMBER_ONE,
      invoicePOToCount: NUMBER_TEN,
      invoicePOShowItems: [10, 20, 50, 100]
    },
    invoicePOArray: [], invoicePOActiveElement: null, invoicePOActiveSortOrder: null
  })

  // closed po pagination initial state
  const [closedPOData, setClosedPOData] = useState({
    closedPOTotalData: {}, closedPOTableData: [],
    closedPOPageNumber: '', closedPONextToken: null,
    closedPOPaginationData: {
      closedPONoOfRecordsPerPage: NUMBER_TEN,
      closedPONextBtn: true,
      closedPOPreviousBtn: true,
      closedPOFromCount: NUMBER_ONE,
      closedPOToCount: NUMBER_TEN,
      closedPOShowItems: [10, 20, 50, 100]
    },
    closedPOArray: [], closedPOActiveElement: null, closedPOActiveSortOrder: null
  })

  // open pos pagination initial state
  const [openPOData, setOpenPOData] = useState({
    openPOTotalData: {}, openPOTableData: [],
    openPOPageNumber: '', openPONextToken: null,
    openPOPaginationData: {
      openPONoOfRecordsPerPage: NUMBER_TEN,
      openPONextBtn: true,
      openPOPreviousBtn: true,
      openPOFromCount: NUMBER_ONE,
      openPOToCount: NUMBER_TEN,
      openPOShowItems: [10, 20, 50, 100]
    },
    openPOArray: [], openPOActiveElement: null, openPOActiveSortOrder: null, openPOStatusSetKeys: []
  })

  const { tab1Date, tab2Date, tab3Date } = tabData;

  const { invoicePOTotalData, invoicePOTableData, invoicePOPageNumber, invoicePONextToken, invoicePOActiveElement, invoicePOActiveSortOrder, invoicePOArray } = invoicePOData;
  const { invoicePONoOfRecordsPerPage, invoicePONextBtn, invoicePOPreviousBtn, invoicePOFromCount, invoicePOToCount, invoicePOShowItems } = invoicePOData.invoicePOPaginationData;

  const { openPOTotalData, openPOTableData, openPOPageNumber, openPONextToken, openPOArray, openPOActiveElement, openPOActiveSortOrder, openPOStatusSetKeys } = openPOData;
  const { openPONoOfRecordsPerPage, openPONextBtn, openPOPreviousBtn, openPOFromCount, openPOToCount, openPOShowItems } = openPOData.openPOPaginationData;

  const { closedPOTotalData, closedPOTableData, closedPOPageNumber, closedPONextToken, closedPOArray, closedPOActiveElement, closedPOActiveSortOrder } = closedPOData;
  const { closedPONoOfRecordsPerPage, closedPONextBtn, closedPOPreviousBtn, closedPOFromCount, closedPOToCount, closedPOShowItems } = closedPOData.closedPOPaginationData;

  const handleStartDate = (e) => {
    setStartDate(new Date(e));
    var endDate = new Date(e);
    var endDateTime = new Date(endDate.setMonth(endDate.getMonth() + 3));
    setEndDate(endDateTime);
    setTabData(state => ({ ...state, tab1Date: true, tab2Date: true, tab3Date: true }));
    if (tabKey == NUMBER_ONE) { setInititalState(INVOICE); fetchInvoiceData(e, endDateTime, null, NUMBER_ONE, invoicePONoOfRecordsPerPage, INVOICE); }
    else if (tabKey == NUMBER_TWO) { setInititalState(OPENPO); fetchPosData(e, endDateTime, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO); }
    else if (tabKey == NUMBER_THREE) { setInititalState(CLOSEDPO); fetchClosedPoData(e, endDateTime, null, NUMBER_ONE, closedPONoOfRecordsPerPage, CLOSEDPO); }
  }

  const handleEndDate = (e) => {
    setEndDate(new Date(e));
    setTabData(state => ({ ...state, tab1Date: true, tab2Date: true, tab3Date: true }));
    if (tabKey == NUMBER_ONE) { setInititalState(INVOICE); fetchInvoiceData(startDateTime, e, null, NUMBER_ONE, invoicePONoOfRecordsPerPage, INVOICE); }
    else if (tabKey == NUMBER_TWO) { setInititalState(OPENPO); fetchPosData(startDateTime, e, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO); }
    else if (tabKey == NUMBER_THREE) { setInititalState(CLOSEDPO); fetchClosedPoData(startDateTime, e, null, NUMBER_ONE, closedPONoOfRecordsPerPage, CLOSEDPO); }
  }

  const onTabChange = async (e, key) => {
    setTabKey(e);
    if (String(e) == NUMBER_ONE && tab1Date) {
      setInititalState(INVOICE); fetchInvoiceData(startDateTime, endDateTime, null, NUMBER_ONE, invoicePONoOfRecordsPerPage, INVOICE);
    } else if (String(e) == NUMBER_TWO && tab2Date) {
      await setInititalState(OPENPO); fetchPosData(startDateTime, endDateTime, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO);
    } else if (String(e) == NUMBER_THREE && tab3Date) {
      setInititalState(CLOSEDPO); fetchClosedPoData(startDateTime, endDateTime, null, NUMBER_ONE, closedPONoOfRecordsPerPage, CLOSEDPO);
    }
  }

  const setInititalState = async (type) => {
    if (type === INVOICE) {
      setInvoicePOData(state => ({
        ...state, invoicePOPaginationData: {
          ...state.invoicePOPaginationData, invoicePOFromCount: NUMBER_ONE, invoicePOToCount: invoicePONoOfRecordsPerPage, invoicePOPreviousBtn: true, invoicePONextBtn: false
        }, invoicePOPageNumber: 1, invoicePOActiveElement: null, invoicePOActiveSortOrder: null, invoicePOTotalData: {}, invoicePOArray: []
      }));
    }
    else if (type === OPENPO) {
      await setOpenPOData(state => ({
        ...state, openPOPaginationData: {
          ...state.openPOPaginationData, openPOFromCount: NUMBER_ONE, openPOToCount: openPONoOfRecordsPerPage, openPOPreviousBtn: true, openPONextBtn: false
        }, openPOPageNumber: 1, openPOActiveElement: null, openPOActiveSortOrder: null, openPOTotalData: {}, openPOArray: []
      }));
      setOpenPODataSet(state => ({
        ...state, openPOPaginationData: {
          ...state.openPOPaginationData, openPOFromCount: NUMBER_ONE, openPOToCount: openPONoOfRecordsPerPage, openPOPreviousBtn: true, openPONextBtn: false
        }, openPOPageNumber: 1, openPOActiveElement: null, openPOActiveSortOrder: null, openPOTotalData: {}, openPOArray: []
      }));
    }
    else if (type === CLOSEDPO) {
      setClosedPOData(state => ({
        ...state, closedPOPaginationData: {
          ...state.closedPOPaginationData, closedPOFromCount: NUMBER_ONE, closedPOToCount: closedPONoOfRecordsPerPage, closedPOPreviousBtn: true, closedPONextBtn: false
        }, closedPOPageNumber: 1, closedPOActiveElement: null, closedPOActiveSortOrder: null, closedPOTotalData: {}, closedPOArray: []
      }));
      // setClosePODataSet(state => ({
      //   ...state, closedPOPaginationData: {
      //     ...state.closedPOPaginationData, closedPOFromCount: NUMBER_ONE, closedPOToCount: closedPONoOfRecordsPerPage, closedPOPreviousBtn: true, closedPONextBtn: false
      //   }, closedPOPageNumber: 1, closedPOActiveElement: null, closedPOActiveSortOrder: null, closedPOTotalData: {}, closedPOArray: []
      // }))
      setClosePODataSet(closedPOData);
    }
  }

  useEffect(() => {
    if (st == undefined) {
      setCompanyName(sessionStorage.getItem('companySK'));
    }
  }, [])

  // Fetching Apis Data based on pagination by passing nextToken
  useEffect(() => {
    if (companyName != undefined && st == undefined) {
      var startDate, endDate;
      if (st && st.startDateTime != undefined && st.endDateTime != undefined) {
        startDate = st.startDateTime; endDate = st.endDateTime;
      } else {
        var now = new Date(); endDate = new Date();
        startDate = new Date(now.setMonth(now.getMonth() - 3));
      }
      setStartDate(startDate); setEndDate(endDate);
      setTabKey(st && st.currentTab ? st.currentTab : '2');
      if (st && st.currentTab && st.currentTab == NUMBER_TWO) {
        fetchPosData(startDate, endDate, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO);
        setTabData(state => ({ ...state, tab1Date: true, tab3Date: true }));
      } else if (st && st.currentTab && st.currentTab == NUMBER_THREE) {
        fetchClosedPoData(startDate, endDate, null, NUMBER_ONE, closedPONoOfRecordsPerPage, CLOSEDPO);
        setTabData(state => ({ ...state, tab1Date: true, tab2Date: true }));
      } else {
        fetchPosData(startDate, endDate, null, NUMBER_ONE, openPONoOfRecordsPerPage, OPENPO);
        setTabData(state => ({ ...state, tab1Date: true, tab3Date: true }));
        // fetchInvoiceData(startDate, endDate, null, NUMBER_ONE, invoicePONoOfRecordsPerPage, INVOICE);
        // fetchInvoiceCount();
        // setTabData(state => ({ ...state, tab2Date: true, tab3Date: true }));
      }
    }
  }, [companyName]);

  useEffect(() => {
    if (st && st?.openPODataSet && st?.prevURL || st && st?.closePODataSet) {
      setCompanyName(sessionStorage.getItem('companySK'))
      var startDate, endDate;
      if (st && st.startDateTime != undefined && st.endDateTime != undefined) {
        startDate = st.startDateTime; endDate = st.endDateTime;
      } else {
        var now = new Date(); endDate = new Date();
        startDate = new Date(now.setMonth(now.getMonth() - 3));
      }
      setStartDate(startDate); setEndDate(endDate);
      setTabKey(st && st.currentTab ? st.currentTab : '2');
      if (st && st.currentTab && st.currentTab == NUMBER_TWO) {
        fetchPosData(startDate, endDate, st?.openPODataSet?.openPONextToken, st?.openPODataSet?.openPOPageNumber + 1, st?.openPODataSet?.openPOPaginationData?.openPONoOfRecordsPerPage, NEXTBTN, st?.openPODataSet?.openPOPaginationData?.openPOToCount - st?.openPODataSet?.openPOPaginationData?.openPONoOfRecordsPerPage + 1, st?.openPODataSet?.openPOPaginationData?.openPOToCount);
        setTabData(state => ({ ...state, tab1Date: true, tab3Date: true }));
      } else if (st && st.currentTab && st.currentTab == NUMBER_THREE) {
        fetchClosedPoData(startDate, endDate, st?.closePODataSet?.closedPONextToken, st?.closePODataSet?.closedPOPageNumber + 1, st?.closePODataSet?.closedPOPaginationData?.closedPONoOfRecordsPerPage, NEXTBTN, st?.closePODataSet?.closedPOPaginationData?.closedPOToCount + 1, st.closePODataSet?.closedPOPaginationData?.closedPOToCount + st?.closePODataSet?.closedPOPaginationData?.closedPONoOfRecordsPerPage);
        setTabData(state => ({ ...state, tab1Date: true, tab2Date: true }));
      } else {
        fetchPosData(startDate, endDate, st.openPODataSet.openPONextToken, st.openPODataSet.openPOPageNumber, openPONoOfRecordsPerPage, OPENPO);
        setTabData(state => ({ ...state, tab1Date: true, tab3Date: true }));
        // fetchInvoiceData(startDate, endDate, null, NUMBER_ONE, invoicePONoOfRecordsPerPage, INVOICE);
        // fetchInvoiceCount();
        // setTabData(state => ({ ...state, tab2Date: true, tab3Date: true }));
      }
    }
  }, [st])


  // Fetiching queryOpenInvoicePOCount Data
  // async function fetchInvoiceCount() {
  //   if (sessionStorage.getItem('companyName')) {
  //     let str = sessionStorage.getItem('companyName').split(' ')[0].toLowerCase();
  //     setCompany(str.charAt(0).toUpperCase() + str.slice(1))
  //   }
  //   const countApiData = await API.graphql({ query: queryOpenInvoicePOCount, variables: { GSI1PK: companyName + '#OpenInvoice', first: 12, after: null } });
  //   setLoading(false);
  //   setTotalAsnCount(countApiData.data.queryOpenInvoicePOCount.ASNCount);
  //   setTotalAmendCount(countApiData.data.queryOpenInvoicePOCount.AmendmentCount);
  // }

  // Fetiching OpenInvoice Data
  async function fetchInvoiceData(startDate, endDate, nextToken, pageNumber = 1, noOfRecordsPerPage, from, fCount, tCount) {
    var startDateFormat = moment.utc(startDate).format(DATE_PICKER_TIME_ZONE_Z);
    var endDateFormat = moment.utc(endDate).format(DATE_PICKER_TIME_ZONE_Z);

    try {
      setLoading(true);
      const apiData = await API.graphql({
        query: queryOpenInvoiceByDateRange, variables: {
          GSI1PK: companyName + '#OpenInvoice',
          start: startDateFormat, end: endDateFormat, first: noOfRecordsPerPage, after: nextToken
        }
      });
      var data = apiData.data.queryOpenInvoiceByDateRange;
      setTabData(state => ({ ...state, tab1Date: false }));

      var invoicePOArry;
      if (from === NOOFRECORDS || from == INVOICE) invoicePOArry = data.items;
      else invoicePOArry = [...invoicePOArray, ...data.items];

      setInvoicePOData(state => ({
        ...state, invoicePOTotalData: { ...state.invoicePOTotalData, [pageNumber]: data.items }, invoicePOTableData: data.items,
        invoicePONextToken: data.nextToken, invoicePOPageNumber: pageNumber, invoicePOArray: invoicePOArry
      }))

      if (data.nextToken) {
        setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePONextBtn: false } }))
      } else if (data.nextToken == null) {
        var toCount1 = invoicePOToCount + data.items.length;
        if (from == INVOICE) {
          setInvoicePOData(state => ({
            ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePONextBtn: true, invoicePOToCount: data.items.length }
          }))
        } else {
          setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePONextBtn: true, invoicePOToCount: toCount1 } }))
        }
      }
      if (from == NEXTBTN && (invoicePOActiveSortOrder == ASCENDING || invoicePOActiveSortOrder == DECENDING)) {
        invoicePOSortingFunction(invoicePOActiveElement, invoicePOActiveSortOrder, invoicePOArry, fCount, invoicePOToCount + data.items.length)
      }
    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
  }

  // Fetiching OpenPOs Data
  async function fetchPosData(startDate, endDate, nextToken, pageNumber, noOfRecordsPerPage, from, fCount, tCount) {
    var startDateFormat = moment.utc(startDate).format(DATE_PICKER_TIME_ZONE_Z);
    var endDateFormat = moment.utc(endDate).format(DATE_PICKER_TIME_ZONE_Z);

    try {
      setLoading(true);
      let tempcompanyCode = companyName ? companyName : COMPANY_CODE;
      const apiData = await API.graphql({
        query: queryOpenPOByDateRange,
        variables: { GSI1PK: tempcompanyCode + '#OpenPO', start: startDateFormat, end: endDateFormat, first: noOfRecordsPerPage, after: nextToken }
      });
      // Fetching sitecode taking the PO datas PK and SK as its PK and Sk = Item#00001 and passing the state variable sitecode as props in orders table
    apiData.data.queryOpenPOByDateRange.items.map(async (element)=>{
        
        const apiDatas =await API.graphql({
          query: querySiteCode,
          variables: {PK: element.PK+"#"+element.SK,SK: "Item#00001"}
        })
        element['SiteCode']=apiDatas.data.querySiteCode.items[0]?.SiteCode
        setSiteCode((prevData)=>[...prevData,apiDatas.data.querySiteCode.items[0]])
      })
      var dataMapper = [], i = 0, data = apiData.data.queryOpenPOByDateRange, filteredItems =[];
      let amdStatussets = [], invoiceStatussets = [], eanStatussets = [], xmlStatussets = [], caselotStatussets = [], instantACKStatussets = [];
      let seen = {};
      data.items && data.items.map(item => {
        let statussets = [];
        if (item.StatusSet != null) {
          item.StatusSet && item.StatusSet.map((status, j) => {
            if (status.includes(KEY_ITEMAMEND)) amdStatussets.push(status);
            else if (status.includes(KEY_INVOICE)) invoiceStatussets.push(status)
            else if (status.includes(KEY_ITEMEAN)) eanStatussets.push(status)
            else if (status.includes(KEY_XMLFAI)) xmlStatussets.push(status)
            else if (status.includes(KEY_ITEMCASELOT)) caselotStatussets.push(status)
            else if (status.includes(KEY_INSTANTACK)) instantACKStatussets.push(status)
            if (!statussets.some(substring => status.includes(substring))) {
              statussets.push(status.substring(0, 6));

              const key = item.SK
              if(!seen[key] || status === KEY_PARTIAL_INVOICE){
                if(status === KEY_PARTIAL_INVOICE){
                  dataMapper.pop()
                  status  = PARTIAL_INVOICE
                }
              dataMapper.push({ item, status, i });
              i++;
              seen[key]= true;
              }
            }

          })
        } else dataMapper.push({ item, status: null, i });
      })

      setTabData(state => ({ ...state, tab2Date: false }));
      var openPOArry;
      if (from === NOOFRECORDS || from === OPENPO) openPOArry = dataMapper;
      else openPOArry = [...openPOArray, ...dataMapper];

      setOpenPOData(state => ({
        ...state, openPOArray: openPOArry, openPOTotalData: { ...state.openPOTotalData, [pageNumber]: dataMapper },
        openPOTableData: dataMapper, openPONextToken: data.nextToken, openPOPageNumber: pageNumber,
        openPOStatusSetKeys: { ...state.openPOStatusSetKeys, amdStatussets, invoiceStatussets, eanStatussets, xmlStatussets, caselotStatussets, instantACKStatussets }
      }))

      // setOpenPODataSet(state => ({
      //   ...state, openPOArray: openPOArry, openPOTotalData: { ...state.openPOTotalData, [pageNumber]: dataMapper },
      //   openPOTableData: dataMapper, openPONextToken: data.nextToken, openPOPageNumber: pageNumber,
      //   openPOStatusSetKeys: { ...state.openPOStatusSetKeys, amdStatussets, invoiceStatussets, eanStatussets, xmlStatussets, caselotStatussets, instantACKStatussets }
      // }))
      setOpenPODataSet(openPOData)

      if (data.nextToken) {
        var toCount1 = openPOToCount + dataMapper.length;
        setOpenPOData(state => ({
          ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: false, openPOToCount: from == OPENPO ? dataMapper.length : toCount1 }
        }))
        setOpenPODataSet(state => ({
          ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: false, openPOToCount: from == OPENPO ? dataMapper.length : toCount1 }
        }))
        if (from == NEXTBTN) {
          setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOFromCount: fCount, openPOToCount: tCount } }))
        }
      } else if (data.nextToken == null) {
        var toCount1 = openPOToCount + dataMapper.length;
        if (from == OPENPO) {
          setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: true, openPOToCount: dataMapper.length } }))
          setOpenPODataSet(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: true, openPOToCount: dataMapper.length } }))
        } else {
          setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: true, openPOToCount: toCount1 } }))
          setOpenPODataSet(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: true, openPOToCount: toCount1 } }))
        }
      }

      if (from === NEXTBTN && (openPOActiveSortOrder == ASCENDING || openPOActiveSortOrder == DECENDING)) {
        openPOSortingFunction(openPOActiveElement, openPOActiveSortOrder, openPOArry, fCount, openPOToCount + dataMapper.length);
      }

    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
  }

  // Fetiching ClosedPos Data
  async function fetchClosedPoData(startDate, endDate, nextToken, pageNumber = 1, noOfRecordsPerPage, from, fCount, tCount) {
    var startDateFormat = moment.utc(startDate).format(DATE_PICKER_TIME_ZONE_Z);
    var endDateFormat = moment.utc(endDate).format(DATE_PICKER_TIME_ZONE_Z);

    try {
      setLoading(true);
      // const apiData = await API.graphql({ query: queryClosedPOByDateRange, variables: { PK: 'EDI#' + companyName, SK: "PO#", start: startDateFormat, end: endDateFormat, first: 10, after: nextToken } });
      let tempcompanyCode = companyName ? companyName : COMPANY_CODE;
      const apiData = await API.graphql({
        query: queryClosedPOByDateRange,
        variables: {
          GSI1PK: tempcompanyCode + '#COMPLETED', start: startDateFormat, end: endDateFormat,
          first: noOfRecordsPerPage, after: nextToken
        }
      });
      var data = apiData.data.queryClosedPOByDateRange;
     
      data.items.map(async (element)=>{
        const apiDatas = await API.graphql({
          query:querySiteCode,
          variables: {PK: element.PK+"#"+element.SK,SK: "Item#00001" }
        })
        element['SiteCode']=apiDatas.data.querySiteCode.items[0]?.SiteCode
        setClosedSiteCode((prevData)=>[...prevData,apiDatas.data.querySiteCode.items[0]])
      })
      setTabData(state => ({ ...state, tab3Date: false }));

      var closedPOArry;
      if (from === CLOSEDPO || from === NOOFRECORDS) closedPOArry = data.items;
      else closedPOArry = [...closedPOArray, ...data.items];

      setClosedPOData(state => ({
        ...state, closedPOTotalData: { ...state.closedPOTotalData, [pageNumber]: data.items }, closedPOTableData: data.items,
        closedPONextToken: data.nextToken, closedPOPageNumber: pageNumber, closedPOArray: closedPOArry
      }))
      setClosePODataSet(closedPOData)
      if (data.nextToken) {
        setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: false } }))
        setClosePODataSet(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: false } }))
        if (from == NEXTBTN) {
          setClosedPOData(state => ({
            ...state, closedPOPaginationData: {
              ...state.closedPOPaginationData, closedPOFromCount: fCount, closedPOToCount: tCount
            }
          }))
        }
      } else if (data.nextToken == null) {
        var toCount1 = closedPOToCount + data.items.length;
        if (from === CLOSEDPO || from === NOOFRECORDS) {
          setClosedPOData(state => ({
            ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: true, closedPOToCount: data.items.length }
          }))
          setClosePODataSet(state => ({
            ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: true, closedPOToCount: data.items.length }
          }))
        } else {
          setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: true, closedPOToCount: toCount1 } }))
          setClosePODataSet(state => ({
            ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: true, closedPOToCount: data.items.length }
          }))
        }
      }
      if (from === NEXTBTN && (closedPOActiveSortOrder == ASCENDING || closedPOActiveSortOrder == DECENDING)) {
        closedPOSortingFunction(closedPOActiveElement, closedPOActiveSortOrder, closedPOArry, fCount, closedPOToCount + data.items.length)
      }

    } catch (error) { message.error('Invalid Data') }
    setLoading(false);
  }

  const closedPONoOfRecordsPerPageChanged = (number) => {
    setClosedPOData(state => ({
      ...state,
      closedPOPaginationData: {
        ...state.closedPOPaginationData, closedPONoOfRecordsPerPage: number, closedPOToCount: number,
        closedPOFromCount: NUMBER_ONE, closedPOPreviousBtn: true, closedPONextBtn: false
      }, closedPOTotalData: {}, closedPOArray: [], closedPOActiveElement: null, closedPOActiveSortOrder: null, closedPOTableData: []
    }))
    setClosePODataSet(state => ({
      ...state,
      closedPOPaginationData: {
        ...state.closedPOPaginationData, closedPONoOfRecordsPerPage: number, closedPOToCount: number,
        closedPOFromCount: NUMBER_ONE, closedPOPreviousBtn: true, closedPONextBtn: false
      }, closedPOTotalData: {}, closedPOArray: [], closedPOActiveElement: null, closedPOActiveSortOrder: null, closedPOTableData: []
    }))
    fetchClosedPoData(startDateTime, endDateTime, null, NUMBER_ONE, number, NOOFRECORDS)
  }

  // const invoicePONoOfRecordsPerPageChanged = (number) => {
  //   setInvoicePOData(state => ({
  //     ...state,
  //     invoicePOPaginationData: {
  //       ...state.invoicePOPaginationData, invoicePONoOfRecordsPerPage: number, invoicePOToCount: number,
  //       invoicePOFromCount: NUMBER_ONE, invoicePOPreviousBtn: true, invoicePONextBtn: false
  //     }, invoicePOTotalData: {}, invoicePOArray: [], invoicePOActiveElement: null, invoicePOActiveSortOrder: null, invoicePOTableData: []
  //   }))
  //   fetchInvoiceData(startDateTime, endDateTime, null, NUMBER_ONE, number, NOOFRECORDS)
  // }

  const openPONoOfRecordsPerPageChanged = (number) => {
    setOpenPOData(state => ({
      ...state,
      openPOPaginationData: {
        ...state.openPOPaginationData, openPONoOfRecordsPerPage: number, openPOToCount: number,
        openPOFromCount: NUMBER_ONE, openPOPreviousBtn: true, openPONextBtn: false
      }, openPOTotalData: {}, openPOArray: [], openPOActiveElement: null, openPOActiveSortOrder: null, openPOTableData: []
    }))
    setOpenPODataSet(state => ({
      ...state,
      openPOPaginationData: {
        ...state.openPOPaginationData, openPONoOfRecordsPerPage: number, openPOToCount: number,
        openPOFromCount: NUMBER_ONE, openPOPreviousBtn: true, openPONextBtn: false
      }, openPOTotalData: {}, openPOArray: [], openPOActiveElement: null, openPOActiveSortOrder: null, openPOTableData: []
    }))
    fetchPosData(startDateTime, endDateTime, null, NUMBER_ONE, number, OPENPO, NOOFRECORDS)
  }

  // to detch next Data
  const onNext = (type, currentPage) => {
    if (type === CLOSEDPO) {
      var fromCount1 = closedPOToCount + NUMBER_ONE;
      var toCount1 = closedPOToCount + closedPONoOfRecordsPerPage;
      var currentPageNumber = closedPOPageNumber + NUMBER_ONE;

      if (closedPOTotalData[currentPageNumber] == undefined) {
        setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOToCount: toCount1 } }));
        setClosePODataSet(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOToCount: toCount1 } }))
        fetchClosedPoData(startDateTime, endDateTime, closedPONextToken, currentPageNumber, closedPONoOfRecordsPerPage, NEXTBTN, fromCount1, toCount1);
      } else {
        setClosedPOData(state => ({ ...state, closedPOTableData: closedPOTotalData[currentPageNumber], closedPOPageNumber: currentPageNumber }))
        var toCount2 = closedPOToCount + closedPOTotalData[currentPageNumber].length;
        if (currentPageNumber == Object.keys(closedPOTotalData).length && closedPONextToken == null) {
          setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: true, closedPOToCount: toCount2 } }))
          setClosePODataSet(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPONextBtn: true, closedPOToCount: toCount2 } }))
        } else {
          setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOToCount: toCount1 } }))
          setClosePODataSet(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOToCount: toCount1 } }))
        }
        if (closedPOActiveSortOrder == ASCENDING || closedPOActiveSortOrder == DECENDING) { closedPOSplitSortData(closedPOArray, fromCount1, toCount2) }
      }
      setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOFromCount: fromCount1, closedPOPreviousBtn: false } }))
      setClosePODataSet(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOFromCount: fromCount1, closedPOPreviousBtn: false } }))
    } else if (type === INVOICE) {
      var fromCount1 = invoicePOToCount + NUMBER_ONE;
      var toCount1 = invoicePOToCount + invoicePONoOfRecordsPerPage;
      var currentPageNumber = invoicePOPageNumber + NUMBER_ONE;

      if (invoicePOTotalData[currentPageNumber] == undefined) {
        setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOToCount: toCount1 } }));
        fetchInvoiceData(startDateTime, endDateTime, invoicePONextToken, currentPageNumber, invoicePONoOfRecordsPerPage, NEXTBTN, fromCount1, toCount1);
      } else {
        setInvoicePOData(state => ({ ...state, invoicePOTableData: invoicePOTotalData[currentPageNumber], invoicePOPageNumber: currentPageNumber }))
        var toCount2 = invoicePOToCount + invoicePOTotalData[currentPageNumber].length;
        if (currentPageNumber == Object.keys(invoicePOTotalData).length && invoicePONextToken == null) {
          setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePONextBtn: true, invoicePOToCount: toCount2 } }))
        } else setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOToCount: toCount1 } }))
        if (invoicePOActiveSortOrder == ASCENDING || invoicePOActiveSortOrder == DECENDING) { invoicePOSplitSortData(invoicePOArray, fromCount1, toCount2) }
      }
      setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOFromCount: fromCount1, invoicePOPreviousBtn: false } }))

    } else {
      var fromCount1 = openPOToCount + NUMBER_ONE;
      var toCount1 = openPOToCount + openPONoOfRecordsPerPage;
      var currentPageNumber = openPOPageNumber + NUMBER_ONE;

      if (openPOTotalData[currentPageNumber] == undefined) {
        setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOToCount: toCount1 } }));
        setOpenPODataSet(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOToCount: toCount1 } }));
        fetchPosData(startDateTime, endDateTime, openPONextToken, currentPageNumber, openPONoOfRecordsPerPage, NEXTBTN, fromCount1, toCount1);
      } else {
        setOpenPOData(state => ({ ...state, openPOTableData: openPOTotalData[currentPageNumber], openPOPageNumber: currentPageNumber }))
        setOpenPODataSet(state => ({ ...state, openPOTableData: openPOTotalData[currentPageNumber], openPOPageNumber: currentPageNumber }))
        var toCount2 = openPOToCount + openPOTotalData[currentPageNumber].length;
        if (currentPageNumber == Object.keys(openPOTotalData).length && openPONextToken == null) {
          setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: true, openPOToCount: toCount2 } }))
          setOpenPODataSet(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPONextBtn: true, openPOToCount: toCount2 } }))
        } else {
          setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOToCount: toCount2 } }))
          setOpenPODataSet(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOToCount: toCount2 } }))
        }
        if (openPOActiveSortOrder == ASCENDING || openPOActiveSortOrder == DECENDING) { openPOSplitSortData(openPOArray, fromCount1, toCount2) }
      }
      setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOFromCount: fromCount1, openPOPreviousBtn: false } }))
      setOpenPODataSet(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOFromCount: fromCount1, openPOPreviousBtn: false } }))
    }
  }

  // after click on Previous icon
  const onPre = (type, currentPage) => {
    if (type === CLOSEDPO) {
      var fromCount1 = closedPOFromCount - closedPONoOfRecordsPerPage;
      var toCount1 = closedPOFromCount - 1;
      var currentPageNumber = closedPOPageNumber - 1;

      if (fromCount1 == NUMBER_ONE) {
        setClosedPOData(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOPreviousBtn: true } }))
        setClosePODataSet(state => ({ ...state, closedPOPaginationData: { ...state.closedPOPaginationData, closedPOPreviousBtn: true } }));
      }
      setClosedPOData(state => ({
        ...state, closedPOTableData: closedPOTotalData[currentPageNumber], closedPOPageNumber: currentPageNumber,
        closedPOPaginationData: { ...state.closedPOPaginationData, closedPOFromCount: fromCount1, closedPOToCount: toCount1, closedPONextBtn: false },
      }))
      setClosePODataSet(state => ({
        ...state, closedPOTableData: closedPOTotalData[currentPageNumber], closedPOPageNumber: currentPageNumber,
        closedPOPaginationData: { ...state.closedPOPaginationData, closedPOFromCount: fromCount1, closedPOToCount: toCount1, closedPONextBtn: false },
      }))
      if (closedPOActiveSortOrder == ASCENDING || closedPOActiveSortOrder == DECENDING) { closedPOSplitSortData(closedPOArray, fromCount1, toCount1) }

    } else if (type === INVOICE) {
      var fromCount1 = invoicePOFromCount - invoicePONoOfRecordsPerPage;
      var toCount1 = invoicePOFromCount - 1;
      var currentPageNumber = invoicePOPageNumber - 1;

      if (fromCount1 == NUMBER_ONE) setInvoicePOData(state => ({ ...state, invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOPreviousBtn: true } }))
      setInvoicePOData(state => ({
        ...state, invoicePOTableData: invoicePOTotalData[currentPageNumber], invoicePOPageNumber: currentPageNumber,
        invoicePOPaginationData: { ...state.invoicePOPaginationData, invoicePOFromCount: fromCount1, invoicePOToCount: toCount1, invoicePONextBtn: false },
      }))
      if (invoicePOActiveSortOrder == ASCENDING || invoicePOActiveSortOrder == DECENDING) { invoicePOSplitSortData(invoicePOArray, fromCount1, toCount1) }

    } else {
      var toCount1 = openPOFromCount - 1, currentPageNumber = openPOPageNumber - 1;
      var fromCount1 = toCount1 - openPOTotalData[currentPageNumber].length + 1;
      if (fromCount1 == NUMBER_ONE) {
        setOpenPOData(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOPreviousBtn: true } }))
        setOpenPODataSet(state => ({ ...state, openPOPaginationData: { ...state.openPOPaginationData, openPOPreviousBtn: true } }))
      }
      setOpenPOData(state => ({
        ...state, openPOTableData: openPOTotalData[currentPageNumber], openPOPageNumber: currentPageNumber,
        openPOPaginationData: { ...state.openPOPaginationData, openPOFromCount: fromCount1, openPOToCount: toCount1, openPONextBtn: false },
      }))
      setOpenPODataSet(state => ({
        ...state, openPOTableData: openPOTotalData[currentPageNumber], openPOPageNumber: currentPageNumber,
        openPOPaginationData: { ...state.openPOPaginationData, openPOFromCount: fromCount1, openPOToCount: toCount1, openPONextBtn: false },
      }))
      if (openPOActiveSortOrder == ASCENDING || openPOActiveSortOrder == DECENDING) { openPOSplitSortData(openPOArray, fromCount1, toCount1) }
    }
  }

  // For validating End Date
  const disabledEndDates = (current) => {
    if (!current) return false;
    const startDate = moment(startDateTime);
    var endDate = new Date(startDateTime);
    // endDate.setDate(endDate.getDate() + 90);
    endDate.setMonth(endDate.getMonth() + 3);
    return current.valueOf() < startDate.valueOf() || current.valueOf() > endDate.valueOf();
  }

  // sorting functionality for closed pos
  const closedPOUpdateKeyValue = (sortActiveValue, sortOrderValue, closedPOArray, fromCount, toCount) => {
    if (sortActiveValue) {

      setClosedPOData(state => ({ ...state, closedPOActiveElement: sortActiveValue }))
      setClosePODataSet(state => ({ ...state, closedPOActiveElement: sortActiveValue }))
    }
    if (sortOrderValue) {
      setClosedPOData(state => ({ ...state, closedPOActiveSortOrder: sortOrderValue }))
      setClosePODataSet(state => ({ ...state, closedPOActiveSortOrder: sortOrderValue }))
    }
    closedPOSortingFunction(sortActiveValue, sortOrderValue, closedPOArray, fromCount, toCount);
  }
  const closedPOSortingFunction = (sortActiveValue, sortOrderValue, closedPOArray, fromCount, toCount) => {
    if (sortOrderValue === DECENDING) {
      if (sortActiveValue === POID) closedPOArray.sort((x, y) => y.GSI2 - x.GSI2);
      if (sortActiveValue === SALESORDER) closedPOArray.sort((x, y) => y.SalesOrder - x.SalesOrder);
      if (sortActiveValue === CREATEDDATE) closedPOArray.sort((x, y) => new Date(y.GSI1SK) - new Date(x.GSI1SK));
    } else if (sortOrderValue === ASCENDING) {
      if (sortActiveValue === POID) closedPOArray.sort((x, y) => x.GSI2 - y.GSI2);
      if (sortActiveValue === SALESORDER) closedPOArray.sort((x, y) => x.SalesOrder - y.SalesOrder);
      if (sortActiveValue === CREATEDDATE) closedPOArray.sort((x, y) => new Date(x.GSI1SK) - new Date(y.GSI1SK));
    }
    closedPOSplitSortData(closedPOArray, fromCount, toCount)
  }
  const closedPOSplitSortData = (array, fromCount, toCount) => {
    var splitData = array.slice(fromCount - 1, toCount);
    setClosedPOData(state => ({ ...state, closedPOTableData: splitData }));
    setClosePODataSet(state => ({ ...state, closedPOTableData: splitData }));
  }

  // sorting functionality for invoice
  const invoicePOUpdateKeyValue = (sortActiveValue, sortOrderValue, invoicePOArray, fromCount, toCount) => {
    if (sortActiveValue) { setInvoicePOData(state => ({ ...state, invoicePOActiveElement: sortActiveValue })) }
    if (sortOrderValue) { setInvoicePOData(state => ({ ...state, invoicePOActiveSortOrder: sortOrderValue })) }
    invoicePOSortingFunction(sortActiveValue, sortOrderValue, invoicePOArray, fromCount, toCount);
  }
  const invoicePOSortingFunction = (sortActiveValue, sortOrderValue, invoicePOArray, fromCount, toCount) => {
    if (sortOrderValue === DECENDING) {
      if (sortActiveValue === POID) invoicePOArray.sort((x, y) => y.GSI2 - x.GSI2);
      if (sortActiveValue === INID) invoicePOArray.sort((x, y) => y.GSI2 - x.GSI2);
      if (sortActiveValue === CREATEDDATE) invoicePOArray.sort((x, y) => new Date(y.GSI1SK) - new Date(x.GSI1SK));
    } else if (sortOrderValue === ASCENDING) {
      if (sortActiveValue === POID) invoicePOArray.sort((x, y) => x.GSI2 - y.GSI2);
      if (sortActiveValue === INID) invoicePOArray.sort((x, y) => x.GSI2 - y.GSI2);
      if (sortActiveValue === CREATEDDATE) invoicePOArray.sort((x, y) => new Date(x.GSI1SK) - new Date(y.GSI1SK));
    }
    invoicePOSplitSortData(invoicePOArray, fromCount, toCount)
  }
  const invoicePOSplitSortData = (array, fromCount, toCount) => {
    var splitData = array.slice(fromCount - 1, toCount);
    setInvoicePOData(state => ({ ...state, invoicePOTableData: splitData }));
  }

  // sorting functionality for open pos
  const openPOUpdateKeyValue = (sortActiveValue, sortOrderValue, openPOArray, fromCount, toCount) => {
    if (sortActiveValue) {
      setOpenPOData(state => ({ ...state, openPOActiveElement: sortActiveValue }))
      setOpenPODataSet(state => ({ ...state, openPOActiveElement: sortActiveValue }))
    }
    if (sortOrderValue) {
      setOpenPOData(state => ({ ...state, openPOActiveSortOrder: sortOrderValue }))
      setOpenPODataSet(state => ({ ...state, openPOActiveSortOrder: sortOrderValue }))
    }
    openPOSortingFunction(sortActiveValue, sortOrderValue, openPOArray, fromCount, toCount);
  }
  const openPOSortingFunction = async (sortActiveValue, sortOrderValue, openPOArray, fromCount, toCount) => {
    if (sortOrderValue === DECENDING) {
      if (sortActiveValue === POID) openPOArray.sort((x, y) => y.item.GSI2 - x.item.GSI2);
      if (sortActiveValue === CREATEDDATE) openPOArray.sort((x, y) => new Date(y.item.GSI1SK) - new Date(x.item.GSI1SK));
    } else if (sortOrderValue === ASCENDING) {
      if (sortActiveValue === POID) openPOArray.sort((x, y) => x.item.GSI2 - y.item.GSI2);
      if (sortActiveValue === CREATEDDATE) openPOArray.sort((x, y) => new Date(x.item.GSI1SK) - new Date(y.item.GSI1SK));
    }
    openPOSplitSortData(openPOArray, fromCount, toCount);
  }
  const openPOSplitSortData = async (array, fromCount, toCount) => {
    var splitData = array.slice(fromCount - 1, toCount);
    setOpenPOData(state => ({ ...state, openPOTableData: splitData }));
    setOpenPODataSet(state => ({ ...state, openPOTableData: splitData }))
  }

  const disabledPreviousDates = (current) => { }
  const onModalSubmit = () => { }

  // Enable ASN modal
  const openModal = () => { setModalVisible(true); }
  const nameChange = (e) => { setCompanyName(e); }

  // const backToOrders = () => {
  //   setIsPOSeletected(false)
  // }

  return (!isPOSeletected ?
    <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
      <Row>
        <Col span='24'>
          <div className="card-container">
            <Tabs activeKey={tabKey} onTabClick={onTabChange} type="card" className="tabsMain">

              {OrdersTabs && OrdersTabs.map((item, i) =>
                i == 0 ? null : <TabPane tab={item} key={i + 1}>
                  <div className="bgWhite paddingtopbotom borderRadius5rem">
                    <Row className="displayFlex justifyContentCenter alignItemsCenter">
                      <Col md={{ span: '5' }} sm={{ span: '5' }} xs={{ span: '24' }} className="tableTitle paddingTop10Bottom10">
                        <h2 className="margin0">{item === OpenPOs ? OpenPOs : item === CLOSED_POS ? 'Closed POs' : `${company} Open Invoices`}</h2>
                      </Col>
                      <Col md={{ span: '10' }} sm={{ span: '10' }} xs={{ span: item === OpenPOs || item === CLOSED_POS ? "0" : "24" }} className="invoceCount respCommonPadding paddingTop10Bottom10">
                        {item === OpenPOs || item === CLOSED_POS ? null :
                          <>
                            <div className="displayFlex ">
                              <p className="invoiceNumbers bgBlue">{!loading ? totalAsnCount : <div className="loadingSpinnerStylesWhite"><Spin size="small" /></div>}</p>
                              <p className="invoiceStatus">ASN Ready</p>
                            </div>
                            <div className="displayFlex">
                              <p className="invoiceNumbers bgRed">{!loading ? totalAmendCount : <div className="loadingSpinnerStylesWhite"><Spin size="small" /></div>}</p>
                              <p className="invoiceStatus">Amendment</p>
                            </div> </>}
                      </Col>
                      <Col md={{ span: '9' }} sm={{ span: '9' }} xs={{ span: '24' }} className="datePickers paddingTop10Bottom10">
                        <div className="datesBlock">
                          <p>From </p>
                          <CustomDatePicker className="beforeStar datePickerLabel " onChange={handleStartDate}
                            value={startDateTime ? moment(startDateTime, DATE_PICKER_YEAR_MONTH_DATE) : undefined} placeholder='Select a date'
                            disabledDate={disabledPreviousDates} format={DATE_PICKER_YEAR_MONTH_DATE} datePickerStyles="datePickerStyles " />
                        </div>
                        <div className="datesBlock">
                          <p>To </p>
                          <CustomDatePicker className="beforeStar datePickerLabel " onChange={handleEndDate}
                            value={endDateTime ? moment(endDateTime, DATE_PICKER_YEAR_MONTH_DATE) : undefined} placeholder='Select a date'
                            disabledDate={disabledEndDates} format={DATE_PICKER_YEAR_MONTH_DATE} datePickerStyles="datePickerStyles" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span='24'>
                        {item === OpenPOs ?
                          <>{!loading ? <>
                            <OrdersTable siteCode={siteCode} data={openPOTableData} startDateTime={startDateTime} endDateTime={endDateTime} openPOArray={openPOArray}
                              activeSortOrder={openPOActiveSortOrder} activeElement={openPOActiveElement} updateKeyValue={openPOUpdateKeyValue}
                              from={openPOFromCount} to={openPOToCount} openPOStatusSetKeys={openPOStatusSetKeys} openPODataSet={openPODataSet} />
                            {openPOTableData && openPOTableData.length ? <CustomPaginationWithPages onPre={() => onPre(OPENPO, openPOPageNumber)} onNext={() => onNext(OPENPO, openPOPageNumber)}
                              from={openPOFromCount} to={openPOToCount} nextBtn={openPONextBtn} previousBtn={openPOPreviousBtn}
                              noOfRecordsPerPageChanged={(e) => openPONoOfRecordsPerPageChanged(e)} noOfRecordsPerPage={openPONoOfRecordsPerPage} showItems={openPOShowItems} dataLength={openPOToCount} /> : null}
                          </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>} </>

                          : item === CLOSED_POS ?
                            <>{!loading ? <>
                              <ClosedPOs siteCode={closedSiteCode} data={closedPOTableData} startDateTime={startDateTime} endDateTime={endDateTime} closedPOArray={closedPOArray}
                                activeSortOrder={closedPOActiveSortOrder} activeElement={closedPOActiveElement} updateKeyValue={closedPOUpdateKeyValue}
                                from={closedPOFromCount} to={closedPOToCount} closePODataSet={closePODataSet} />
                              {closedPOTableData && closedPOTableData.length ? <CustomPaginationWithPages onPre={() => onPre(CLOSEDPO, closedPOPageNumber)} onNext={() => onNext(CLOSEDPO, closedPOPageNumber)}
                                from={closedPOFromCount} to={closedPOToCount} nextBtn={closedPONextBtn} previousBtn={closedPOPreviousBtn}
                                noOfRecordsPerPageChanged={(e) => closedPONoOfRecordsPerPageChanged(e)} noOfRecordsPerPage={closedPONoOfRecordsPerPage} showItems={closedPOShowItems} dataLength={closedPOToCount} /> : null}
                            </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>} </>

                            : null}
                        {/* <>{item !== OpenPOs && item !== CLOSED_POS && !loading ? <>
                              <InvoiceTable data={invoicePOTableData} openModal={openModal} startDateTime={startDateTime} endDateTime={endDateTime}
                                activeSortOrder={invoicePOActiveSortOrder} activeElement={invoicePOActiveElement} updateKeyValue={invoicePOUpdateKeyValue}
                                from={invoicePOFromCount} to={invoicePOToCount} invoicePOArray={invoicePOArray} />
                              {invoicePOTableData && invoicePOTableData.length ? <CustomPaginationWithPages onPre={() => onPre(INVOICE, invoicePOPageNumber)} onNext={() => onNext(INVOICE, invoicePOPageNumber)}
                                from={invoicePOFromCount} to={invoicePOToCount} nextBtn={invoicePONextBtn} previousBtn={invoicePOPreviousBtn}
                                noOfRecordsPerPageChanged={(e) => invoicePONoOfRecordsPerPageChanged(e)} noOfRecordsPerPage={invoicePONoOfRecordsPerPage} showItems={invoicePOShowItems} dataLength={invoicePOToCount} /> : null}

                            </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>}
                            </>} */}
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </Col>
      </Row>
      <AsnDateModal isVisible={isModalVisible} onCancel={() => setModalVisible(false)} onSubmit={onModalSubmit} />
    </Dashboard> : <>
      {/* <OrdersActivity state={tempState} backToOrders={backToOrders} /> */}
    </>
  );
}

export default OrdersDashboard;
