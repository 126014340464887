import React from 'react';
import { Form, AutoComplete } from 'antd';
import "./index.scss";
const { Option } = AutoComplete;

const AutoCompleteSection = ({ label, placeholder, options, onSelect, onChange, errorText, errorMsg, value, onSearch, className, autoCompleteStyles, disabled }) => {
    const FormItem = Form.Item;
    return (<FormItem label={label} className={className} colon={false}>
        <AutoComplete className={autoCompleteStyles} onSearch={onSearch} placeholder={placeholder} onSelect={onSelect}
            disabled={disabled} value={value} onChange={onChange}>
            {options.map((item) => (<Option key={item} value={item}> {item} </Option>))}
        </AutoComplete>
        {errorMsg ? <p className="errorAutoCompleteSection">{errorText}</p> : null}
    </FormItem>)
}

export default AutoCompleteSection;