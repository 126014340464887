import React, { useState, useEffect, useCallback } from 'react';
import { Table, Input, InputNumber, Form, Typography, Select } from 'antd';
import './index.scss';
import { getLineItemID } from '../../../functions';
import { NO_KEY, TRUE_CONDITION, YES_KEY } from '../../../constants';
import EditIcon from '../../../images/editRecord.svg';
import UpdateIcon from '../../../images/updateIcon.svg';
import DeleteIcon from '../../../images/deleteIcon.svg';
import DefaultSorting from '../../../images/defaultSort.svg';
import AsendingSorting from '../../../images/asendingSort.svg';
import DecendingSorting from '../../../images/decendingSort.svg';
import { API } from 'aws-amplify';
const Option = Select.Option;

const ASCENDING = 'ascending';
const DECENDING = 'decending';
const S4HANAPARENTCODE = 'S4HANAParentCode';
const ISACTIVE = 'IsActive';
const CUSTOMERPRODUCT = 'CustomerProduct';
const SKUMAPPING = 'SKUMapping';
const GSI2 = 'GSI2';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    const inputNode = inputType === 'number' ? <InputNumber />
        : inputType === 'selection' ? <Select> {[<Option value={YES_KEY}> Yes </Option>, <Option value={NO_KEY}> No </Option>]}</Select>
            : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[{ required: true, message: `${title}` }]} className="editableTableError">
                    {inputNode}
                </Form.Item>
            ) : (children)}
        </td>
    );
};

const ProductEditableTable = ({ productData, updateProductData, data, handleSave }) => {
    const [form] = Form.useForm();
    const [tableData, setTableData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [activeSortOrder, setSortOrder] = useState();
    const [activeColumn, setActiveColumn] = useState();
    const [activeElement, setActiveElement] = useState();

    useEffect(() => { if (data) setTableRowData(data); }, [data])

    const setTableRowData = (data) => {
        const tableData = [];
        data && data.map((item, i) => {
            const { SKUMapping, CustomerProduct } = item;
            var obj = {
                key: i,
                GTIN: <span className={SKUMapping.IsActive || SKUMapping.IsActive === TRUE_CONDITION ? null : 'clrRed'} >{SKUMapping.GSI2}</span>,
                division: getLineItemID(SKUMapping.SK), hanaParentCode: SKUMapping.S4HANAParentCode, hanaCode: SKUMapping.S4HANACode,
                hanaProdDesc: SKUMapping.HANAProductDescription, MRP: SKUMapping.MRP ? SKUMapping.MRP : '-', caseSize: SKUMapping.CaseSize,
                RRL: CustomerProduct.IsActive || CustomerProduct.IsActive === TRUE_CONDITION ? YES_KEY : NO_KEY,
                RRLArticle: CustomerProduct.GSI2, RRLArticleDesc: CustomerProduct.RRLArticleDescription,
            }
            tableData.push(obj);
        })
        setTableData(tableData)
    }

    const isEditing = (record) => record.key === editingKey;
    const cancel = () => { setEditingKey(''); };

    const edit = (record) => {
        form.setFieldsValue({
            division: '',
            GTIN: '',
            hanaParentCode: '',
            hanaCode: '',
            MRP: '',
            caseSize: '',
            hanaProdDesc: '',
            RRL: '',
            RRLArticle: '',
            RRLArticleDesc: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...tableData];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setTableData(newData);
                handleSave(newData[index]);
                setEditingKey('');
            } else {
                newData.push(row);
                setTableData(newData);
                handleSave(newData[index]);
                setEditingKey('');
            }
        } catch (errInfo) { console.log('Validate Failed:', errInfo); }
    };

    const handleSorting = (name, info, key) => {
        // return Number(x.SKUMapping) - Number(y.SKUMapping);
        // return (x.SKUMapping === y.SKUMapping) ? 0 : x ? -1 : 1;
        setSortOrder(info)
        setActiveColumn(key)
        setActiveElement(name)
        if (info === DECENDING) {
            if (name === SKUMAPPING) productData.sort((x, y) => y.SKUMapping[key] - x.SKUMapping[key]);
            else if (name === CUSTOMERPRODUCT) productData.sort((x, y) => y.CustomerProduct[key] - x.CustomerProduct[key]);
            updateProductData(productData)
        } else if (info === ASCENDING) {
            if (name === SKUMAPPING) productData.sort((x, y) => x.SKUMapping[key] - y.SKUMapping[key]);
            else if (name === CUSTOMERPRODUCT) productData.sort((x, y) => x.CustomerProduct[key] - y.CustomerProduct[key]);
            updateProductData(productData)
        }
    }

    const getSorterIcon = useCallback((info, key) => {
        return activeColumn == key && activeSortOrder == ASCENDING && info == activeElement ?
            <span onClick={() => handleSorting(info, DECENDING, key)}><img src={AsendingSorting} className="cursorpointer" /></span> :
            activeColumn == key && activeSortOrder == DECENDING && info == activeElement ?
                <span onClick={() => handleSorting(info, ASCENDING, key)}><img src={DecendingSorting} className="cursorpointer" /></span> :
                <span onClick={() => handleSorting(info, ASCENDING, key)} > <img src={DefaultSorting} className="cursorpointer" /> </span>
    }, [activeColumn, activeSortOrder, activeElement])

    const columns = [{
        title: 'Division',
        dataIndex: 'division',
        width: '5%'
    }, {
        title: <p className="margin0">GTIN {getSorterIcon(SKUMAPPING, ISACTIVE)} </p>,
        dataIndex: 'GTIN',
        width: '8%'
    }, {
        title: <p className="margin0">S4 HANA Parent Code {getSorterIcon(SKUMAPPING, S4HANAPARENTCODE)} </p>,
        dataIndex: 'hanaParentCode',
        width: '10%'
    }, {
        title: 'S4 HANA Code',
        dataIndex: 'hanaCode',
        width: '8%'
    }, {
        title: 'Hana Prod Desc',
        dataIndex: 'hanaProdDesc',
        width: '15%'
    }, {
        title: 'MRP',
        dataIndex: 'MRP',
        width: '4%'
    }, {
        title: 'Case Size',
        dataIndex: 'caseSize',
        width: '5%'
    }, {
        title: <p className="margin0">RRL {getSorterIcon(CUSTOMERPRODUCT, ISACTIVE)} </p>,
        dataIndex: 'RRL',
        editable: true,
        className: 'productTable_RRL productTable_fixedRight',
        width: '6%'
    }, {
        title: <p className="margin0">RRL Article {getSorterIcon(CUSTOMERPRODUCT, GSI2)} </p>,
        dataIndex: 'RRLArticle',
        editable: true,
        className: 'productTable_RRLArticle productTable_fixedRight',
        width: '8%'
    }, {
        title: 'RRL Article Desc',
        dataIndex: 'RRLArticleDesc',
        editable: true,
        className: 'productTable_RRLArticleDesc productTable_fixedRight',
        width: '15%'
    }, {
        title: 'Edit',
        dataIndex: 'operation',
        className: 'productTable_edit productTable_fixedRight',
        width: '4%',
        align:'center',
        render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (<div className="displayFlex justifyContentCenter alignItemsCenter">
                <img src={UpdateIcon} alt="edit icon" onClick={() => save(record.key)} className="editIcon" />
                <img src={DeleteIcon} alt="edit icon" onClick={() => cancel()} className="editIcon" />
            </div>)
                : (<Typography.Link disabled={editingKey !== ''}>
                    <img src={EditIcon} alt="edit icon" onClick={() => edit(record)} className="editIcon" />
                </Typography.Link>);
        },
    }];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) { return col; }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'RRLArticle' ? 'number' : col.dataIndex === 'RRL' ? 'selection' : 'text',
                dataIndex: col.dataIndex,
                title: col.dataIndex === 'RRLArticle' ? `Please enter a RRL article!` : 'Please enter a RRL article description!',
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table components={{ body: { cell: EditableCell } }} pagination={false} scroll={{ x: 1700 }}
                className="primary-color-table productMappingTable" rowClassName="editable-row" dataSource={tableData} columns={mergedColumns} />
        </Form>
    );
};

export default ProductEditableTable;

