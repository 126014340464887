import React from 'react';
import { Table } from 'antd';
import { getInvoiceID, getItems } from '../../../../functions';

const ASNSentLineItems = ({ data }) => {
    // Mapping Data For Table
    const dataMapper = [];
    if (data && data.length) {
        data.map((item, i) => {
            var obj = {
                key: i,
                lineItem: getItems(item.SK, 2) || '-',
                invoiceID: getItems(item.SK, 1) || '-',
                customerID: item.CustomerProductID || '-',
                SystemSKUDescription: item.SystemSKUDescription || '-',
                SystemSKUCode: item.SystemSKUCode || '-',
                mrp: item.MRP || '-',
                noOfCases: item.NoofCases || '-',
                deliveryQuantity: item.DeliveryQuantity || '-'
            }
            dataMapper.push(obj);
        })
    }

    const columns = [{
        title: 'Item ID',
        dataIndex: 'lineItem',
        key: 'lineItem',
    }, {
        title: 'Invoice ID',
        dataIndex: 'invoiceID',
        key: 'invoiceID',
    }, {
        title: 'Customer ProductId',
        dataIndex: 'customerID',
        key: 'customerID',
    }, {
        title: 'MRP',
        dataIndex: 'mrp',
        key: 'mrp',
    }, {
        title: 'No of Cases',
        dataIndex: 'noOfCases',
        key: 'noOfCases',
    }, {
        title: 'Delivery Quantity',
        dataIndex: 'deliveryQuantity',
        key: 'deliveryQuantity',
    }, {
        title: 'SKU Code',
        dataIndex: 'SystemSKUCode',
        key: 'SystemSKUCode',
    }, {
        title: 'Article Desc',
        dataIndex: 'SystemSKUDescription',
        key: 'SystemSKUDescription',
    }];

    return (<Table dataSource={dataMapper} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default ASNSentLineItems;