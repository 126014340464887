import React, { useCallback, useState, useEffect } from 'react';
import { Button, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { COMPONENTFROM_CLOSEDPOS, DATE_PICKER_YEAR_MONTH_DATE } from '../../../constants';
import CustomPopover from '../../popOver';
import { getOnlyID } from '../../../functions';
import { ORDERS_PODATA } from '../../../routingUrl';
import DefaultSorting from '../../../images/defaultSort.svg';
import AsendingSorting from '../../../images/asendingSort.svg';
import DecendingSorting from '../../../images/decendingSort.svg';

import ReactExport from "react-data-export";

const POID = 'poID';
const SALESORDER = 'SalesOrder';
const CREATEDDATE = 'createdDate';
const ASCENDING = 'ascending';
const DECENDING = 'decending';

const ClosedPOs = ({ siteCode,data, startDateTime, endDateTime, activeSortOrder, activeElement, updateKeyValue, closedPOArray, from, to, closePODataSet }) => {
    const history = useHistory();
    const [tableData, setTableData] = useState([]);

    const[res, setres] = useState([]);
    const [flag, setFlag] = useState(false);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;    

    useEffect(() => { 
        if (data) setTableRowData(data);
    }, [data, res,siteCode])    
    const checkFunc = async(e, itemData) => {
        if(e.currentTarget.value === "checkbox_Parent" && e.currentTarget.checked === true){

            var checkboxes = document.getElementsByName('COMPLETED');
            for (var i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i] != e)
                    checkboxes[i].checked = e.currentTarget.checked;
            }
            let response = [];
            data?.forEach((element) => {
                response = [...response, [element]];                
            })
            setres(response);
            setFlag(true);
        }
        else if(e.currentTarget.value === "checkbox_Parent" && e.currentTarget.checked === false){

            var checkboxes = document.getElementsByName('COMPLETED');
            for (var i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i] != e)
                    checkboxes[i].checked = e.currentTarget.checked;
            }
            setres([]);
            setFlag(false);
        }
        else if(e.currentTarget.value === "checkbox" && e.currentTarget.checked === true){
            let item = [];
            data?.filter((items)=>{
                if (items.SK === itemData) {
                    item = [items];                    
                }
            })
            setFlag(true);
            setres([...res, item])
        }
        else if(e.currentTarget.value === "checkbox" && e.currentTarget.checked === false){
            
            res.forEach((item, index) => {
                if(item[0].SK === itemData){
                    res.splice(index, 1);
                }
            })
            let newResponse = [];
            //creating a new array after removing the element
            res.map((element, index)=>{
                newResponse = [...newResponse, element]
            })
            setres(newResponse);

            var checkboxes = document.querySelectorAll('input[name="COMPLETED"]:checked');
            if (checkboxes.length === 0){
                setFlag(false);
            }
            else{
                setFlag(true);
            }
        }

    }

    const setTableRowData = (data) => {
        // Mapping Data to the table
        const dataMapper = []
        if (data && data.length) {
            data.map((item, i) => {
                var obj = {
                    checkBox: 
                        <>                        
                            <input type="checkbox" id='checkbox' name="COMPLETED" value="checkbox" onClick={(e) => {checkFunc(e, item.SK)} }/>                        
                        </>,
                    key: i,
                    siteCode: item.SiteCode,
                    createdDate: <CustomPopover onNav={() => navToOrdersActivity(item)} data={<span className="cursorpointer">{` ${moment(item.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}`}</span>} content={popOverContent(item, item.Status)} />,
                    poID: <CustomPopover onNav={() => navToOrdersActivity(item)} data={<span className="cursorpointer">{getOnlyID(item.SK)}</span>} content={popOverContent(item, item.Status)} />,
                    SalesOrder: <CustomPopover onNav={() => navToOrdersActivity(item)} data={<span className="cursorpointer">{item.SalesOrder}</span>} content={popOverContent(item, item.Status)} />,
                    status: <CustomPopover onNav={() => navToOrdersActivity(item)} data={<span className="cursorpointer capitalizeAllCaps">{item.Status && item.Status.toLowerCase()}</span>} content={popOverContent(item, item.Status)} />
                }
                dataMapper.push(obj);
            })
        }
        setTableData(dataMapper)
    }

    // For navigating to inner pages based on status
    const navToOrdersActivity = (item) => {
        // history.push({ pathname: `/orders/${item.SK}`, state: { SK: "Item#", PK: item.PK + "#" + item.SK, currentTab: "3", wholeData:item } });
        history.push({
            pathname: ORDERS_PODATA, state: {
                SK: item.SK, PK: item.PK, currentTab: "3", wholeData: item, componentFrom: COMPONENTFROM_CLOSEDPOS, startDateTime, endDateTime, closePODataSet: closePODataSet
            }
        });
    }

    const popOverContent = (item, status) => {
        return <div className="popupContent"> <h2 className="clrRed capitalize">{status && status.toLowerCase()}</h2> </div>
    }

    const handleSorting = (name, info, closedPOArray, from, to) => { updateKeyValue(name, info, closedPOArray, from, to) }

    const getSorterIcon = useCallback((info, closedPOArray, from, to) => {
        return activeSortOrder == ASCENDING && info == activeElement ? <span onClick={() => handleSorting(info, DECENDING, closedPOArray, from, to)}><img src={AsendingSorting} className="cursorpointer" /></span> :
            activeSortOrder == DECENDING && info == activeElement ? <span onClick={() => handleSorting(info, ASCENDING, closedPOArray, from, to)}><img src={DecendingSorting} className="cursorpointer" /></span> :
                <span onClick={() => handleSorting(info, ASCENDING, closedPOArray, from, to)} > <img src={DefaultSorting} className="cursorpointer" /> </span>
    }, [activeSortOrder, activeElement])

    const columns = [
    {
        title: <input type="checkbox" value="checkbox_Parent" onChange={(e) =>{checkFunc(e,'')}}/>,
        dataIndex: 'checkBox',
        key: 'checkBox',
        width: '4%'
    }, {
        title: <p className="margin0">PO ID {tableData.length ? getSorterIcon(POID, closedPOArray, from, to) : null} </p>,
        dataIndex: 'poID',
        key: 'poID',
    },
    {
        title: <p className="margin0">Site Code{tableData.length ? getSorterIcon(CREATEDDATE, closedPOArray, from, to) : null} </p>,
        dataIndex: 'siteCode',
        key: 'siteCode',
    }, {
        title: <p className="margin0">Created Date {tableData.length ? getSorterIcon(CREATEDDATE, closedPOArray, from, to) : null} </p>,
        dataIndex: 'createdDate',
        key: 'createdDate',
    }, {
        title: <p className="margin0">Sales Order {tableData.length ? getSorterIcon(SALESORDER, closedPOArray, from, to) : null} </p>,
        dataIndex: 'SalesOrder',
        key: 'SalesOrder',
    }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    }, {
        title: <>    
                <ExcelFile filename={`Multiple_PO_Details_${new Date().getFullYear() + '_' + (new Date().getMonth() + 1) + '_' + new Date().getDate()}`} 
                element={<Button type='button' disabled={flag === true ? false : true}>Download</Button>}>
                    {res?.map((res, index) => 
                    <ExcelSheet key={index} data={res} name={`PO_Sheet_${res[0].GSI2}`}>                    
                        <ExcelColumn label="Created Date" value={(col) => col.GSI1SK.split('T')[0]}/>                        
                        <ExcelColumn label="PO ID" value={(col) => col.GSI2}/>                                                                        
                        <ExcelColumn label="Sales Order" value={(col) => col.SalesOrder}/>                        
                        <ExcelColumn label="Status" value={(col) => col.Status}/>                        
                    </ExcelSheet>
                    )}
                </ExcelFile>
                </>,
        dataIndex: 'download',
        key: 'download',
        width: '5%'
    }];

    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default ClosedPOs;