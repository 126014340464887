import React from 'react';
import { Table } from 'antd';
import { getLineItemID } from '../../../functions';

const PendingInvoicesTable = ({ data }) => {
    const tableData = [];

    data && data.map((item, i) => {
        var obj = {
            key: i,
            liItemID: item.SK ? getLineItemID(item.SK) : "--",
            ean: item.EAN ? item.EAN : "--",
            itemName: item.SystemSKUDescription ? item.SystemSKUDescription : "--",
            mrp: item.MRP ? item.MRP : "--",
            // upMrp: item.UpdatedMRP,
            material: item.SystemSKUCode ? item.SystemSKUCode : "--",
            CustomerProductID: item.CustomerProductID ? item.CustomerProductID : '--',
            status : item.Message ? item.Message : 'So Created Succesfully '+ item.SalesOrder 
        }
        tableData.push(obj);
    })

    const columns = [{
        title: 'LineItem ID',
        dataIndex: 'liItemID',
        key: 'liItemID',
    }, {
        title: 'EAN',
        dataIndex: 'ean',
        key: 'ean',
    }, {
        title: 'Customer Product ID',
        dataIndex: 'CustomerProductID',
        key: 'CustomerProductID',
    }, {
        title: 'Article Desc',
        dataIndex: 'itemName',
        key: 'itemName',
    }, {
        title: 'MRP',
        dataIndex: 'mrp',
        key: 'mrp',
    }, {
        title: 'Material',
        dataIndex: 'material',
        key: 'material',
    },{
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    }];
    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default PendingInvoicesTable;