import React from 'react';
import { Table } from 'antd';
import { getLineItemID } from '../../../functions';

const EanLookUpTable = ({ data }) => {

    const tableData = [];
    data && data.map((item, i) => {
        var obj = {
            key: i,
            liItemID: item.SK ? getLineItemID(item.SK) : "--",
            ean: item.EAN ? item.EAN : "--",
            siteCode: item.SiteCode ? item.SiteCode : "--",
            plantMaterial: item.Plant ? item.Plant : "--",
            message: item.Message ? item.Message : "--",
            material: item.SystemSKUCode ? item.SystemSKUCode : "--",
            CustomerProductID: item.CustomerProductID ? item.CustomerProductID : '--'
        }
        tableData.push(obj);
    })

    const columns = [{
        title: 'LineItem ID',
        dataIndex: 'liItemID',
        key: 'liItemID',
    }, {
        title: 'EAN',
        dataIndex: 'ean',
        key: 'ean',
    }, {
        title: 'Customer Product ID',
        dataIndex: 'CustomerProductID',
        key: 'CustomerProductID',
    }, {
        title: 'Site Code',
        dataIndex: 'siteCode',
        key: 'siteCode',
    }, {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
    }];
    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default EanLookUpTable;