import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Spin, message, Modal } from 'antd';
import Dashboard from '../dashboard';
import { useHistory } from 'react-router-dom';
import { MASTER_DATA_CREATE } from '../../utils/routingUrl';
import CustomSearchBar from '../../utils/components/customeInputFields/searchField';
import { queryMappedProduct } from '../../graphql/queries';
import { uploadMasterData } from '../../graphql/mutations';
import { API } from 'aws-amplify';
import CustomPaginationWithPages from '../../utils/components/pagination/index1';
import MasterTable from '../../utils/components/tables/masterTable';
import CustomButton from '../../utils/components/buttons';
import { getItems } from '../../utils/functions';
import { SALES_PERSON_KEY } from '../../utils/constants';
import UploadFile from '../../utils/components/MediaUpload/uploadFie';
const NUMBER_ONE = 1;
const NUMBER_TEN = 10;
const ASCENDING = 'ascending';
const DECENDING = 'decending';

const MasterDataDashboard = (props) => {
    const history = useHistory();
    const inititalPginationState = {
        noOfRecordsPerPage: NUMBER_TEN,
        nextBtn: true,
        previousBtn: true,
        fromCount: NUMBER_ONE,
        toCount: NUMBER_TEN,
        showItems: [10, 20, 50, 100]
    }
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('TCPLParentCode');
    const [productArray, setProductArray] = useState([]);
    const [productData, setProductData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState('');
    const [searchValue, setSearchValue] = useState("");
    const [companyName, setCompanyName] = useState(sessionStorage.getItem('companySK'));
    const [nextToken, setNextToken] = useState(null);
    const [searchNextToken, setSearchNextToken] = useState(null);
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));

    const [activeSortOrder, setActiveSortOrder] = useState();
    const [activeElement, setActiveElement] = useState();
    const [state, setState] = useState({ modalVisible: false, updatedObj: {} })
    const { modalVisible, updatedObj } = state;
    // pagination initial state
    const [paginationData, setPaginationData] = useState(inititalPginationState);
    const { noOfRecordsPerPage, nextBtn, previousBtn, fromCount, toCount, showItems } = paginationData;
    const [uploadData, setUploadData] = useState({
        uploadVisible: false,
        data: [],
        customerId: '',
        isModalVisible: false,
        fileName: ''
    })
    const { uploadVisible, data, customerId, isModalVisible, fileName } = uploadData;

    // useEffect(() => { getProductData(NUMBER_ONE, null, noOfRecordsPerPage); }, [])
    useEffect(() => { getProductData(NUMBER_ONE, null, noOfRecordsPerPage); }, [companyName]);

    const getProductData = async (pageNumber = NUMBER_ONE, nextToken, noOfRecordsPerPage, fCount, tCount) => {
        try {
            setLoading(true);
            const apiData = await API.graphql({
                query: queryMappedProduct,
                variables: { CustomerPayerID: companyName, first: noOfRecordsPerPage, after: nextToken }
            });
            var data = apiData.data.queryMappedProduct;
            setPageNumber(pageNumber);
            setProductData(state => ({ ...state, [pageNumber]: data.items }))
            setTableData(data.items)
            setNextToken(data.nextToken);
            var productArry = [...productArray, ...data.items];
            setProductArray(productArry);
            if (activeSortOrder == ASCENDING || activeSortOrder == DECENDING) { sortingFunction(activeElement, activeSortOrder, productArry, fCount, tCount) }

            if (data.nextToken) setPaginationData(state => ({ ...state, nextBtn: false }))
            else if (data.nextToken == null) {
                var toCount1 = toCount + data.items.length;
                setPaginationData(state => ({ ...state, nextBtn: true, toCount: toCount1 }))
            }
        } catch (error) { message.error('Invalid Data') }
        setLoading(false);
    }

    const noOfRecordsPerPageChanged = (number) => {
        setProductData({});
        setPaginationData(state => ({ ...state, noOfRecordsPerPage: number, toCount: number, fromCount: NUMBER_ONE, previousBtn: true, nextBtn: false }))
        getProductData(NUMBER_ONE, null, number)
    }

    // to detch next Data
    const onNext = () => {
        var fromCount1 = toCount + NUMBER_ONE;
        var toCount1 = toCount + noOfRecordsPerPage;
        var currentPageNumber = pageNumber + NUMBER_ONE;

        if (productData[currentPageNumber] == undefined) {
            setPaginationData(state => ({ ...state, toCount: toCount1 }));
            getProductData(currentPageNumber, nextToken, noOfRecordsPerPage, fromCount1, toCount1);
        } else {
            setTableData(productData[currentPageNumber]);
            setPageNumber(currentPageNumber)
            if (currentPageNumber == Object.keys(productData).length && nextToken == null) {
                var toCount2 = toCount + productData[currentPageNumber].length;
                setPaginationData(state => ({ ...state, nextBtn: true, toCount: toCount2 }))
            } else setPaginationData(state => ({ ...state, toCount: toCount1 }))
            if (activeSortOrder == ASCENDING || activeSortOrder == DECENDING) { splitSortData(productArray, fromCount1, toCount1) }
        }
        setPaginationData(state => ({ ...state, fromCount: fromCount1, previousBtn: false }))
    }

    // after click on Previous icon
    const onPre = () => {
        var fromCount1 = fromCount - noOfRecordsPerPage;
        var toCount1 = fromCount - 1;
        var currentPageNumber = pageNumber - 1;
        if (fromCount1 == NUMBER_ONE) setPaginationData(state => ({ ...state, previousBtn: true }))
        setPaginationData(state => ({ ...state, fromCount: fromCount1, toCount: toCount1, nextBtn: false }))
        setTableData(productData[currentPageNumber])
        setPageNumber(currentPageNumber)

        if (activeSortOrder == ASCENDING || activeSortOrder == DECENDING) { splitSortData(productArray, fromCount1, toCount1) }
    }

    const handleValueChange = (info, e) => {
        if (info === 'searchValue') {
            setSearchValue(e.target.value);
            if (e.target.value === "") {
                setPaginationData(state => ({ ...state, noOfRecordsPerPage: NUMBER_TEN, nextBtn: false, previousBtn: true, fromCount: NUMBER_ONE, toCount: NUMBER_TEN }))
                getProductData(1, null, NUMBER_TEN);
            }
        } else setType(e);
    }

    useEffect(() => { if (searchNextToken !== null) onSearch(searchValue, searchNextToken); }, [searchNextToken]);

    // fetching Data while click on search
    const onSearch = async (searchValue, nextToken) => {
        try {
            setLoading(true);
            if (searchValue) {
                setTableData([]);
                const apiData = await API.graphql({
                    query: queryMappedProduct,
                    variables: { CustomerPayerID: companyName, first: 100, after: nextToken, filter: { MetaInfo: { contains: searchValue.trim() } } }
                })
                var data = apiData.data.queryMappedProduct;
                let totalData = [];
                if (nextToken === null) totalData = data.items;
                else totalData = [...tableData, ...data.items];
                setTableData(totalData);
                setSearchNextToken(data.nextToken);
            }
        } catch (error) { message.error('Invalid Data') }
        setLoading(false);
    }

    const createMasterData = () => { history.push(MASTER_DATA_CREATE) }

    const updateKeyValue = (sortActiveValue, sortOrderValue, productArray, fromCount, toCount) => {
        if (sortActiveValue) { setActiveElement(sortActiveValue); }
        if (sortOrderValue) { setActiveSortOrder(sortOrderValue); }
        sortingFunction(sortActiveValue, sortOrderValue, productArray, fromCount, toCount);
    }

    const sortingFunction = (sortActiveValue, sortOrderValue, productArray, fromCount, toCount) => {
        if (sortOrderValue === DECENDING) {
            if (sortActiveValue === 'TCPLPSKU') productArray.sort((x, y) => getItems(y.PK, 0) - getItems(x.PK, 0));
            if (sortActiveValue === 'TCPLSSKU') productArray.sort((x, y) => getItems(y.PK, 1) - getItems(x.PK, 1));
            if (sortActiveValue === 'CustProdID') productArray.sort((x, y) => getItems(y.GSI1SK, 1) - getItems(x.GSI1SK, 1));
            if (sortActiveValue === 'CustDC') productArray.sort(function (x, y) {
                return /[A-Za-z]/.test(getItems(y.GSI2, 1)) - /[A-Za-z]/.test(getItems(x.GSI2, 1)) || getItems(y.GSI2, 1).charCodeAt(0) - getItems(x.GSI2, 1).charCodeAt(0);
            });
        } else if (sortOrderValue === ASCENDING) {
            if (sortActiveValue === 'TCPLPSKU') productArray.sort((x, y) => getItems(x.PK, 0) - getItems(y.PK, 0));
            if (sortActiveValue === 'TCPLSSKU') productArray.sort((x, y) => getItems(x.PK, 1) - getItems(y.PK, 1));
            if (sortActiveValue === 'CustProdID') productArray.sort((x, y) => getItems(x.GSI1SK, 1) - getItems(y.GSI1SK, 1));
            if (sortActiveValue === 'CustDC') productArray.sort(function (x, y) {
                return /[A-Za-z]/.test(getItems(x.GSI2, 1)) - /[A-Za-z]/.test(getItems(y.GSI2, 1)) || getItems(x.GSI2, 1).charCodeAt(0) - getItems(y.GSI2, 1).charCodeAt(0);
            });
        }
        splitSortData(productArray, fromCount, toCount)
    }

    const splitSortData = (array, fromCount, toCount) => {
        Object.keys(productData).map(function (key, index) {
            var splitData = array.slice(fromCount - 1, toCount);
            productData[key] = splitData;
            setTableData(splitData);
        });
    }

    const nameChange = (e) => { setCompanyName(e); }

    const handleUploadChange = (name, e, fileName) => {
        if (e.length === 0) setUploadData(state => ({ ...state, [name]: {} }))
        else setUploadData(state => ({ ...state, [name]: e, fileName }))
    }

    const handleSubmitOK = async () => {
        setUploadData(state => ({ ...state, isModalVisible: true }))
        try {
            if (data.length > 0) {
                let tempData = [...data], sendData = [], count = 1;
                let dataLength = Math.ceil(tempData.length / 100);
                if (tempData.length > 100) {
                    while (count <= dataLength && tempData.length > 100) {
                        sendData = tempData.slice(0, 99);
                        let ApiData = await API.graphql({ query: uploadMasterData, variables: { customerId, Data: sendData } });
                        if (ApiData?.data?.uploadMasterData?.statusCode == 200) {
                            count = count + 1;
                            tempData.splice(0, 100);
                        }
                    }
                }
                if (tempData.length < 100) {
                    sendData = tempData;
                    let ApiData = await API.graphql({ query: uploadMasterData, variables: { customerId, Data: sendData } });
                    let response = ApiData.data.uploadMasterData;
                    if (response.statusCode == 200) message.success('Data uploaded successfully!');
                    else message.error(response.body);
                }
            }
            setUploadData(state => ({ ...state, uploadVisible: false, isModalVisible: false, data: [], fileName: '' }))
        } catch (error) { message.error('Invalid Data') }
    }

    const uploadFile = async () => {
        setUploadData(state => ({
            ...state, uploadVisible: true, customerId: companyName
        }))
    }

    const handleUploadCancel = () => { setUploadData({ ...state, uploadVisible: false, data: [], fileName: '' }) }

    return (
        <Dashboard onCompanyNameChange={(e) => nameChange(e)}>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <Row className="displayFlex justifyContentCenter alignItemsCenter paddingTop10Bottom10">
                        <Col lgmd={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="tableTitle">
                            <h2 className="margin0 heading18Bold">Master Data </h2>
                        </Col>
                        <Col lgmd={{ span: '12' }} sm={{ span: '12' }} xs={{ span: '24' }} className="create-user">
                            <div className="displayFlex justifyContentFlexEnd">
                                {/* <DropDownSelect value={type || undefined} onChange={(e) => { handleValueChange("type", e) }} selectStyles=""
                                    options={[<Option value={'TCPLParentCode'}> TCPL Parent Code </Option>, <Option value={'Customer DC Code'}> Customer DC Code </Option>]} /> */}
                                <CustomSearchBar placeholder="Search here" className={"width200"} value={searchValue}
                                    inputClassname="Search-main" onChange={(e) => { handleValueChange('searchValue', e) }} onSearch={e => onSearch(searchValue, searchNextToken)} />
                                {userDetails.GSI1PK !== SALES_PERSON_KEY ?
                                    <div className="paddingLeft20">
                                        <CustomButton buttonLabel="Add New" className="createUserBtn alignItemsRight" handleSubmit={createMasterData} disabled={true} />
                                    </div>
                                    : null}
                                <div className="paddingLeft20">
                                    <CustomButton buttonLabel="Upload Master Data" className="createUserBtn alignItemsRight" handleSubmit={uploadFile} disabled={true}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ span: '24' }} className="paddingTop10">
                            <>{!loading ? <>
                                <MasterTable data={tableData} productArray={productArray} activeSortOrder={activeSortOrder}
                                    activeElement={activeElement} updateKeyValue={updateKeyValue} fromCount={fromCount} toCount={toCount} />
                                {tableData && tableData.length && searchValue === "" ? <CustomPaginationWithPages onPre={() => onPre()} onNext={() => onNext()} noOfRecordsPerPageChanged={(e) => noOfRecordsPerPageChanged(e)}
                                    from={fromCount} to={toCount} nextBtn={nextBtn} previousBtn={previousBtn} noOfRecordsPerPage={noOfRecordsPerPage} showItems={showItems} dataLength={toCount} /> : null}
                            </> : <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>} </>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal visible={uploadVisible} onOk={handleSubmitOK} onCancel={handleUploadCancel} className='FilterModal' footer={null} width={'80vh'} centered>
                <h3 className='paddingBottom10'>Upload Master Data</h3>
                <UploadFile UploadedFile={(url, name) => { handleUploadChange('data', url, name) }} file={data} fileName={fileName} />

                <div className="buttonsInline paddingTop10">
                    <CustomButton handleSubmit={handleUploadCancel} className="clearFilter" buttonLabel="Cancel" />
                    <CustomButton handleSubmit={handleSubmitOK} className="filterButton" buttonLabel="Ok" />
                </div>
            </Modal>
            <Modal visible={isModalVisible} className="loadingModal">
                <div className="textAlignCenter">
                    <h2 className="colorWhite">Please wait...</h2>
                </div>
            </Modal>
        </Dashboard>
    );
}

export default MasterDataDashboard;
