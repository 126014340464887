import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import Dashboard from '../dashboard';
import moment from 'moment';
import '../orders/features/index.scss';
import { queryInvoiceItemsByInvoiceID, queryLineItemsByPOID } from '../../graphql/queries';
import { API } from 'aws-amplify';
import { DATE_PICKER_YEAR_MONTH_DATE, SEARCHBY_OPENINVOICE } from '../../utils/constants';
import { getItems, getOnlyID } from '../../utils/functions';
import InvoiceByID from '../../utils/components/tables/searchPage/invoice';
import POByID from '../../utils/components/tables/searchPage/openPO';
import { ORDERS_DASHBOARD, ORDERS_PODATA } from '../../utils/routingUrl';
import CustomNavigator from '../../utils/components/backToOrders';

const SearchedData = (props) => {
    const location = useLocation();
    const [invoiceDetails, setInvoiceDetails] = useState([]);
    const [searchedData, setSearchedData] = useState(location.state ? location.state.wholeData : "");
    const history = useHistory();
    const [currentTab, setCurrentTab] = useState("1");
    const [loading, setLoading] = useState(true);
    const [nextToken, setNextToken] = useState(null);
    const [details, setDetails] = useState({});

    useEffect(() => {
        var { SK, PK, currentTab, wholeData } = location.state ? location.state : "";
        if (SK == "Item#") getPOByID(SK, PK);
        else getInvoiceByID(SK, PK);
        setCurrentTab(currentTab);
        setDetails({ sk: SK, pk: PK })
        setSearchedData(wholeData);
    }, [location.state])

    // useEffect(() => {
    //     var { SK, PK, currentTab, wholeData } = location.state ? location.state : "";
    //     if (SK == "Item#") getPOByID(SK, PK, 'locationUpdate');
    //     else getInvoiceByID(SK, PK, 'locationUpdate');
    //     setCurrentTab(currentTab);
    //     setDetails({ sk: SK, pk: PK })
    //     setSearchedData(wholeData);
    //     console.log("use-location")
    // }, [location.state])

    useEffect(() => {
        if (nextToken !== null) {
            var { SK, PK, wholeData } = location.state ? location.state : "";
            if (SK == "Item#") getPOByID(SK, PK);
            else getInvoiceByID(SK, PK);
            setDetails({ sk: SK, pk: PK })
            setSearchedData(wholeData);
        }
    }, [nextToken])

    // Fetching Data Based on Status
    const getInvoiceByID = async (SK, PK, from) => {
        setLoading(true);
        const apiData = await API.graphql({ query: queryInvoiceItemsByInvoiceID, variables: { SK: SK, PK: PK, first: 100, after: nextToken } });
        // if (from === 'locationUpdate') setInvoiceDetails(apiData.data.queryInvoiceItemsByInvoiceID.items);
        // else setInvoiceDetails([...invoiceDetails, ...apiData.data.queryInvoiceItemsByInvoiceID.items]);
        // if (apiData.data.queryInvoiceItemsByInvoiceID.nextToken != null) setNextToken(apiData.data.queryInvoiceItemsByInvoiceID.nextToken);
        // else setNextToken(null);

        if (nextToken === null) setInvoiceDetails(apiData.data.queryInvoiceItemsByInvoiceID.items);
        else setInvoiceDetails([...invoiceDetails, ...apiData.data.queryInvoiceItemsByInvoiceID.items]);
        setNextToken(apiData.data.queryInvoiceItemsByInvoiceID.nextToken);
        setLoading(false);
    }

    const getPOByID = async (SK, PK, from) => {
        setLoading(true);
        const apiData = await API.graphql({ query: queryLineItemsByPOID, variables: { PK: PK, SK: SK, first: 100, after: nextToken } });
        // if (from === 'locationUpdate') setInvoiceDetails(apiData.data.queryLineItemsByPOID.items);
        // else setInvoiceDetails([...invoiceDetails, ...apiData.data.queryLineItemsByPOID.items]);
        // if (apiData.data.queryLineItemsByPOID.nextToken != null) setNextToken(apiData.data.queryLineItemsByPOID.nextToken);
        // else setNextToken(null);

        if (from === null) setInvoiceDetails(apiData.data.queryLineItemsByPOID.items);
        else setInvoiceDetails([...invoiceDetails, ...apiData.data.queryLineItemsByPOID.items]);
        setNextToken(apiData.data.queryLineItemsByPOID.nextToken);
        setLoading(false);
    }

    const navTOOrders = () => { history.push({ pathname: ORDERS_DASHBOARD, state: { currentTab } }) };
    const navTOPOData = () => {
        var splitData = searchedData.PK.split('#');
        history.push({
            pathname: ORDERS_PODATA, state: {
                PK: splitData[0] + '#' + splitData[1], SK: splitData[2] + '#' + splitData[3], currentTab,
                componentFrom: SEARCHBY_OPENINVOICE, componentDetails: searchedData, componentOrderDetails: details
            }
        })
    }

    const idType = invoiceDetails[0] && invoiceDetails[0].SK.includes("InvoiceItem") ? "Invoice" : "PO";

    return (
        <Dashboard>
            <Row>
                <Col lg={{ span: '24' }} className="bgWhite viewDetailsPage borderRadius5rem">
                    <div className="displaySpacebetween marginBottomRg">
                        <h2 className="heading18Bold margin0"> {idType} Line Items   </h2>
                        {/* <CustomButton handleSubmit={navTOOrders} buttonLabel="Back To Orders" className="regularSize" /> */}
                        <CustomNavigator onChange={navTOOrders} />
                    </div>
                    <div className="detailsInline">
                        <p className="sub14regular"><span className="sub14Bold">{idType} ID :</span> {getOnlyID(searchedData.SK)}</p>
                    </div>
                    <div className="detailsInline">
                        <p className="sub14regular"><span className="sub14Bold">PO ID :</span> <span className="cursorpointer colorBlue" onClick={navTOPOData}>{getItems(searchedData.PK, 3)}</span></p>
                        {searchedData.GSI1SK ? <p className="sub14regular"><span className="sub14Bold">Created Date :</span>{moment(searchedData.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE)}</p> : null}
                    </div>

                    {idType === "Invoice" ?
                        <>{!loading ?
                            <InvoiceByID data={invoiceDetails} /> :
                            <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>}
                        </>
                        : <>{!loading ?
                            <POByID data={invoiceDetails} /> :
                            <div className="loadingSpinnerStylesBlue"><Spin size="large" /></div>}
                        </>}
                </Col>
            </Row>
        </Dashboard>
    );
}

export default SearchedData;
