import React from 'react';
import { Table } from 'antd';
import { DATE_PICKER_YEAR_MONTH_DATE } from '../../../constants';
import moment from 'moment';

const SOCreationTable = ({ data, POData }) => {
    const tableData = [];
    data && data.map((item, i) => {
        var obj = {
            key: i,
            evaluatedCount: i + 1,
            evaluatedDate: moment(item.GSI1SK).format(DATE_PICKER_YEAR_MONTH_DATE),
            soID: POData && POData.SalesOrder,
            soStatus: <span className="capitalizeAllCaps">{item.Status.toLowerCase()}</span>,
            statusDescription: <span className="capitalizeAllCaps">{item.Message.toLowerCase()}</span>
        }
        tableData.push(obj);
    })

    const columns = [{
        title: 'Evaluated Count',
        dataIndex: 'evaluatedCount',
        key: 'evaluatedCount'
    }, {
        title: 'Evaluated Date',
        dataIndex: 'evaluatedDate',
        key: 'evaluatedDate'
    }, {
        title: 'Sales Order',
        dataIndex: 'soID',
        key: 'soID'
    }, {
        title: 'SO Status',
        dataIndex: 'soStatus',
        key: 'soStatus'
    }, {
        title: 'SO Status Description',
        dataIndex: 'statusDescription',
        key: 'statusDescription'
    }];

    return (<Table dataSource={tableData} columns={columns} className="primary-color-table" pagination={false} scroll={{ x: 500 }} />)
}

export default SOCreationTable;