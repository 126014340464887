import React from 'react'
import { Col } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './index.scss';
import CustomButton from '../buttons';

const CustomPagination = ({ onPre, onNext, from, to, previousBtn, nextBtn }) => {

  const onPreClick = () => { onPre(); }
  const onNextClick = () => { onNext(); }

  return (<Col span={24} className="paginationMain">
    <div className="paginationButtons displayFlex justifyContentCenter alignItemsCenter">
      <CustomButton handleSubmit={onPreClick} className="PrevNext" buttonLabel={<LeftOutlined />} disabled={previousBtn} />
      <div className="pageNumbers">
        <p className="margin0 displayFlex justifyContentCenter alignItemsCenter"><span>{from}</span> - <span>{to}</span></p>
      </div>
      <CustomButton handleSubmit={onNextClick} className="PrevNext" buttonLabel={<RightOutlined />} disabled={nextBtn} />
    </div>
  </Col>)
}
export default CustomPagination;


