import React from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons';
import './index.scss';

const CustomNavigator = ({ onChange }) => {
    const navTo = () => { onChange(); }
    return (<div onClick={navTo} className="navtoCls"> <ArrowLeftOutlined /><span>Back</span> </div>)
}

export default CustomNavigator;
