import React from 'react'
import { Popover } from 'antd';
import './index.scss';

const CustomPopover = ({ className, data, relatedInfo, content, onNav, openModal }) => {
    const onNavi = () => {
        if (relatedInfo === "ASN_AWAIT") openModal();
        else onNav();
    }

    return (<div className="popOverParent">
        <Popover placement="top" className="popoverMain" content={content} trigger="hover"> <span onClick={onNavi}> {data}</span></Popover>
    </div>)
}

export default CustomPopover;
