import React from 'react'
import { Button } from 'antd';
import './index.scss';

const CustomButton = ({ handleSubmit, loading, buttonLabel, className, disabled, style }) => {
    return (<Button htmlType="submit" onClick={handleSubmit} loading={loading} className={className} disabled={disabled} style={style}>
        {buttonLabel}
    </Button>)
}

export default CustomButton;
